import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Fab, Grid, Paper, TextField, Typography} from "@mui/material";
import {translate} from "../../../translate/translate";
import Loading from "../../../components/loading/loading";
import {Field, Form, Formik} from "formik";
import {toast} from "react-toastify";
import {ErrorComponent} from "../../../components/error/error.component";
import {Search, UploadFile} from "@mui/icons-material";
import AutocompletePaginated from "../../../components/autocomplete/autocompletePaginated";
import {Product} from "../../../models/basic/product";
import TablePaginationComponent from "../../../components/table/table-pagination.component";
import TableActionComponent from "../../../components/table/table-action.component";
import {PaginatedResult} from "../../../models/default/paginated-result";
import {ProductStock} from "../../../models/planning/product-stock";

const ImportStockComponent = () => {
    const service = new BaseService<ProductStock>(URLS.PRODUCT_STOCK);
    const productService = new BaseService<Product>(URLS.PRODUCT);
    const fileInputRef = React.useRef<any>(null);
    const [loading, setLoading] = React.useState(false);
    const [selectedFiles, setSelectedFiles] = React.useState<any>();
    const [searchProduct, setSearchProduct] = useState<Product>();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [pagination, setPagination] = React.useState<PaginatedResult<ProductStock>>();
    const [dataSource, setDataSource] = React.useState<ProductStock[] | []>([]);

    useEffect(() => {
        setDataSource(pagination?.results ?? []);
    }, [pagination]);

    useEffect(() => {
        search();
    }, [page, rowsPerPage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const search = (restartIndex = false) => {
        service.clearParameters();
        if (searchProduct) service.addParameter("product", searchProduct.id);

        service.addParameter("expand", "product");
        service.addParameter("limit", rowsPerPage);
        service.addParameter("offset", (page * rowsPerPage));
        setLoading(true);
        service.getAllPaginate()
            .then(response => {
                setPagination(response.data);
            })
            .catch((error: any) => {
                ErrorComponent(error);
            })
            .finally(() => setLoading(false));
        restartIndex && setPage(0);
    };

    return (
        <section>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("stock")}
                </Typography>
            </div>
            <Loading open={loading}/>
            <Grid item xs={12} sx={{paddingBottom: "16px"}}>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{}}
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    setLoading(true);
                                    service.uploadFile("import_stock", selectedFiles)
                                        .then(() => {
                                            toast.success(translate.t("successfully_uploaded"));
                                            fileInputRef.current.value = null;
                                            setSelectedFiles(null);
                                            search(true);
                                        })
                                        .catch((error: any) => {
                                            ErrorComponent(error);
                                        })
                                        .finally(() => setLoading(false));
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                () => (
                                    <Form autoComplete="off">
                                        <Grid alignItems="center" container spacing={2} direction={"row"}>
                                            <Grid item xs>
                                                <Field
                                                    as={TextField}
                                                    className={"input-file"}
                                                    name="file"
                                                    label={""}
                                                    type={"file"}
                                                    variant={"outlined"}
                                                    fullWidth
                                                    autoFocus={true}
                                                    inputRef={fileInputRef}
                                                    onChange={(event: any) => {
                                                        const files = Array.from(event.target.files);
                                                        setSelectedFiles(files);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Fab
                                                    size="small"
                                                    type="submit"
                                                    color="secondary"
                                                    disabled={!selectedFiles}>
                                                    <UploadFile/>
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>

            <Grid item xs={12} sx={{paddingBottom: "16px"}}>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Grid alignItems="center" container spacing={2} direction={"row"}>
                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                <AutocompletePaginated
                                    display={["model","name"]}
                                    searchField="full_description"
                                    label="product"
                                    service={productService}
                                    onSelectElement={(product: Product) => {
                                        setSearchProduct(product);
                                    }}
                                    autoFocus={false}
                                />
                            </Grid>
                            <Grid item>
                                <Fab
                                    onClick={() => search(true)}
                                    size="small"
                                    type="submit"
                                    color="secondary">
                                    <Search/>
                                </Fab>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>

            <Paper variant="outlined" sx={{marginTop: "16px"}}>
                <TablePaginationComponent
                    state={dataSource}
                    displayedColumnsShow={["#", "product", "date", "quantity"]}
                    displayedColumns={["id", "product.model_and_name", "delivery_date", "quantity"]}
                    dateColumns={["delivery_date"]}
                    count={pagination?.count}
                    next={pagination?.next}
                    previous={pagination?.previous}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    actionsComponent={TableActionComponent}
                    columnStyles={{1: "5%", 2: "20%", 3: "10%", 4: "10%"}}
                />
            </Paper>
        </section>
    );
};

export default ImportStockComponent;
