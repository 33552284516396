import BaseModel from "../default/base-model";
import {ProductionLine} from "./production-line";
import {DowntimeReason} from "./downtime-reason";

export enum DOWNTIME_TYPE {
    "S" = "Scheduled",
    "U" = "Unscheduled"
}

export class Downtime extends BaseModel {
    start_time: string | any;
    start_date: Date | string | any;
    end_time: string | any;
    end_date: Date | string | any;
    production_line: ProductionLine | string | any;
    reason: DowntimeReason | string | any;
    type: string | any;

    constructor() {
        super();
        this.start_time = "";
        this.start_date = "";
        this.end_time = "";
        this.end_date = "";
        this.production_line = "";
        this.reason = "";
        this.type = "";
    }
}

