import * as Yup from "yup";
import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import {translate} from "../../../../translate/translate";
import {Box, DialogActions, DialogTitle, Grid, TextField, Typography} from "@mui/material";
import {Field, Form, Formik} from "formik";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {Close} from "@mui/icons-material";
import {ErrorComponent} from "../../../../components/error/error.component";
import {Simulation} from "../../../../models/planning/Simulation";
import {toast} from "react-toastify";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";


const DialogCreateDemandComponent = (props: any) => {
    const {open, onClose, version} = props;

    const simulationService = new BaseService<Simulation>(URLS.SIMULATION);

    const schemaPeriodGenerateDemand = Yup.object().shape({
        start_date: Yup.string().required(),
        end_date: Yup.string().required()
    });

    const handleClose = (result: boolean) => {
        onClose(result);
    };

    const onSubmit = (values: any) => {
        simulationService.clearParameters();
        simulationService.addParameter("start_date", values["start_date"].format("YYYY-MM-DD"));
        simulationService.addParameter("end_date", values["end_date"].format("YYYY-MM-DD"));
        simulationService.addParameter("version", version.id);
        simulationService.getFromListRoute("generate_demands_to_simulation").then(() => {
            toast.success(translate.t("successfully_registered"));
            handleClose(true);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    return (
        <div>
            <Dialog open={open} onClose={() => handleClose(false)} fullWidth={true} maxWidth={"xl"}>
                <DialogTitle sx={{backgroundColor: "primary.main"}}>
                    <Grid container direction="row">
                        <Grid item xs>
                            <Typography color="#FFFFFF" fontWeight="bold" fontSize="16px">
                                {translate.t("create_demand")}
                            </Typography>
                        </Grid>
                        <Grid sx={{cursor: "pointer", color: "#ffffff"}}>
                            <Close onClick={() => handleClose(false)}/>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Box>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            validationSchema={schemaPeriodGenerateDemand}
                            initialValues={{start_date: undefined, end_date: undefined}}
                            onSubmit={onSubmit}
                        >
                            {
                                ({isValid, values, errors, touched, setFieldValue}) => (
                                    <Form autoComplete="off">
                                        <Grid container direction="column" alignItems="center">
                                            <Grid container item direction="row" spacing={2}>
                                                <Grid item xs lg={2}>
                                                    <DatePicker
                                                        format="DD/MM/YYYY"
                                                        onChange={event => setFieldValue("start_date", event)}
                                                        maxDate={values.end_date}
                                                        slotProps={{
                                                            textField: {
                                                                name: "start_date",
                                                                required: true,
                                                                fullWidth: true,
                                                                label: translate.t("start_date"),
                                                                InputLabelProps: {
                                                                    shrink: true
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs lg={2}>
                                                    <DatePicker
                                                        format="DD/MM/YYYY"
                                                        onChange={event => setFieldValue("end_date", event)}
                                                        minDate={values.start_date}
                                                        slotProps={{
                                                            textField: {
                                                                name: "end_date",
                                                                required: true,
                                                                fullWidth: true,
                                                                label: translate.t("end_date"),
                                                                InputLabelProps: {
                                                                    shrink: true
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid container justifyContent="flex-end" gap={2} paddingTop={2}>
                                            <Grid item>
                                                <Button
                                                    className="button-options"
                                                    color="success"
                                                    type="submit"
                                                    disabled={!isValid}
                                                    variant="contained">
                                                    {translate.t("create")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="flex-end" gap={2} paddingTop={2}>
                        <Grid item>
                            <Button
                                className="button-options"
                                color="secondary"
                                onClick={() => handleClose(false)}
                                variant="contained">
                                {translate.t("cancel")}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DialogCreateDemandComponent;
