import BaseModel from "../default/base-model";

export class Version extends BaseModel {
    id: number | undefined;
    name: string;
    active: boolean;
    final_version: boolean;
    created_by: string;
    simulation_version: boolean;
    in_progress: boolean;

    constructor() {
        super();
        this.id = undefined;
        this.name = "";
        this.active = false;
        this.final_version = false;
        this.created_by = "";
        this.simulation_version = false;
        this.in_progress = false;
    }
}
