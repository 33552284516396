import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    TableCell,
    Typography
} from "@mui/material";
import {Close, Delete, Edit, MoreVert, History, ContentCopy} from "@mui/icons-material";
import {NavLink} from "react-router-dom";
import {translate} from "../../translate/translate";
import * as React from "react";
import {useState} from "react";
import {useAuth} from "../../contexts/auth-context";

const TableMenuComponent = (props: any) => {
    const {
        row,
        handleOpenDialogHistory,
        editWithDialog,
        handleDelete,
        showActions,
        handleDuplicate
    } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const openUserPanel = Boolean(anchorEl);
    const [open, setOpen] = React.useState(false);
    const {user} = useAuth();
    const isCurrentUser = row.id === user.id && row.username === user.username;

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <TableCell align="right">
                <IconButton color="secondary" size={"small"} onClick={handleClick}>
                    <MoreVert sx={{fontSize: "28px"}}></MoreVert>
                </IconButton>
                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={openUserPanel}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    {
                        (!showActions || showActions.includes("history")) &&
                        (
                            <MenuItem
                                onClick={() => {
                                    handleOpenDialogHistory(row.id);
                                }}
                            >
                                <ListItemIcon>
                                    <History/>
                                </ListItemIcon>
                                <ListItemText>
                                    {translate.t("history")}
                                </ListItemText>
                            </MenuItem>
                        )
                    }
                    {
                        (!showActions || showActions.includes("edit")) &&
                        (
                            <MenuItem
                                component={NavLink} to={`${props.url}${row.id}`}
                                onClick={() => {
                                    if (editWithDialog) editWithDialog(row);
                                }}
                            >
                                <ListItemIcon>
                                    <Edit/>
                                </ListItemIcon>
                                <ListItemText>
                                    {translate.t("edit")}
                                </ListItemText>
                            </MenuItem>
                        )
                    }
                    {
                        (!showActions || showActions.includes("delete")) &&
                        (
                            <MenuItem
                                onClick={() => {
                                    if (!isCurrentUser) {
                                        handleOpenDialog();
                                    }
                                }} disabled={isCurrentUser}
                            >
                                <ListItemIcon>
                                    <Delete/>
                                </ListItemIcon>
                                <ListItemText>
                                    {translate.t("delete")}
                                </ListItemText>
                            </MenuItem>
                        )
                    }
                    {
                        (showActions !== undefined && showActions.includes("duplicate")) &&
                        (
                            <MenuItem
                                onClick={() => {
                                    handleDuplicate(row);
                                }}
                            >
                                <ListItemIcon>
                                    <ContentCopy />
                                </ListItemIcon>
                                <ListItemText>
                                    {translate.t("duplicate")}
                                </ListItemText>
                            </MenuItem>
                        )
                    }
                </Menu>
            </TableCell>
            <Dialog open={open} onClose={handleCloseDialog}>
                <Grid padding={2} container justifyContent="space-between" sx={{backgroundColor: "primary.main"}}>
                    <Grid item>
                        <Typography sx={{color: "#ffffff", fontWeight: "bold", fontSize: "16px"}}>
                            {translate.t("delete")}
                        </Typography>
                    </Grid>
                    <Grid item sx={{cursor: "pointer", color: "#ffffff"}}>
                        <Close onClick={() => {
                            handleCloseDialog();
                        }}/>
                    </Grid>
                </Grid>
                <DialogContent>
                    <Grid container flexDirection="column" gap={1}>
                        <Grid item>
                            <Typography>
                                {translate.t("confirm_delete")}
                            </Typography>
                        </Grid>
                        {
                            row.name !== undefined && (
                                <Grid item>
                                    <Typography sx={{fontWeight: "bold"}}>
                                        {row.name}
                                    </Typography>
                                </Grid>
                            )
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="flex-end" gap={1} padding={2}>
                        <Grid item>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => {
                                    handleCloseDialog();
                                }}>{translate.t("cancel")}</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="error"

                                onClick={() => {
                                    handleDelete(row);
                                    setAnchorEl(null);
                                    handleCloseDialog();
                                }}>{translate.t("delete")}</Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default TableMenuComponent;
