import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Tooltip, Typography} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {translate} from "../../../../../translate/translate";
import {DateRangeIcon} from "@mui/x-date-pickers";
import {format} from "date-fns";
import {Assignment, CalendarMonth, CallSplit, Info, MoreVertOutlined, WarningAmber} from "@mui/icons-material";
import {Demand} from "../../../../../models/basic/demand";
import DialogPlanningDemandComponent from "../../dialog-planning-demand/dialog-planning-demand.component";
import DialogInfoDemandComponent from "../../dialog-info-demand/dialog-info-demand.component";
import DialogMoveBalanceComponent from "../../dialog-move-balance/dialog-move-balance.component";
import "./available-menu.component.css";
import DialogEditDemandComponent from "../../dialog-edit-demand/dialog-edit-demand.component";


const AvailableMenuComponent = (props: any) => {
    const {
        handleSearch,
        available,
    } = props;
    const [expanded, setExpanded] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [demandSelected, setDemandSelected] = useState<Demand>(new Demand());
    const [openPlanningDialog, setOpenPlanningDialog] = useState(false);
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [openMoveBalanceDialog, setOpenMoveBalanceDialog] = useState(false);
    const [openEditDemandDialog, setOpenEditDemandDialog] = useState(false);

    useEffect(() => {
        getTotalItems();
    }, [available]);

    const getTotalItems = () => {
        let totalItems = 0;
        for (const key in available.available) {
            if (Array.isArray(available.available[key])) {
                totalItems += available.available[key].length;
            }
        }
        setTotalItems(totalItems);
    };

    const handleChange = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    const handleDemandMenuClick = (event: any, item: any) => {
        setDemandSelected(item);
        setAnchorEl(event.currentTarget);
    };

    const handleEditDemand = () => {
        setAnchorEl(null);
        setDemandSelected(demandSelected);
        setOpenEditDemandDialog(true);
    };

    const handleDemandMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenPlanDemand = () => {
        setAnchorEl(null);
        setDemandSelected(demandSelected);
        setOpenPlanningDialog(true);
    };

    const handleCloseDialog = (search: boolean) => {
        setOpenInfoDialog(false);
        setOpenMoveBalanceDialog(false);
        setOpenPlanningDialog(false);
        setOpenEditDemandDialog(false);
        search ? handleSearch() : null;
    };

    const handleOpenInfoDemand = () => {
        setAnchorEl(null);
        setDemandSelected(demandSelected);
        setOpenInfoDialog(true);
    };

    const handleMoveBalance = () => {
        setAnchorEl(null);
        setDemandSelected(demandSelected);
        setOpenMoveBalanceDialog(true);
    };

    return (
        <Box>
            <Accordion className="demand-accordion" expanded={expanded} onChange={handleChange}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="accordion-summary"
                >
                    <Box justifyContent="space-between" display="flex">
                        <Typography fontWeight="bold" justifyContent="flex-start" display="flex">
                            {translate.t("available")}
                        </Typography>
                        <Paper className="badge-quantity" elevation={0}>
                            <Typography variant="body2">
                                {totalItems}
                            </Typography>
                        </Paper>
                    </Box>
                </AccordionSummary>
                <AccordionDetails className="demand-accordion-details">
                    {available.available && Object.keys(available.available).length > 0 ? (
                        available && available.available && Object.entries(available.available).map(([date, items], index) => (
                            <Accordion className="demand-accordion" key={index}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>} aria-controls={`panel${index}-content`} id={`panel${index}-header`} className="demand-accordion-summary">
                                    <Box display="flex">
                                        <Box display="flex">
                                            <DateRangeIcon sx={{color: "#45485F"}}/>
                                            <Typography
                                                fontWeight="bold"
                                                marginLeft="8px">{translate.t(format(new Date(date).setMonth(new Date(date).getMonth() + 1), "MMMM"))}/{new Date(date).getFullYear()}
                                            </Typography>
                                        </Box>
                                        <Card className="badge-count">
                                            <Typography color="#FFFFFF" fontWeight="bold">
                                                {Array.isArray(items) ? items.length : 0}
                                            </Typography>
                                        </Card>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails sx={{padding: "5px 0px 5px"}}>
                                    <Box>
                                        {Array.isArray(items) && items.map((item, itemIndex) => (
                                            <Card key={itemIndex} className="demand-card" sx={{backgroundColor: item.color, color: item.font_color, padding: "6px"}}>
                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    fontWeight: "bold",
                                                    justifyContent: "space-between",
                                                    alignItems: "center"
                                                }}>
                                                    <Typography sx={{
                                                        color: item.font_color,
                                                        fontWeight: "bold"
                                                    }}>
                                                        {item.description}
                                                    </Typography>
                                                    <Button
                                                        sx={{backgroundColor: "transparent"}}
                                                        onClick={(event) => handleDemandMenuClick(event, item)}
                                                    >
                                                        <MoreVertOutlined
                                                            sx={{color: item.font_color}}/>
                                                    </Button>
                                                </Box>
                                                <Box sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "4px",
                                                }}>
                                                    {!item.has_smt_configuration ? (
                                                        <Tooltip
                                                            title={translate.t("smt_configuration")}>
                                                            <Box sx={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                backgroundColor: "#ff6b01",
                                                                borderRadius: "15px",
                                                                border: "2px solid white",
                                                                color: "#ffffff",
                                                                padding: "1px",
                                                            }}>
                                                                <WarningAmber fontSize="small"
                                                                    sx={{marginRight: "3px"}}/>
                                                                <Typography sx={{
                                                                    color: "white",
                                                                    fontSize: "10.5px"
                                                                }}>
                                                                    {translate.t("smt_warnning")}
                                                                </Typography>
                                                            </Box>
                                                        </Tooltip>
                                                    ) : null}
                                                    {!item.has_manual_default_time ? (
                                                        <Tooltip
                                                            title={translate.t("manual_configuration")}>
                                                            <Box sx={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                backgroundColor: "#ff6b01",
                                                                borderRadius: "15px",
                                                                border: "2px solid white",
                                                                color: "#ffffff",
                                                                padding: "1px",
                                                            }}>
                                                                <WarningAmber fontSize="small"
                                                                    sx={{marginRight: "3px"}}/>
                                                                <Typography sx={{
                                                                    fontSize: "10.5px",
                                                                    color: "#ffffff"
                                                                }}>
                                                                    {translate.t("manual_warnning")}
                                                                </Typography>
                                                            </Box>
                                                        </Tooltip>
                                                    ) : null}
                                                </Box>
                                            </Card>
                                        ))}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    ) : (
                        <Typography sx={{padding: "8px 16px"}}>{translate.t("empty_listing")}</Typography>
                    )}
                </AccordionDetails>
            </Accordion>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleDemandMenuClose}>
                <MenuItem onClick={() => handleOpenInfoDemand()}>
                    <ListItemIcon>
                        <Info/>
                    </ListItemIcon>
                    <ListItemText>
                        {translate.t("info_demand")}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleOpenPlanDemand()} key="to_plan">
                    <ListItemIcon>
                        <CalendarMonth/>
                    </ListItemIcon>
                    <ListItemText>
                        {translate.t("to_plan")}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleMoveBalance()} key="move_balance">
                    <ListItemIcon>
                        <CallSplit/>
                    </ListItemIcon>
                    <ListItemText>
                        {translate.t("move_balance")}
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleEditDemand()}>
                    <ListItemIcon>
                        <Assignment/>
                    </ListItemIcon>
                    <ListItemText>
                        {translate.t("edit_demand")}
                    </ListItemText>
                </MenuItem>
            </Menu>
            {openPlanningDialog && (
                <DialogPlanningDemandComponent style="width: 1200px!important; max-width: 1200px!important;"
                    open={openPlanningDialog} onClose={handleCloseDialog}
                    demand={demandSelected}/>)}
            {openInfoDialog && (<DialogInfoDemandComponent style="width: 1200px!important; max-width: 1200px!important;"
                open={openInfoDialog} onClose={handleCloseDialog}
                demand={demandSelected}/>)}
            {openMoveBalanceDialog && (
                <DialogMoveBalanceComponent style="width: 1200px!important; max-width: 1200px!important;"
                    open={openMoveBalanceDialog} onClose={handleCloseDialog}
                    demand={demandSelected}/>)}
            {openEditDemandDialog && (
                <DialogEditDemandComponent style="width: 1200px!important; max-width: 1200px!important;"
                    open={openEditDemandDialog} onClose={handleCloseDialog}
                    demand={demandSelected}></DialogEditDemandComponent>)}
        </Box>
    );
};


export default AvailableMenuComponent;
