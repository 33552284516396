import * as React from "react";
import {useEffect} from "react";
import {translate} from "../../../translate/translate";
import {Box, Button, Fab, FormControl, Grid, InputLabel, MenuItem, Paper, TextField, Typography} from "@mui/material";
import TablePaginationComponent from "../../../components/table/table-pagination.component";
import {BaseService} from "../../../services/base-service";
import {Add, Search} from "@mui/icons-material";
import {URLS} from "../../../services/app-urls";
import {NavLink, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {PaginatedResult} from "../../../models/default/paginated-result";
import TableActionComponent from "../../../components/table/table-action.component";
import {Field, Form, Formik} from "formik";
import {User} from "../../../models/account/user";
import Select from "@mui/material/Select";
import HistoryComponent from "../../../components/history/history.component";
import {ErrorComponent} from "../../../components/error/error.component";
import {DowntimeReason} from "../../../models/basic/downtime-reason";


const DowntimeReasonComponent = () => {
    const service = new BaseService<DowntimeReason>(URLS.DOWNTIME_REASON);
    const url = "/registration/downtime_reason/";
    const [dataSource, setDataSource] = React.useState<DowntimeReason[]>([]);
    const navigate = useNavigate();
    const [eventTypePagination, setEventTypePagination] = React.useState<PaginatedResult<DowntimeReason>>();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [openHistory, setOpenHistory] = React.useState(false);
    const [idHistory, setIdHistory] = React.useState(0);
    const [selectedStatus, setSearchStatus] = React.useState<string>("true");
    const [searchDescription, setSearchDescription] = React.useState<string>("");

    const handleOpenDialogHistory = (id: number) => {
        setIdHistory(id);
        setOpenHistory(true);
    };

    const handleCloseDialogHistory = () => {
        setOpenHistory(false);
    };

    const search = (restartIndex = false) => {
        service.clearParameters();
        service.addParameter("limit", rowsPerPage);
        service.addParameter("offset", (page * rowsPerPage));

        if (searchDescription) service.addParameter("description", searchDescription);
        if (selectedStatus) service.addParameter("active", selectedStatus);

        service.getAllPaginate().then(response => {
            setEventTypePagination(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
        restartIndex && setPage(0);
    };

    const handleSwitch = (row: User, column: keyof User) => {
        (row[column] as boolean) = !row[column];
        const payload = {"active": row.active};

        service.patch(payload, Number(row.id)).then(() => {
            toast.success(translate.t("successfully_updated"));
            service.clearParameters();
            search();
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    useEffect(() => {
        setDataSource(eventTypePagination?.results ?? []);
    }, [eventTypePagination]);

    useEffect(() => {
        search();
    }, [page, rowsPerPage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDoubleClick = (row: DowntimeReason) => {
        navigate(`${url}${row.id}`);
    };

    const handleDelete = (row: DowntimeReason) => {
        service.delete(Number(row.id)).then(() => {
            toast.success(translate.t("successfully_deleted"));
            service.getAll().then(() => {
                search();
            });
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    return (
        <section>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("downtime_reason")}
                </Typography>
            </div>
            <div className="floating-button">
                <Button
                    component={NavLink}
                    to={url + "create"}
                    startIcon={<Add/>}
                    variant="contained"
                    color="secondary"
                    className={"button-new"}>
                    {translate.t("new")}
                </Button>
            </div>
            <Grid item xs={12}>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{name: ""}}
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    search(true);
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {() => (
                                <Form autoComplete="off">
                                    <Grid alignItems="center" container spacing={2} direction={"row"}>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="description"
                                                label={translate.t("description")}
                                                fullWidth
                                                variant={"outlined"}
                                                autoFocus={true}
                                                value={searchDescription}
                                                onChange={(event: React.ChangeEvent<{
                                                    value: unknown
                                                }>) => setSearchDescription(event.target.value as string)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={2}>
                                            <FormControl fullWidth>
                                                <InputLabel className={"formControl"} htmlFor="status">
                                                    {translate.t("status")}
                                                </InputLabel>
                                                <Field
                                                    variant={"outlined"}
                                                    label={translate.t("status")}
                                                    defaultValue="true"
                                                    name="status"
                                                    component={Select}
                                                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                        setSearchStatus(event.target.value as string)}>
                                                    <MenuItem value="">-</MenuItem>
                                                    <MenuItem value="true">
                                                        {translate.t("is_active")}</MenuItem>
                                                    <MenuItem value="false">
                                                        {translate.t("inactive")}</MenuItem>
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <Fab
                                                size="small"
                                                type="submit"
                                                color="secondary">
                                                <Search/>
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>

            <Paper variant="outlined" sx={{marginTop: "16px"}}>
                <TablePaginationComponent
                    state={dataSource}
                    displayedColumnsShow={["#", "description", "status", "action"]}
                    displayedColumns={["id", "description", "active", "action"]}
                    handleSwitch={handleSwitch}
                    handleDelete={handleDelete}
                    url={url}
                    handleDoubleClick={handleDoubleClick}
                    count={eventTypePagination?.count}
                    next={eventTypePagination?.next}
                    previous={eventTypePagination?.previous}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    actionsComponent={TableActionComponent}
                    handleOpenDialogHistory={handleOpenDialogHistory}
                    columnStyles={{1: "33%", 2: "33%", 3: "33%"}}
                />
            </Paper>
            <HistoryComponent
                openHistory={openHistory}
                handleCloseDialogHistory={handleCloseDialogHistory}
                idHistory={idHistory}
                columns_history_show={["description", "status", "action"]}
                columns_history={["description", "active", "action"]}
                service={service}
            />
        </section>
    );
};

export default DowntimeReasonComponent;
