import * as React from "react";
import {useEffect, useState} from "react";
import {Box, Chip, Grid, Paper, Stack, Typography} from "@mui/material";
import EChartsReact, {useChart} from "echarts-for-react-fc";
import * as echarts from "echarts/core";
import {DataZoomComponent, GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent} from "echarts/components";
import {BarChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {ErrorComponent} from "../../../components/error/error.component";
import {translate} from "../../../translate/translate";
import Loading from "../../../components/loading/loading";
import {ActiveLineShift} from "../../../models/basic/active-line-shift";
import {Form, Formik} from "formik";
import AutocompletePaginated from "../../../components/autocomplete/autocompletePaginated";
import {ProductionLine} from "../../../models/basic/production-line";
import {Shift} from "../../../models/basic/shift";

echarts.use([GridComponent, BarChart, CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, DataZoomComponent]);

const initUpphOption: any = {
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "shadow"
        }
    },
    toolbox: {
        feature: {
            saveAsImage: {show: true}
        }
    },
    legend: {
        data: []
    },
    xAxis: {
        type: "category",
        data: [],
        axisLabel: {
            rotate: 45,
            interval: 0
        }
    },
    yAxis: {
        type: "value",
        name: "",
        axisLabel: {
            formatter: "{value}"
        }
    },
    series: []
};

const DashboardUpphProductionLineComponent = () => {
    const service = new BaseService<ActiveLineShift>(URLS.ACTIVE_LINE_SHIFT);
    const [showBufferChart, setShowBufferChart] = useState<boolean>(false);
    const {chartRef: bufferChartRef, setChartOption: setBufferChartOption, handleListenChartReady: handleBufferChartReady} = useChart();
    const [loading, setLoading] = React.useState(false);
    const productionLineService = new BaseService<ProductionLine>(URLS.PRODUCTION_LINE);
    const [clearAutocompleteProductionLine, setClearAutocompleteProductionLine] = useState<boolean>(false);
    const [selectedProductionLines, setSelectedProductionLines] = useState<ProductionLine[]>([]);
    const shiftService = new BaseService<Shift>(URLS.SHIFT);
    const [clearAutocompleteShift, setClearAutocompleteShift] = useState<boolean>(false);
    const [selectedShifts, setSelectedShifts] = useState<Shift[]>([]);

    useEffect(() => {
        getData();
    }, [selectedProductionLines, selectedShifts]);

    const setBufferChartValues = (data: any[]) => {
        const option = JSON.parse(JSON.stringify(initUpphOption));
        const months = data.map(item => item.month);
        const upph = data.map(item => item.value);

        option.xAxis.data = months;

        option.series = [
            {
                name: "UPPH",
                type: "line",
                stack: "total",
                data: upph,
                itemStyle: {
                    color: "#8faadc",
                }
            }
        ];

        option.tooltip = {
            trigger: "axis",
            axisPointer: {
                type: "shadow"
            },
            formatter: (params: any) => {
                const tooltipContent = params
                    .map((item: any) => `
                    <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${item.color};"></span>
                    <span style="display:inline-block;text-align:left; padding-right: 16px;">${item.seriesName}</span>
                    <strong style="float:right;">${item.value}</strong>`
                    ).join("<br/>");
                return `<div>${tooltipContent}</div>`;
            }
        };

        option.legend = {
            show: true,
        };

        setBufferChartOption(option, true);
        setShowBufferChart(true);
    };

    const getData = () => {
        setLoading(true);
        const productionLineIds = selectedProductionLines.map((productionLine) => productionLine.id);
        const shiftIds = selectedShifts.map((shift) => shift.id);
        service.addParameter("production_line_list", productionLineIds.join(","));
        service.addParameter("shift_list", shiftIds.join(","));
        service.getFromListRoute("get_upph_production_line_data")
            .then((response: any) => {
                setLoading(false);
                setBufferChartValues(response.data);
            })
            .catch((error: any) => {
                setLoading(false);
                ErrorComponent(error);
            });
    };

    const onSubmit = () => {
        setTimeout(async () => {
            setClearAutocompleteProductionLine(true);
            setClearAutocompleteShift(true);
            getData();
        }, 500);
    };

    const handleDeleteProductionLine = (productionLineToDelete: ProductionLine) => {
        setSelectedProductionLines((productionLines) => productionLines.filter((productionLine) => productionLine.id !== productionLineToDelete.id));
    };

    const handleDeleteShift = (shiftToDelete: Shift) => {
        setSelectedShifts((shifts) => shifts.filter((shift) => shift.id !== shiftToDelete.id));
    };

    return (
        <Grid>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("upph_production_line")}
                </Typography>
            </div>
            <Loading open={loading}/>
            <Grid>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            initialValues={{}}
                            enableReinitialize={true}
                            validateOnMount={true}
                            onSubmit={onSubmit}
                            validateOnChange={true}
                        >
                            {
                                () => (
                                    <Form autoComplete="off">
                                        <Grid alignItems="center" container spacing={2} direction={"row"}>
                                            <Grid item xs sm md lg>
                                                <AutocompletePaginated
                                                    display={["name"]}
                                                    searchField="name"
                                                    label="production_line"
                                                    service={productionLineService}
                                                    clear={clearAutocompleteProductionLine}
                                                    onSelectElement={(productionLine: ProductionLine) => {
                                                        setClearAutocompleteProductionLine(false);
                                                        if (!selectedProductionLines.find((m) => m.id === productionLine.id)) {
                                                            setSelectedProductionLines([...selectedProductionLines, productionLine]);
                                                            setTimeout(() => setClearAutocompleteProductionLine(true), 200);
                                                        }
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs sm md lg>
                                                <AutocompletePaginated
                                                    display={["name"]}
                                                    searchField="name"
                                                    label="shift"
                                                    service={shiftService}
                                                    clear={clearAutocompleteShift}
                                                    onSelectElement={(shift: Shift) => {
                                                        setClearAutocompleteShift(false);
                                                        if (!selectedShifts.find((s) => s.id === shift.id)) {
                                                            setSelectedShifts([...selectedShifts, shift]);
                                                            setTimeout(() => setClearAutocompleteShift(true), 200);
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </Paper>

                {selectedProductionLines.length > 0 && (
                    <Paper variant="outlined" style={{marginTop: "8px"}}>
                        <Box p={2} marginTop={2}>
                            <Stack direction="row" flexWrap="wrap" gap={1}>
                                {selectedProductionLines.map((productionLine) => (
                                    <Chip
                                        key={productionLine.id}
                                        label={productionLine.name}
                                        onDelete={() => handleDeleteProductionLine(productionLine)}
                                    />
                                ))}
                            </Stack>
                        </Box>
                    </Paper>
                )}

                {selectedShifts.length > 0 && (
                    <Paper variant="outlined" style={{marginTop: "8px"}}>
                        <Box p={2} marginTop={2}>
                            <Stack direction="row" flexWrap="wrap" gap={1}>
                                {selectedShifts.map((shift) => (
                                    <Chip
                                        key={shift.id}
                                        label={shift.name}
                                        onDelete={() => handleDeleteShift(shift)}
                                    />
                                ))}
                            </Stack>
                        </Box>
                    </Paper>
                )}
            </Grid>

            {showBufferChart && (selectedShifts.length > 0 || selectedProductionLines.length > 0) && (
                <Grid container direction={"column"} gap={0} marginTop={1}>
                    <Grid item xs>
                        <Paper id={"chart-buffer"} sx={{padding: "16px"}}>
                            <EChartsReact
                                autoResize={true}
                                style={{width: "100%", height: 500}}
                                ref={bufferChartRef}
                                echarts={echarts}
                                onChartReady={handleBufferChartReady}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            )}

        </Grid>
    );
};

export default DashboardUpphProductionLineComponent;
