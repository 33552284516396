import "./dialog-shortage.component.css";
import {Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, TablePagination, Typography} from "@mui/material";
import {translate} from "../../../../translate/translate";
import * as React from "react";
import {useEffect} from "react";
import {ChangeCircle, Circle, Close, MoreVertOutlined} from "@mui/icons-material";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import Loading from "../../../../components/loading/loading";
import TableActionComponent from "../../../../components/table/table-action.component";
import {SpreadsheetImportItem} from "../../../../models/planning/spreadsheet-import-item";
import {ErrorComponent} from "../../../../components/error/error.component";


interface product {
    product: string;
    product_id: number;
    quantities: number[];
}

interface resultData {
    days: string[];
    products: product[];
    count: number;
}

const DialogShortageComponent = (props: any) => {
    const {open, onClose} = props;
    const service = new BaseService<SpreadsheetImportItem>(URLS.SPREADSHEET_IMPORT_ITEM);
    const [dataSource, setDataSource] = React.useState<resultData>();
    const [loading, setLoading] = React.useState(false);

    const search = () => {
        setLoading(true);
        service.clearParameters();
        service.getFromListRoute("get_shortage").then((response: any) => {
            setDataSource(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        }).finally(() => setLoading(false));
    };

    useEffect(() => {
        search();
    }, []);

    const formatDate = (date: string) => {
        return date.split("-")[2].padStart(2, "0") + "/" + date.split("-")[1].padStart(2, "0") + "/" + date.split("-")[0];
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"xl"}>
                <DialogTitle sx={{backgroundColor: "primary.main"}}>
                    <Grid container direction="row">
                        <Grid item xs>
                            <Typography color="#FFFFFF" fontWeight="bold" fontSize="16px">
                                {translate.t("Shortage")}
                            </Typography>
                        </Grid>
                        <Grid sx={{cursor: "pointer", color: "#ffffff"}}>
                            <Close onClick={handleClose}/>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers>
                    <Box sx={{overflow: "hidden"}}>
                        <Loading open={loading}/>

                        {dataSource && (
                            <Box display="flex" flexDirection="column" sx={{overflow: "scroll"}}>
                                <Box position="sticky" top="0" zIndex={3}>
                                    <Box width="auto" display="flex" flexDirection="row">
                                        <Box position="sticky" left="0" display="flex" flexDirection="column" className="product">
                                            <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                                <Typography variant="body2" color="white">{translate.t("product")}</Typography>
                                            </Box>
                                        </Box>
                                        {dataSource.days.map((day, index) => {
                                            return (
                                                <Box key={index} display="flex" flexDirection="column" className="header-date">
                                                    <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                                        <Typography variant="body2">{formatDate(day)}</Typography>
                                                    </Box>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Box>
                                {dataSource.products.map((item, index) => {
                                    return (
                                        <Box key={index}>
                                            <Box display="flex" flexDirection="row">
                                                <Box position="sticky" zIndex={2} left="0" display="flex" flexDirection="column" className="product">
                                                    <Box style={{backgroundColor: "#f5f5f5", paddingLeft: "0", paddingRight: "8px"}}
                                                        display="flex" flex="1" justifyContent="left" alignItems="center" overflow="hidden">
                                                        <Typography sx={{margin: "15px"}} variant="body2" fontWeight="bold">{item.product}</Typography>
                                                    </Box>
                                                </Box>
                                                {item.quantities.map((quantity, index) => {
                                                    return (
                                                        <Box key={index} display="flex" flexDirection="column" className="line-date">
                                                            <Box className={"info"} display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                                                <Typography variant="body2">{quantity}</Typography>
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Box>
                        )}
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default DialogShortageComponent;
