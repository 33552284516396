import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {toast} from "react-toastify";
import * as React from "react";
import {useEffect, useState} from "react";
import {PaginatedResult} from "../../../models/default/paginated-result";
import {NavLink, useNavigate} from "react-router-dom";
import {SmtTime} from "../../../models/basic/smt-time";
import {SmtTimeTable} from "../../../models/basic/smt-time-table";
import {ProductionLine} from "../../../models/basic/production-line";
import {translate} from "../../../translate/translate";
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Fab,
    Grid,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import TablePaginationComponent from "../../../components/table/table-pagination.component";
import TableActionComponent from "../../../components/table/table-action.component";
import {Add, Close, Search} from "@mui/icons-material";
import {Form, Formik} from "formik";
import {Product} from "../../../models/basic/product";
import {ErrorComponent} from "../../../components/error/error.component";
import HistoryComponent from "../../../components/history/history.component";
import AutocompletePaginated from "../../../components/autocomplete/autocompletePaginated";

const SmtDefaultTimeComponent = () => {
    const navigate = useNavigate();
    const service = new BaseService<SmtTime>(URLS.SMT_TIME);
    const serviceProduct = new BaseService<Product>(URLS.PRODUCT);
    const serviceProductionLine = new BaseService<ProductionLine>(URLS.PRODUCTION_LINE);
    const url = "/production_capacity/smt_default_time/";
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [openHistory, setOpenHistory] = React.useState(false);
    const [idHistory, setIdHistory] = React.useState(0);
    const [pagination, setPagination] = React.useState<PaginatedResult<SmtTime>>();
    const [dataSource, setDataSource] = React.useState<SmtTimeTable[]>([]);
    const [listProduct, setListProduct] = React.useState<Product[]>([]);
    const [listProductionLine, setListProductionLine] = React.useState<ProductionLine[]>([]);
    const [productValue, setProductValue] = React.useState<Product | null>(null);
    const [productDuplicate, setProductDuplicate] = React.useState<Product | null>(null);
    const [productionLineValue, setProductionLineValue] = React.useState<ProductionLine | null>(null);
    const [rowHandleDuplicate, setRowHandleDuplicate] = useState<SmtTimeTable>(new SmtTimeTable());
    const [openDialogDuplicate, setOpenDialogDuplicate] = useState<boolean>(false);

    useEffect(() => {
        searchProduct();
        searchProductionLine();
    }, []);

    const handleOpenDialogHistory = (id: number) => {
        setIdHistory(id);
        setOpenHistory(true);
    };

    const handleCloseDialogHistory = () => {
        setOpenHistory(false);
    };

    const searchProduct = () => {
        serviceProduct.clearParameters();
        serviceProduct.getAll().then(response => {
            setListProduct(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const searchProductionLine = () => {
        serviceProductionLine.clearParameters();
        serviceProductionLine.getAll().then(response => {
            setListProductionLine(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const search = () => {
        service.clearParameters();
        service.addParameter("expand", "product.sides,lines");
        service.addParameter("limit", rowsPerPage);
        service.addParameter("offset", (page * rowsPerPage));
        if (productValue) service.addParameter("product", productValue.id);
        if (productionLineValue) service.addParameter("production_line", productionLineValue.id);
        service.getAllPaginate().then(response => {
            setPagination(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    useEffect(() => {
        const listSmtTimeTable:SmtTimeTable[] = [];
        const results =  pagination?.results ?? [];
        results.forEach((smtTime:SmtTime) => {

            let productString = `${smtTime.product.model_and_name} - (`;
            smtTime.product.sides.forEach((side:any) => {
                productString += `${translate.t("side")} ${side.name}, `;
            });
            productString = productString.slice(0, -2);
            productString += ")";

            let linesString = "";
            smtTime.lines.forEach((line:any) => {
                linesString += `${line.name}, `;
            });
            linesString = linesString.slice(0, -2);

            const smtTimeTable = new SmtTimeTable();
            Object.assign(smtTimeTable, smtTime);
            smtTimeTable.product = productString;
            smtTimeTable.lines = linesString;

            listSmtTimeTable.push(smtTimeTable);
        });
        setDataSource(listSmtTimeTable);
    }, [pagination]);

    useEffect(() => {
        search();
    }, [page, rowsPerPage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDoubleClick = (row: SmtTimeTable) => {
        navigate(`${url}${row.id}`);
    };

    const handleDelete = (row: SmtTimeTable) => {
        service.delete(Number(row.id)).then(() => {
            toast.success(translate.t("successfully_deleted"));
            service.getAll().then(() => {
                search();
            });
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handleDuplicate = (row: SmtTimeTable) => {
        setRowHandleDuplicate(row);
        setOpenDialogDuplicate(true);
    };

    const duplicateNow = () => {
        const duplicateObj = {
            to_product: productDuplicate?.id,
            smt_time: rowHandleDuplicate.id
        };

        service.getFromRoutePost("duplicate", duplicateObj)
            .then(() => {
                toast.success(translate.t("successfully_registered"));
                search();
            }).catch((error: any) => {
                ErrorComponent(error);
            });

        setOpenDialogDuplicate(false);
    };

    useEffect(() => {
        if (!openDialogDuplicate) {
            setRowHandleDuplicate(new SmtTimeTable());
            setProductDuplicate(null);
        }
    }, [openDialogDuplicate]);

    const defaultPropsProductionLine = {
        options: listProductionLine,
        getOptionLabel: (option: ProductionLine) => option.name,
    };

    return (
        <Box>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("smt_default_time")}
                </Typography>
            </div>
            <div className="floating-button">
                <Button
                    component={NavLink}
                    to={url + "create"}
                    startIcon={<Add/>}
                    variant="contained"
                    color="secondary">
                    {translate.t("new")}
                </Button>
            </div>

            <Grid>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{name: ""}}
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    search();
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                () => (
                                    <Form autoComplete="off">
                                        <Grid alignItems="center" container spacing={2} direction={"row"}>
                                            <Grid item xs>
                                                <AutocompletePaginated
                                                    display={["model","name"]}
                                                    searchField="full_description"
                                                    label="product"
                                                    service={serviceProduct}
                                                    onSelectElement={(product: Product) => {
                                                        setProductValue(product);
                                                    }}
                                                    autoFocus={true}
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <Autocomplete
                                                    disablePortal
                                                    id="cb-production-line"
                                                    {...defaultPropsProductionLine}
                                                    fullWidth
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            label={translate.t("production_line")}
                                                        />
                                                    }
                                                    onChange={(event: any, newValue: ProductionLine | null) => {
                                                        setProductionLineValue(newValue);
                                                    }}
                                                    noOptionsText={translate.t("empty_listing")}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Fab
                                                    size="small"
                                                    type="submit"
                                                    color="secondary">
                                                    <Search/>
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>

            <Paper variant="outlined" sx={{marginTop: "16px"}}>
                <TablePaginationComponent
                    state={dataSource}
                    displayedColumnsShow={["product", "lines", "action"]}
                    displayedColumns={["product", "lines", "action"]}
                    handleDelete={handleDelete}
                    handleDuplicate={handleDuplicate}
                    url={url}
                    handleDoubleClick={handleDoubleClick}
                    count={pagination?.count}
                    next={pagination?.next}
                    previous={pagination?.previous}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleOpenDialogHistory={handleOpenDialogHistory}
                    actionsComponent={TableActionComponent}
                    columnStyles={{1: "40%", 2: "40%", 3: "20%"}}
                    showActions={["history", "edit", "delete", "duplicate"]}
                />
            </Paper>
            <HistoryComponent
                openHistory={openHistory}
                handleCloseDialogHistory={handleCloseDialogHistory}
                idHistory={idHistory}
                columns_history={["product", "active"]}
                service={service}
            />
            <Dialog open={openDialogDuplicate} onClose={() => setOpenDialogDuplicate(false)}>
                <Grid padding={2} container justifyContent="space-between" sx={{backgroundColor: "primary.main"}}>
                    <Grid item sx={{marginRight: 5}}>
                        <Typography sx={{color: "#ffffff", fontWeight: "bold", fontSize: "16px"}}>
                            {translate.t("duplicate")}&nbsp;
                            {translate.t("smt_default_time")}
                        </Typography>
                    </Grid>
                    <Grid item sx={{cursor: "pointer", color: "#ffffff"}}>
                        <Close onClick={() => setOpenDialogDuplicate(false)}/>
                    </Grid>
                </Grid>
                <DialogContent>
                    <Grid container flexDirection="column" gap={1}>
                        <AutocompletePaginated
                            display={["model","name"]}
                            searchField="full_description"
                            label="product"
                            service={serviceProduct}
                            onSelectElement={(product: Product) => {
                                setProductDuplicate(product);
                            }}
                            autoFocus={true}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="flex-end" gap={1} padding={2}>
                        <Grid item>
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => setOpenDialogDuplicate(false)}
                            >
                                {translate.t("cancel")}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="info"
                                onClick={duplicateNow}
                                disabled={productDuplicate === null}
                            >
                                {translate.t("duplicate")}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default SmtDefaultTimeComponent;
