import * as React from "react";
import {useEffect, useState} from "react";
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import EChartsReact, {useChart} from "echarts-for-react-fc";
import * as echarts from "echarts/core";
import {DataZoomComponent, GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent} from "echarts/components";
import {BarChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {ErrorComponent} from "../../../components/error/error.component";
import {LineSchedule} from "../../../models/deshboard/line-schedule";
import {translate} from "../../../translate/translate";
import Loading from "../../../components/loading/loading";

echarts.use([GridComponent, BarChart, CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, DataZoomComponent]);

const initBufferChartOption: any = {
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "shadow"
        }
    },
    toolbox: {
        feature: {
            saveAsImage: {show: true}
        }
    },
    legend: {
        data: []
    },
    xAxis: {
        type: "category",
        data: [],
        axisLabel: {
            rotate: 45,
            interval: 0
        }
    },
    yAxis: {
        type: "value",
        name: "",
        axisLabel: {
            formatter: "{value}"
        }
    },
    series: []
};

const DashboardBufferChartComponent = () => {
    const service = new BaseService<LineSchedule>(URLS.LINE_SCHEDULE);
    const [showBufferChart, setShowBufferChart] = useState<boolean>(false);
    const {chartRef: bufferChartRef, setChartOption: setBufferChartOption, handleListenChartReady: handleBufferChartReady} = useChart();
    const [loading, setLoading] = React.useState(false);
    const [chartData, setChartData] = useState<any[]>([]);

    useEffect(() => {
        getData();
    }, []);

    const setBufferChartValues = (data: any[]) => {
        const option = JSON.parse(JSON.stringify(initBufferChartOption));
        const months = data.map(item => item.month);
        const volumes = data.map(item => item.volume);
        const offline = data.map(item => item.offline);
        const target = data.map(item => item.target);
        setChartData(data);

        option.xAxis.data = months;

        option.series = [
            {
                name: "VOLUME",
                type: "bar",
                stack: "total",
                data: volumes,
                itemStyle: {
                    color: "#8faadc",
                }
            },
            {
                name: "OFFLINE PROJECTS",
                type: "bar",
                stack: "total",
                data: offline,
                itemStyle: {
                    color: "#5b9bd5",
                }
            },
            {
                name: "TARGET",
                type: "line",
                data: target,
                symbol: "none",
                lineStyle: {
                    color: "#002060"
                },
            }
        ];

        option.tooltip = {
            trigger: "axis",
            axisPointer: {
                type: "shadow"
            },
            formatter: (params: any) => {
                const sortedParams = params.sort((a: any, b: any) => {
                    if (a.seriesName === "TARGET") return -1;
                    if (b.seriesName === "TARGET") return 1;
                    return 0;
                });

                const tooltipContent = sortedParams
                    .map((item: any) => `
                    <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${item.color};"></span>
                    <span style="display:inline-block;text-align:left; padding-right: 16px;">${item.seriesName}</span>
                    <strong style="float:right;">${item.value}</strong>`
                    ).join("<br/>");
                return `<div>${tooltipContent}</div>`;
            }
        };

        option.legend = {
            show: true,
            data: ["VOLUME", "OFFLINE PROJECTS", "TARGET"]
        };

        setBufferChartOption(option, true);
        setShowBufferChart(true);
    };

    const getData = () => {
        setLoading(true);
        service.getFromListRoute("get_buffer_chart_data")
            .then((response: any) => {
                setLoading(false);
                setBufferChartValues(response.data);
            })
            .catch((error: any) => {
                setLoading(false);
                ErrorComponent(error);
            });
    };

    const renderTable = () => {
        if (chartData.length === 0) return null;

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            {chartData.map((item: any, index: number) => (
                                <TableCell key={index} align="center">{item.month}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>OFFLINE PROJECTS</TableCell>
                            {chartData.map((item: any, index: number) => (
                                <TableCell key={index} align="center">{item.offline}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell>DELTA</TableCell>
                            {chartData.map((item: any, index: number) => {
                                const delta = item.offline - item.target;
                                return (
                                    <TableCell key={index} align="center">{delta}</TableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow>
                            <TableCell>TARGET</TableCell>
                            {chartData.map((item: any, index: number) => (
                                <TableCell key={index} align="center">{item.target}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell>VOLUME</TableCell>
                            {chartData.map((item: any, index: number) => (
                                <TableCell key={index} align="center">{item.volume}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell>STATUS</TableCell>
                            {chartData.map((item: any, index: number) => {
                                const delta = item.offline - item.target;
                                const status = delta > 0 ? "NOK" : "OK";
                                return (
                                    <TableCell key={index} align="center">{status}</TableCell>
                                );
                            })}
                        </TableRow>
                        <TableRow>
                            <TableCell>RESULT</TableCell>
                            {chartData.map((item: any, index: number) => (
                                <TableCell key={index} align="center">{item.volume + item.offline}</TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Grid>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("buffer_chart")}
                </Typography>
            </div>
            <Loading open={loading}/>
            {showBufferChart && (
                <Grid container direction={"column"} gap={0} marginTop={1}>
                    <Grid item xs>
                        <Paper id={"chart-buffer"} sx={{padding: "16px"}}>
                            <EChartsReact
                                autoResize={true}
                                style={{width: "100%", height: 500}}
                                ref={bufferChartRef}
                                echarts={echarts}
                                onChartReady={handleBufferChartReady}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs sx={{paddingTop: "16px"}}>
                        {renderTable()}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default DashboardBufferChartComponent;
