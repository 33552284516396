import React, {useEffect, useState} from "react";
import {Box, Button, Card, CardContent, Typography} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Edit} from "@mui/icons-material";
import {BaseService} from "../../../../../services/base-service";
import {URLS} from "../../../../../services/app-urls";
import {ActiveLineShift} from "../../../../../models/basic/active-line-shift";
import {translate} from "../../../../../translate/translate";
import DialogActiveLineShiftComponent from "./dialog-active-line-shift/dialog-active-line-shift.component";

const ActiveLineShiftComponent = (props: any) => {
    const { activeLineCount } = props;
    const service = new BaseService<ActiveLineShift>(URLS.ACTIVE_LINE_SHIFT);
    const [datesObject, setDatesObject] = useState<any>();
    const [openLineShiftDialog, setOpenLineShiftDialog] = useState(false);
    const [lineShiftDay, setLineShiftDay] = useState<any[]>([]);
    const [lineShift, setLineShift] = useState<any[]>([]);
    const [weekTitle, setWeekTitle] = useState("");

    useEffect(() => {
        if (activeLineCount.id) {
            getData();
        }
    }, [activeLineCount]);

    const getData = () => {
        service.clearParameters();
        service.addParameter("active_line_count", activeLineCount.id);
        service.getFromListRoute("generate_dates_for_year").then(response => setDatesObject(response.data));
    };

    const handleOpenLineShiftDialog = (event: any, day: any, shift: any, weekTitle = "", week = false) => {
        const days = [];
        const shifts = [];
        if (week) {
            Object.keys(day).forEach((d: any) => days.push(d));
            Object.values(day).forEach((d: any) => shifts.push(d));
        } else {
            days.push(day);
            shifts.push(shift);
        }
        setWeekTitle(weekTitle);
        setLineShiftDay(days);
        setLineShift(shifts);
        setOpenLineShiftDialog(true);
    };

    const handleLineShiftDialogClose = (result: boolean) => {
        setLineShiftDay([]);
        setOpenLineShiftDialog(false);
        if (result) {
            getData();
        }
    };



    return (
        <Box sx={{width: "100%"}}>
            {/* Meses */}
            {datesObject && Object.keys(datesObject).map((month) => {
                return (
                    <Accordion key={month}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Box display="flex" gap="10px" alignItems="center">
                                <Typography width={"130px"} variant={"h6"} borderRadius={"4px"} sx={{padding: "4px 8px", backgroundColor: "#E3F2FD"}}>
                                    {translate.t(month)}
                                </Typography>
                                <Box display="flex" flexDirection="row">
                                    {Object.keys(datesObject[month]["shifts"]).map((shift) => {
                                        return (
                                            <Box className="shift-data" key={shift} display="flex" flexDirection="row" gap={1} alignItems="start">
                                                <Typography variant="h6">
                                                    {shift}:
                                                </Typography>
                                                <Typography fontSize={"1.1em"} lineHeight={"1.6"}>
                                                    {datesObject[month]["shifts"][shift]}
                                                </Typography>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            {/* Semanas */}
                            {Object.keys(datesObject[month]["weeks"]).map((week) => {
                                return (
                                    <Accordion key={week}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                            <Box display="flex" gap="10px" alignItems="center">
                                                <Typography width={"130px"} variant={"h6"} borderRadius={"4px"} sx={{padding: "4px 8px", backgroundColor: "#E0F2F1"}}>
                                                    {week}
                                                </Typography>
                                                <Box display="flex" flexDirection="row">
                                                    {Object.keys(datesObject[month]["weeks"][week]["shifts"]).map((shift) => {
                                                        return (
                                                            <Box className="shift-data" gap={1} key={shift} display="flex" flexDirection="row" alignItems="start">
                                                                <Typography variant={"h6"}>
                                                                    {shift}:
                                                                </Typography>
                                                                <Typography fontSize={"1.1em"} lineHeight={"1.6"}>
                                                                    {datesObject[month]["weeks"][week]["shifts"][shift]}
                                                                </Typography>
                                                            </Box>
                                                        );
                                                    })}
                                                </Box>
                                                <Button
                                                    color={"secondary"}
                                                    size="small"
                                                    onClick={(event) => handleOpenLineShiftDialog(event, datesObject[month]["weeks"][week]["days"], datesObject[month]["weeks"][week], week, true)}>
                                                    <Edit/>
                                                </Button>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {/* Dias */}
                                            <Box display="flex" flexDirection={"row"} flexWrap={"wrap"} sx={{gap: 2}}>
                                                {Object.keys(datesObject[month]["weeks"][week]["days"]).map((day) => {
                                                    return (
                                                        <Card sx={{width: "180px", border: "1px solid rgba(0, 0, 0, .2)", boxShadow: "none", backgroundColor: "#F0F8FF" }} key={day}>
                                                            <CardContent>
                                                                <Box marginBottom={"8px"} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                                                    <Typography variant={"h6"} gutterBottom sx={{ color: "text.secondary", fontSize: 14 , margin: 0}}>
                                                                        {day}
                                                                    </Typography>

                                                                    <Button
                                                                        color={"secondary"}
                                                                        size="small"
                                                                        onClick={(event) => handleOpenLineShiftDialog(event, day, datesObject[month]["weeks"][week]["days"][day])}>
                                                                        <Edit/>
                                                                    </Button>
                                                                </Box>
                                                                {Object.keys(datesObject[month]["weeks"][week]["days"][day]).map((shift) => {
                                                                    return (
                                                                        <Box className="active-line" marginBottom={"4px"} key={shift} display="flex" flexDirection="row" justifyContent="space-between">
                                                                            <Typography>
                                                                                {shift}:
                                                                            </Typography>
                                                                            <Typography variant="h6">
                                                                                {datesObject[month]["weeks"][week]["days"][day][shift]["quantity"]}
                                                                            </Typography>
                                                                        </Box>
                                                                    );
                                                                })}
                                                            </CardContent>
                                                        </Card>
                                                    );
                                                })}
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
            {openLineShiftDialog && lineShiftDay && (
                <DialogActiveLineShiftComponent
                    activeLineCount={activeLineCount}
                    activeLineShiftDate={lineShiftDay}
                    activeLineShift={lineShift}
                    weekTitle={weekTitle}
                    open={openLineShiftDialog}
                    onClose={(result: boolean) => handleLineShiftDialogClose(result)}>
                </DialogActiveLineShiftComponent>)}
        </Box>
    );
};

export default ActiveLineShiftComponent;
