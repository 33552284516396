export class Month {
    public static load(): Month[] {
        const months: Month[] = [];
        months.push(new Month(1, "january"));
        months.push(new Month(2, "february"));
        months.push(new Month(3, "march"));
        months.push(new Month(4, "april"));
        months.push(new Month(5, "may"));
        months.push(new Month(6, "june"));
        months.push(new Month(7, "july"));
        months.push(new Month(8, "august"));
        months.push(new Month(9, "september"));
        months.push(new Month(10, "october"));
        months.push(new Month(11, "november"));
        months.push(new Month(12, "december"));
        return months;
    }

    public static loadObject(): any {
        return {
            1: "january",
            2: "february",
            3: "march",
            4: "april",
            5: "may",
            6: "june",
            7: "july",
            8: "august",
            9: "september",
            10: "october",
            11: "november",
            12: "december"
        };
    }

    constructor(public number: number, public description: string) {
    }
}
