import {environment} from "../../environments/environment";

export interface Dictionary {
    [index: string]: string | number | any;
}


export class WebSocketService {
    baseWebSocketURL: string | undefined;
    path: string;
    fullURL: string;
    websocket: WebSocket | undefined;

    constructor(path: string) {
        this.baseWebSocketURL = environment.webSocketBaseURL;
        this.path = path;
        this.fullURL = `${this.baseWebSocketURL}/${this.path}/`;
        this.createWebSocketConnection();
    }

    createWebSocketConnection() {
        this.websocket = new WebSocket(this.fullURL);
    }

    onMessage(callback: (ev?: MessageEvent<any>) => any) {
        this.websocket?.addEventListener("message", callback);
    }

    close() {
        this.websocket?.close();
    }
}
