import * as React from "react";
import {useEffect} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Fab,
    Grid,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {translate} from "../../../../translate/translate";
import {ProductionOrderDemand} from "../../../../models/basic/production-order-demand";
import {BaseService} from "../../../../services/base-service";
import {Demand} from "../../../../models/basic/demand";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import {PaginatedResult} from "../../../../models/default/paginated-result";
import {Field, Form, Formik} from "formik";
import {Search} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import TablePaginationComponent from "../../../../components/table/table-pagination.component";
import TableActionComponent from "../../../../components/table/table-action.component";
import DialogProductionOrderDemandComponent from "./dialog-production-order-demand.component";
import HistoryComponent from "../../../../components/history/history.component";
import {ErrorComponent} from "../../../../components/error/error.component";

const service = new BaseService<ProductionOrderDemand>(URLS.PRODUCTION_ORDER_DEMAND);

const ProductionOrderDemandComponent = (props: any) => {
    const {demand} = props;
    const url = `/planning/demand/${demand.id}`;
    const [expanded, setExpanded] = React.useState<boolean>(true);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [pagination, setPagination] = React.useState<PaginatedResult<ProductionOrderDemand>>();
    const [openHistory, setOpenHistory] = React.useState(false);
    const [idHistory, setIdHistory] = React.useState(0);
    const [dataSource, setDataSource] = React.useState<ProductionOrderDemand[]>([]);
    const [searchName, setSearchName] = React.useState<string>("");
    const [openDialog, setOpenDialog] = React.useState(false);
    const [typeOperation, setTypeOperation] = React.useState<string>("");
    const [productionOrderDemand, setProductionOrderDemand] = React.useState<ProductionOrderDemand>(new ProductionOrderDemand());

    useEffect(() => {
        search();
    }, [demand]);

    useEffect(() => {
        setDataSource(pagination?.results ?? []);
    }, [pagination]);

    const search = () => {
        if (demand.id) {
            service.clearParameters();
            if (searchName) service.addParameter("production_order", searchName);
            service.addParameter("demand", demand.id);
            service.addParameter("limit", rowsPerPage);
            service.addParameter("offset", (page * rowsPerPage));
            service.getAllPaginate().then(response => {
                setPagination(response.data);
            }).catch((error: any) => {
                ErrorComponent(error);
            });
        }
    };

    const handleChange = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        search();
    }, [page, rowsPerPage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDelete = (row: Demand) => {
        service.delete(Number(row.id)).then(() => {
            toast.success(translate.t("successfully_deleted"));
            service.getAll().then(() => {
                search();
            });
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handleOpenDialogHistory = (id: number) => {
        setIdHistory(id);
        setOpenHistory(true);
    };

    const handleCloseDialogHistory = () => {
        setOpenHistory(false);
    };

    const addScheduledStop = () => {
        setTypeOperation("add");
        setProductionOrderDemand(new ProductionOrderDemand());
        setOpenDialog(true);
    };

    const editWithDialog = (row: ProductionOrderDemand) => {
        setTypeOperation("edit");
        setProductionOrderDemand(row);
        setOpenDialog(true);
    };

    return (
        <Box sx={{marginTop: 2}}>
            <Accordion expanded={expanded} onChange={handleChange}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{backgroundColor: "#e4e4e4", borderTopRightRadius: 5, borderTopLeftRadius: 5}}
                >
                    <Typography
                        sx={{fontWeight: "bold"}}
                    >
                        {translate.t("production_order_demand")}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Formik
                        enableReinitialize={true}
                        validateOnMount={true}
                        initialValues={{name: ""}}
                        onSubmit={(values, {setSubmitting}) => {
                            setTimeout(() => {
                                search();
                                setSubmitting(false);
                            }, 500);
                        }}
                    >
                        {
                            () => (
                                <Form autoComplete="off" style={{marginTop: 10}}>
                                    <Grid alignItems="center" container spacing={2} direction={"row"}>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="name"
                                                label={translate.t("production_order")}
                                                fullWidth
                                                variant={"outlined"}
                                                autoFocus={true}
                                                value={searchName}
                                                onChange={(event: React.ChangeEvent<{
                                                    value: unknown
                                                }>) => setSearchName(event.target.value as string)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Fab
                                                size="small"
                                                type="submit"
                                                color="secondary">
                                                <Search/>
                                            </Fab>
                                        </Grid>
                                        <Grid item>
                                            <Fab
                                                size="small"
                                                color="secondary"
                                                value="add_schedule_stop"
                                                onClick={() => {
                                                    addScheduledStop();
                                                }}
                                            >
                                                <AddIcon/>
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                    </Formik>
                    <Paper variant="outlined" sx={{marginTop: "16px"}}>
                        <TablePaginationComponent
                            state={dataSource}
                            displayedColumnsShow={["#", "production_order", "action"]}
                            displayedColumns={["id", "production_order", "action"]}
                            handleDelete={handleDelete}
                            url={url}
                            handleDoubleClick={editWithDialog}
                            editWithDialog={editWithDialog}
                            count={pagination?.count}
                            next={pagination?.next}
                            previous={pagination?.previous}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            actionsComponent={TableActionComponent}
                            handleOpenDialogHistory={handleOpenDialogHistory}
                            columnStyles={{1: "20%", 2: "80%"}}
                        />
                    </Paper>
                    <HistoryComponent
                        openHistory={openHistory}
                        handleCloseDialogHistory={handleCloseDialogHistory}
                        idHistory={idHistory}
                        columns_history={["production_order"]}
                        service={service}
                    />
                </AccordionDetails>
            </Accordion>
            <DialogProductionOrderDemandComponent
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                typeOperation={typeOperation}
                productionOrderDemand={productionOrderDemand}
                demand={demand}
                service={service}
                search={search}
            />
        </Box>
    );
};

export default ProductionOrderDemandComponent;
