import * as React from "react";
import {useEffect, useState} from "react";
import {Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import EChartsReact, {EChartsEventInfo, useChart} from "echarts-for-react-fc";
import * as echarts from "echarts/core";
import {DataZoomComponent, GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent} from "echarts/components";
import {BarChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {ErrorComponent} from "../../../components/error/error.component";
import {LineSchedule} from "../../../models/deshboard/line-schedule";
import {translate} from "../../../translate/translate";
import Loading from "../../../components/loading/loading";

echarts.use([GridComponent, BarChart, CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, DataZoomComponent]);

const initWipVolumeOption: any = {
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "shadow"
        }
    },
    toolbox: {
        feature: {
            saveAsImage: {show: true}
        }
    },
    legend: {
        data: []
    },
    xAxis: {
        type: "category",
        data: [],
        axisLabel: {
            rotate: 45,
            interval: 0
        }
    },
    yAxis: {
        type: "value",
        name: "",
        axisLabel: {
            formatter: "{value}"
        }
    },
    series: []
};

const DashboardWipVolumeComponent = () => {
    const service = new BaseService<LineSchedule>(URLS.LINE_SCHEDULE);
    const [showBufferChart, setShowBufferChart] = useState<boolean>(false);
    const {chartRef: bufferChartRef, setChartOption: setBufferChartOption, handleListenChartReady: handleBufferChartReady} = useChart();
    const [loading, setLoading] = React.useState(false);
    const [modelsList, setModelsList] = useState<any[]>([]);

    useEffect(() => {
        getData();
    }, []);

    const setBufferChartValues = (data: any[]) => {
        const option = JSON.parse(JSON.stringify(initWipVolumeOption));
        const months = data.map(item => item.month);
        const volumes = data.map(item => item.volume);

        option.xAxis.data = months;

        option.series = [
            {
                name: "VOLUME",
                type: "bar",
                stack: "total",
                data: volumes,
                itemStyle: {
                    color: "#8faadc",
                }
            }
        ];

        option.tooltip = {
            trigger: "axis",
            axisPointer: {
                type: "shadow"
            },
            formatter: (params: any) => {
                const tooltipContent = params
                    .map((item: any) => `
                    <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${item.color};"></span>
                    <span style="display:inline-block;text-align:left; padding-right: 16px;">${item.seriesName}</span>
                    <strong style="float:right;">${item.value}</strong>`
                    ).join("<br/>");
                return `<div>${tooltipContent}</div>`;
            }
        };

        option.legend = {
            show: true,
            data: ["VOLUME"]
        };

        setBufferChartOption(option, true);
        setShowBufferChart(true);
    };

    const getData = () => {
        setLoading(true);
        service.getFromListRoute("get_wip_volume_data")
            .then((response: any) => {
                setLoading(false);
                setBufferChartValues(response.data);
            })
            .catch((error: any) => {
                setLoading(false);
                ErrorComponent(error);
            });
    };

    const handleBarClick = (params: any) => {
        const monthClicked = params.name;
        getModelsForMonth(monthClicked);
    };

    const getModelsForMonth = (month: string) => {
        const [monthNum, year] = month.split("/");

        service.addParameter("year", year);
        service.addParameter("month", monthNum);
        service.getFromListRoute("get_models_for_month")
            .then((response: any) => {
                setModelsList(response.data);
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    const renderModelsTable = () => {
        if (modelsList.length === 0) return null;

        return (
            <TableContainer component={Paper} sx={{marginTop: 2}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{translate.t("model_name")}</TableCell>
                            <TableCell>{translate.t("quantity")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {modelsList.map((model: any, index: number) => (
                            <TableRow key={index}>
                                <TableCell>{model.description}</TableCell>
                                <TableCell>{model.count}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    const onEvents: Record<string, EChartsEventInfo> = {
        click: {
            handler: handleBarClick,
        }
    };

    return (
        <Grid>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("wip_volume")}
                </Typography>
            </div>
            <Loading open={loading}/>
            {showBufferChart && (
                <Grid container direction={"column"} gap={0} marginTop={1}>
                    <Grid item xs>
                        <Paper id={"chart-buffer"} sx={{padding: "16px"}}>
                            <EChartsReact
                                autoResize={true}
                                style={{width: "100%", height: 500}}
                                ref={bufferChartRef}
                                echarts={echarts}
                                onChartReady={handleBufferChartReady}
                                onEvents={onEvents}
                            />
                        </Paper>
                    </Grid>

                    <Grid item xs sx={{paddingTop: "16px"}}>
                        {renderModelsTable()}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default DashboardWipVolumeComponent;
