import BaseModel from "../default/base-model";
import {Segment} from "./segment";
import {Side} from "./side";

export enum PRODUCT_TYPE {
    "P" = "Product",
    "C" = "Component"
}


export class Product extends BaseModel {
    segment: Segment | any;
    name: string;
    model: string;
    type: string;
    sides: Side[] | any;
    side_product_list: any[];
    pn_commercial: string;
    legacy_code: string;
    model_and_name: string;

    constructor() {
        super();
        this.segment = "";
        this.name = "";
        this.model = "";
        this.type = "";
        this.pn_commercial = "";
        this.legacy_code = "";
        this.sides = [];
        this.side_product_list = [];
        this.model_and_name = "";
    }
}
