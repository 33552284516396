import {Box, Fab, Grid} from "@mui/material";
import * as React from "react";
import {useEffect} from "react";
import {Form, Formik} from "formik";
import {Product} from "../../../../models/basic/product";
import {Search} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {Demand} from "../../../../models/basic/demand";
import AvailableMenuComponent from "./available-menu/available-menu.component";
import PlannedMenuComponent from "./planned-menu/planned-menu.component";
import FinalizedMenuComponent from "./finalized-menu/finalized-menu.component";
import ProductionMenuComponent from "./production-menu/production-menu.component";
import "./demand-menu.component.css";
import {ErrorComponent} from "../../../../components/error/error.component";
import DelayedMenuComponent from "./delayed-menu/delayed-menu.component";
import AutocompletePaginated from "../../../../components/autocomplete/autocompletePaginated";


const DemandMenuComponent = (props: any) => {
    const {
        onPlanning,
        version,
        searchDemand
    } = props;

    const [productValue, setProductValue] = React.useState<Product | null>(null);
    const serviceProduct = new BaseService<Product>(URLS.PRODUCT);
    const serviceDemand = new BaseService<Demand>(URLS.DEMAND);
    const [demand, setDemand] = React.useState<Demand[]>([]);

    useEffect(() => {
        search();
    }, []);

    useEffect(() => {
        search();
    }, [version, searchDemand]);

    const search = () => {
        serviceDemand.clearParameters();
        if (productValue) {
            serviceDemand.addParameter("product", productValue.id);
        }
        serviceDemand.addParameter("version", version);
        serviceDemand.addParameter("active", true);
        serviceDemand.addParameter("ordering", "reference_date");
        serviceDemand.addParameter("expand", "product,version");
        serviceDemand.getAllFromListRoute("board").then(response => {
            setDemand(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handlePlanning = () => {
        search();
        onPlanning();
    };

    return (
        <Box>
            <Box sx={{padding: "10px"}}>
                <Formik
                    enableReinitialize={true}
                    validateOnMount={true}
                    initialValues={{product: ""}}
                    onSubmit={(values, {setSubmitting}) => {
                        setTimeout(() => {
                            search();
                            setSubmitting(false);
                        }, 500);
                    }}
                >
                    {() => (
                        <Form autoComplete="off">
                            <Grid alignItems="center" container spacing={1} direction={"row"}>
                                <Grid item xs>
                                    <AutocompletePaginated
                                        display={["model","name"]}
                                        searchField="full_description"
                                        label="product"
                                        service={serviceProduct}
                                        onSelectElement={(product: Product) => {
                                            setProductValue(product);
                                        }}
                                        autoFocus={true}
                                    />
                                </Grid>
                                <Grid item>
                                    <Fab
                                        size="small"
                                        type="submit"
                                        color="secondary">
                                        <Search/>
                                    </Fab>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
            <Divider/>
            <AvailableMenuComponent available={demand} handleSearch={handlePlanning}/>
            <Divider/>
            <PlannedMenuComponent planned={demand} handleSearch={handlePlanning} version={version}/>
            <Divider/>
            <ProductionMenuComponent production={demand} handleSearch={handlePlanning} version={version}/>
            <Divider/>
            <FinalizedMenuComponent finalized={demand} handleSearch={handlePlanning} version={version}/>
            <Divider/>
            <DelayedMenuComponent delayed={demand} handleSearch={handlePlanning} version={version}/>
        </Box>
    );
};

export default DemandMenuComponent;
