import "./planning-by-delivery.component.css";
import {Box, Fab, Grid, IconButton, Paper, TablePagination, TextField, Typography} from "@mui/material";
import {translate} from "../../../translate/translate";
import * as React from "react";
import {useEffect, useState} from "react";
import {Field, Form, Formik} from "formik";
import AutocompletePaginated from "../../../components/autocomplete/autocompletePaginated";
import {Product} from "../../../models/basic/product";
import {Assignment, MoreVertOutlined, Search, Update, UploadFile} from "@mui/icons-material";
import {ErrorComponent} from "../../../components/error/error.component";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {DateNowEnUS} from "../../../components/date/date-timeFormat";
import {Delivery} from "../../../models/planning/delivery";
import DialogCreateDemandComponent from "./dialog-create-demand/dialog-create-demand.component";
import DialogPlanningDemandComponent
    from "../../basic/production-planning/dialog-planning-demand/dialog-planning-demand.component";
import {toast} from "react-toastify";
import Loading from "../../../components/loading/loading";
import TableActionComponent from "../../../components/table/table-action.component";
import DeliveryDetailComponent from "./delivery-detail/delivery-detail.component";


class DemandQuantityDto {
    balance: number;
    delivery: number;
    projected_dsi: number;
    consuming_planned: boolean;

    constructor() {
        this.balance = 0;
        this.delivery = 0;
        this.projected_dsi = 0;
        this.consuming_planned = false;
    }
}

class ResultProductDto {
    product: string;
    product_id: number;
    balance: number;
    on_hands: number;
    spreadsheet: number;
    not_planned: number;
    planned: number;
    demand_quantities: DemandQuantityDto[];

    constructor() {
        this.product = "";
        this.product_id = 0;
        this.on_hands = 0;
        this.balance = 0;
        this.spreadsheet = 0;
        this.not_planned = 0;
        this.planned = 0;
        this.demand_quantities = [];
    }
}

class ResultDto {
    days: string[];
    products: ResultProductDto[];
    count: number;

    constructor() {
        this.days = [];
        this.products = [];
        this.count = 0;
    }
}

const PlanningByDeliveryComponent = () => {
    const service = new BaseService<Delivery>(URLS.DELIVERY);
    const productService = new BaseService<Product>(URLS.PRODUCT);
    const [searchProduct, setSearchProduct] = useState<Product>();
    const [dataSource, setDataSource] = React.useState<ResultDto>();
    const [selectedProduct, setSelectedProduct] = useState<any>();
    const [startDate, setStartDate] = useState<string>(DateNowEnUS(new Date()));
    const [startDateDetail, setStartDateDetail] = useState<string>();
    const [maxQuantity, setMaxQuantity] = useState<any>();
    const [createdDemand, setCreatedDemand] = useState<any>();
    const [openDialogPlanning, setOpenDialogPlanning] = useState(false);
    const [openDialogCreateDemand, setOpenDialogCreateDemand] = useState(false);
    const [openDialogDeliveryDetail, setOpenDialogDeliveryDetail] = useState(false);
    const [productDelivery, setProductDelivery] = useState<any>();
    const [loading, setLoading] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [page, setPage] = React.useState(0);
    const [selectedFiles, setSelectedFiles] = React.useState<any>();
    const fileInputRef = React.useRef<any>(null);
    const [importStartDate, setImportStartDate] = useState<Date | undefined>(undefined);

    const search = () => {
        setLoading(true);
        service.clearParameters();
        if (searchProduct) service.addParameter("product", searchProduct.id);
        const end = new Date(startDate);
        end.setDate(end.getDate() + 29);
        service.addParameter("start_date", startDate);
        service.addParameter("end_date", DateNowEnUS(end));
        service.addParameter("limit", rowsPerPage);
        service.addParameter("offset", (page * rowsPerPage));
        service.getFromListRoute("get_planning_by_delivery").then((response: any) => {
            setDataSource(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        }).finally(() => setLoading(false));
    };

    const updateDeliveries = () => {
        setLoading(true);
        service.clearParameters();
        service.addParameter("update_start_date", startDate);
        service.getFromListRoute("update_delivery_values").then((response: any) => {
            setDataSource(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        }).finally(() => setLoading(false));
    };

    useEffect(() => {
        if (createdDemand) {
            setOpenDialogPlanning(true);
        }
    }, [createdDemand]);

    useEffect(() => {
        search();
    }, [page, rowsPerPage]);

    const handleImportStartDateChange = (date: any) => {
        setImportStartDate(date);
    };

    const handleStartDateChange = (date: any) => {
        setStartDate(date);
    };

    const formatDate = (date: string) => {
        return date.split("-")[2].padStart(2, "0") + "/" + date.split("-")[1].padStart(2, "0") + "/" + date.split("-")[0];
    };

    const getQuantityClass = (quantity: any) => {
        let css_class = "";
        if (quantity.projected_dsi < 3) {
            css_class = "warn";
        } else if (quantity.projected_dsi >= 3 && quantity.projected_dsi < 6) {
            css_class = "yellow";
        } else if (quantity.projected_dsi >= 6 && quantity.projected_dsi < 10) {
            css_class = "green";
        } else {
            css_class = "info";
        }
        return css_class;
    };

    const handleOpenDialogPlanning = (planning: boolean, demand: any) => {
        setOpenDialogCreateDemand(false);
        if (planning) {
            setCreatedDemand(demand);
        }
    };

    const handleOpenDialogCreateDemand = (event: any, item: any) => {
        const availableQuantity = (item.spreadsheet + item.on_hands) - (item.not_planned + item.planned);
        if (availableQuantity > 0) {
            setSelectedProduct(item.product_id);
            setMaxQuantity(availableQuantity >= 0 ? availableQuantity : 0);
            setOpenDialogCreateDemand(true);
        } else {
            toast.error(translate.t("invalid_quantity"));
        }
    };

    const handleOpenDialogDeliveryDetail = (event: any, item: any) => {
        setProductDelivery(item.product_id);
        setStartDateDetail(startDate);
        setOpenDialogDeliveryDetail(true);
    };

    const handleCloseDialog = (result: boolean) => {
        setOpenDialogPlanning(false);
        if (result) {
            search();
        }
    };

    const handleCloseDeliveryDetailDialog = () => {
        setOpenDialogDeliveryDetail(false);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const showPaginated = (from: number, to: number, count: number) => {
        return `${from} - ${to} ${translate.t("of")} ${count}`;
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    return (
        <Box minHeight={"100%"} display={"flex"} flexDirection={"column"}>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("planning_by_delivery")}
                </Typography>
            </div>
            <Loading open={loading}/>
            <Grid container spacing={2} direction={"row"}>
                <Grid item sm>
                    <Paper variant="outlined">
                        <Box p={2}>
                            <Formik
                                enableReinitialize={true}
                                validateOnMount={true}
                                initialValues={{}}
                                onSubmit={(values, {setSubmitting}) => {
                                    setTimeout(() => {
                                        setLoading(true);
                                        service.addParameter("import_start_date", importStartDate);
                                        service.uploadFile("delivery_import", selectedFiles)
                                            .then(() => {
                                                toast.success(translate.t("successfully_uploaded"));
                                                fileInputRef.current.value = null;
                                                setSelectedFiles(null);
                                                search();
                                            })
                                            .catch((error: any) => {
                                                ErrorComponent(error);
                                            })
                                            .finally(() => setLoading(false));
                                        setSubmitting(false);
                                    }, 500);
                                }}
                            >
                                {
                                    () => (
                                        <Form autoComplete="off">
                                            <Grid alignItems="center" container spacing={2} direction={"row"}>
                                                <Grid item xs>
                                                    <Field
                                                        as={TextField}
                                                        className={"input-file"}
                                                        name="file"
                                                        label={""}
                                                        type={"file"}
                                                        variant={"outlined"}
                                                        fullWidth
                                                        autoFocus={false}
                                                        inputProps={{
                                                            accept: ".xls,.xlsx,.xlsm, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                                        }}
                                                        inputRef={fileInputRef}
                                                        onChange={(event: any) => {
                                                            const files = Array.from(event.target.files);
                                                            setSelectedFiles(files);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Field
                                                        as={TextField}
                                                        id="id_start_date"
                                                        name="start_date"
                                                        label={translate.t("start_date")}
                                                        required={true}
                                                        onChange={(event: any) => handleImportStartDateChange(event.target.value as string)}
                                                        type="date"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Fab
                                                        size="small"
                                                        type="submit"
                                                        color="secondary"
                                                        disabled={!selectedFiles || !importStartDate}>
                                                        <UploadFile/>
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                            </Formik>
                        </Box>
                    </Paper>
                </Grid>

                <Grid item sm>
                    <Paper variant="outlined">
                        <Box p={2}>
                            <Formik
                                enableReinitialize={true}
                                validateOnMount={true}
                                initialValues={{name: "", start_date_filter: startDate}}
                                onSubmit={(values: any, {setSubmitting}) => {
                                    setTimeout(() => {
                                        search();
                                        setSubmitting(false);
                                    }, 500);
                                }}
                            >
                                {
                                    () => (
                                        <Form autoComplete="off">
                                            <Grid alignItems="center" container spacing={2} direction={"row"}>
                                                <Grid item xs>
                                                    <AutocompletePaginated
                                                        display={["model", "name"]}
                                                        searchField="full_description"
                                                        label="product"
                                                        service={productService}
                                                        onSelectElement={(product: Product) => {
                                                            setSearchProduct(product);
                                                        }}
                                                        autoFocus={true}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Field
                                                        as={TextField}
                                                        id="id_start"
                                                        name="start_date_filter"
                                                        label={translate.t("start_date")}
                                                        value={startDate}
                                                        required={true}
                                                        onChange={(event: any) => handleStartDateChange(event.target.value as string)}
                                                        type="date"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <Fab
                                                        size="small"
                                                        type="submit"
                                                        disabled={!startDate}
                                                        color="secondary">
                                                        <Search/>
                                                    </Fab>
                                                </Grid>
                                                <Grid item>
                                                    <Fab
                                                        size="small"
                                                        type="submit"
                                                        disabled={!startDate}
                                                        onClick={updateDeliveries}
                                                        color="secondary">
                                                        <Update/>
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                            </Formik>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            {dataSource && (<div className="mat-repeat-container">
                <div className="mat-repeat-scroller">
                    <Box position="sticky" top="0" zIndex={3}>
                        <Box width="auto" display="flex" flexDirection="row">
                            <Box position="sticky" left="0" display="flex" flexDirection="column" className="product">
                                <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                    <Typography variant="body2" color="white">{translate.t("product")}</Typography>
                                </Box>
                            </Box>
                            <Box position="sticky" left="150px" display="flex" flexDirection="column" className="header-date">
                                <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                    <Typography variant="body2">{translate.t("balance")}</Typography>
                                </Box>
                            </Box>
                            <Box position="sticky" left="240px" display="flex" flexDirection="column" className="header-date">
                                <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                    <Typography variant="body2">{translate.t("On hands")}</Typography>
                                </Box>
                            </Box>
                            <Box position="sticky" left="330px" display="flex" flexDirection="column" className="header-date">
                                <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                    <Typography variant="body2">{translate.t("input")}</Typography>
                                </Box>
                            </Box>
                            <Box position="sticky" left="420px" display="flex" flexDirection="column" className="header-date">
                                <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                    <Typography variant="body2">{translate.t("planned")}</Typography>
                                </Box>
                            </Box>
                            <Box position="sticky" left="510px" display="flex" flexDirection="column" className="header-date">
                                <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                    <Typography variant="body2">{translate.t("no_planned")}</Typography>
                                </Box>
                            </Box>
                            <Box position="sticky" left="600px" display="flex" flexDirection="column" className="header-date">
                                <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                    <Typography variant="body2">{translate.t("planning")}</Typography>
                                </Box>
                            </Box>
                            {dataSource.days.map((day, index) => {
                                return (
                                    <Box key={index} display="flex" flexDirection="column" className="header-date">
                                        <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-date">
                                            <Typography variant="body2">{formatDate(day)}</Typography>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                    {dataSource.products.map((item, index) => {
                        return (
                            <Box key={index}>
                                <Box display="flex" flexDirection="row">
                                    <Box position="sticky" zIndex={2} left="0" display="flex" flexDirection="column" className="product">
                                        <Box style={{backgroundColor: "#f5f5f5", paddingLeft: "0", paddingRight: "8px"}}
                                            onClick={(event) => handleOpenDialogDeliveryDetail(event, item)}
                                            display="flex" flex="1" justifyContent="left" alignItems="center" overflow="hidden">
                                            <IconButton aria-label="delete"><MoreVertOutlined/></IconButton>
                                            <Typography variant="body2" fontWeight="bold">{item.product}</Typography>
                                        </Box>
                                    </Box>
                                    <Box position="sticky" left="150px" display="flex" flexDirection="column" className="line-date">
                                        <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                            <Typography variant="body2">{item.balance}</Typography>
                                        </Box>
                                    </Box>
                                    <Box position="sticky" left="240px" display="flex" flexDirection="column" className="line-date">
                                        <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                            <Typography variant="body2">{item.on_hands}</Typography>
                                        </Box>
                                    </Box>
                                    <Box position="sticky" left="330px" display="flex" flexDirection="column" className="line-date">
                                        <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                            <Typography variant="body2">{item.spreadsheet}</Typography>
                                        </Box>
                                    </Box>
                                    <Box position="sticky" left="420px" display="flex" flexDirection="column" className="line-date">
                                        <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                            <Typography variant="body2">{item.planned}</Typography>
                                        </Box>
                                    </Box>
                                    <Box position="sticky" left="510px" display="flex" flexDirection="column" className="line-date">
                                        <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                            <Typography variant="body2">{item.not_planned}</Typography>
                                        </Box>
                                    </Box>
                                    <Box position="sticky" left="600px" display="flex" flexDirection="column" className="line-date">
                                        <Box onClick={(event) => handleOpenDialogCreateDemand(event, item)}
                                            display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                            <IconButton>
                                                <Assignment className="person-add"></Assignment>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    {item.demand_quantities.map((quantity, index) => {
                                        return (
                                            <Box key={index} display="flex" flexDirection="column" className="line-date">
                                                <Box className={getQuantityClass(quantity)} display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden">
                                                    <Typography variant="body2">{quantity.projected_dsi}</Typography>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        );
                    })}
                </div>
            </div>)}
            <TablePagination
                sx={{color: "#45485F", textAlign: "end", minHeight: "52px", maxHeight: "52px", backgroundColor: "#FFFFFF"}}
                component="div"
                rowsPerPageOptions={[5, 10, 25, 50]}
                colSpan={12}
                count={Number(dataSource?.count ?? 0)}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TableActionComponent}
                labelRowsPerPage={translate.t("rows_per_page")}
                labelDisplayedRows={({from, to, count}) =>
                    showPaginated(from, to, count)
                }
            />
            <div className="labels">
                <div className="label">
                    <div className="label-color green"></div>
                    <div>{translate.t("safety_stock_ok")}</div>
                </div>
                <div className="label">
                    <div className="label-color info"></div>
                    <div>{translate.t("excess_stock")}</div>
                </div>
                <div className="label">
                    <div className="label-color yellow"></div>
                    <div>{translate.t("stock_on_alert")}</div>
                </div>
                <div className="label">
                    <div className="label-color warn"></div>
                    <div>{translate.t("critical_stock")}</div>
                </div>
            </div>
            {openDialogCreateDemand && (
                <DialogCreateDemandComponent style="width: 1200px!important; max-width: 1200px!important;"
                    open={openDialogCreateDemand} onClose={handleOpenDialogPlanning}
                    product={selectedProduct} maxQuantity={maxQuantity}
                />
            )}
            {openDialogPlanning && (
                <DialogPlanningDemandComponent style="width: 1200px!important; max-width: 1200px!important;" open={openDialogPlanning} onClose={handleCloseDialog} demand={createdDemand}/>
            )}
            {openDialogDeliveryDetail && (
                <DeliveryDetailComponent open={openDialogDeliveryDetail} onClose={handleCloseDeliveryDetailDialog} product={productDelivery} startDate={startDateDetail}/>
            )}
        </Box>
    );
};

export default PlanningByDeliveryComponent;
