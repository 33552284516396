import {BaseService} from "../../../../services/base-service";
import {Box, Button, Grid, InputAdornment, Paper, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {translate} from "../../../../translate/translate";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {Shift} from "../../../../models/basic/shift";
import TabsScheduledStop from "../scheduled-stop-tab/scheduled-stop-tab";
import preventTypeNumber from "../../../../components/number/number.component";
import {ErrorComponent} from "../../../../components/error/error.component";


const ShiftItemComponent = () => {
    const [object, setObject] = React.useState(new Shift());
    const service = new BaseService<Shift>(URLS.SHIFT);
    const params = useParams();
    const url = "/registration/shift/";
    const [buttonValue, setButtonValue] = useState("");
    const navigate = useNavigate();

    const schema = Yup.object().shape({
        name: Yup.string().required(translate.t("required_field")),
        order: Yup.number().required(translate.t("required_field")).positive().integer(),
        start_time: Yup.string().required(translate.t("required_field")),
        end_time: Yup.string().required(translate.t("required_field"))
    });

    useEffect(() => {
        if (params["action"] != "create") {
            service.getById(parseInt(params["action"] as string)).then((response) => {
                setObject(response.data);
            });
        }
    }, []);

    const calculateTimeDifference = (startTime: string, endTime: string) => {
        const [startHours, startMinutes, startSeconds] = startTime.split(":").map(Number);
        const [endHours, endMinutes, endSeconds] = endTime.split(":").map(Number);

        const startTimeInSeconds = (startMinutes * 60) + (startHours * 3600) +
            (isNaN(startSeconds) ? 0 : startSeconds);
        const endTimeInSeconds = (endHours * 3600) + (endMinutes * 60) +
            (isNaN(endSeconds) ? 0 : endSeconds);

        const differenceInSeconds = endTimeInSeconds - startTimeInSeconds;

        const differenceInMinutes = differenceInSeconds / 60;

        return Math.floor(differenceInMinutes);
    };

    return (
        <section>
            <div className="floating-title floating-title-item" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={url}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("shift")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={(values: Shift, {resetForm}: FormikHelpers<Shift>) => {
                                setTimeout(() => {
                                    const shift = new Shift();
                                    values.liquid_minutes = calculateTimeDifference(values.start_time, values.end_time);
                                    Object.assign(shift, values);

                                    if (shift.id) {
                                        service.patch(shift, shift.id)
                                            .then(() => {
                                                toast.success(translate.t("successfully_updated"));
                                                if (buttonValue === "save") {
                                                    setObject(new Shift());
                                                    resetForm();
                                                    navigate(`${url}create`);
                                                } else {
                                                    navigate(url);
                                                }
                                            })
                                            .catch((error: any) => {
                                                ErrorComponent(error);
                                            });
                                    } else {
                                        service.save(shift)
                                            .then((response) => {
                                                toast.success(translate.t("successfully_registered"));
                                                if (buttonValue === "save") {
                                                    setObject(new Shift());
                                                    resetForm();
                                                    navigate(`${url}create`);
                                                } else {
                                                    Object.assign(shift, response.data);
                                                    setObject(shift);
                                                    navigate(`${url}${shift.id}`);
                                                }
                                            })
                                            .catch((error: any) => {
                                                ErrorComponent(error);
                                            });
                                    }
                                }, 500);
                            }}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {({isValid, values, isValidating, errors, touched}) => (
                                <Form autoComplete="off">
                                    <Grid container spacing={2} direction={"column"}>
                                        <Grid item container spacing={2} direction={"row"}>
                                            <Grid item xs>
                                                <Field
                                                    as={TextField}
                                                    name="name"
                                                    label={translate.t("name")}
                                                    variant={"outlined"}
                                                    fullWidth
                                                    autoFocus={true}
                                                    required={true}
                                                    values={values}
                                                    error={touched.name && Boolean(errors.name)}
                                                    InputProps={{
                                                        maxLength: 100,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {touched.name && Boolean(errors.name) && (
                                                                    <div className={"required-field"}>
                                                                        {translate.t("required_field")}
                                                                    </div>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}/>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Field
                                                    as={TextField}
                                                    name="order"
                                                    label={translate.t("order")}
                                                    variant={"outlined"}
                                                    fullWidth
                                                    autoFocus={false}
                                                    required={true}
                                                    values={values}
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    onKeyDown={(event: any) => preventTypeNumber(event)}
                                                    placeholder=""
                                                    error={touched.order && Boolean(errors.order)}
                                                    InputProps={{
                                                        min: 0,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {touched.order && Boolean(errors.order) && (
                                                                    <div className={"required-field"}>
                                                                        {translate.t("required_field")}
                                                                    </div>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Field
                                                    as={TextField}
                                                    name="start_time"
                                                    label={translate.t("start_time")}
                                                    variant={"outlined"}
                                                    fullWidth
                                                    autoFocus={false}
                                                    required={true}
                                                    error={touched.start_time && Boolean(errors.start_time)}
                                                    values={values}
                                                    type="time"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        step: 1,
                                                        min: "00:00:00",
                                                        max: "23:59:59",
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {touched.start_time && Boolean(errors.start_time) && (
                                                                    <div className={"required-field"}>
                                                                        {translate.t("required_field")}
                                                                    </div>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Field
                                                    as={TextField}
                                                    name="end_time"
                                                    label={translate.t("end_time")}
                                                    variant={"outlined"}
                                                    fullWidth
                                                    autoFocus={false}
                                                    required={true}
                                                    error={touched.end_time && Boolean(errors.end_time)}
                                                    values={values}
                                                    type="time"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    InputProps={{
                                                        step: 1,
                                                        min: "00:00:00",
                                                        max: "23:59:59",
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {touched.end_time && Boolean(errors.end_time) && (
                                                                    <div className={"required-field"}>
                                                                        {translate.t("required_field")}
                                                                    </div>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item container direction={"row"}>
                                            <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        component={NavLink}
                                                        to={url}
                                                        variant="outlined">
                                                        {translate.t("cancel")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="success"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save_form"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save_form"
                                                        disabled={!isValid || isValidating || !values.name}>
                                                        {translate.t("save_form")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save"
                                                        disabled={!isValid || isValidating || !values.name}>
                                                        {translate.t("save_plus")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
                {
                    (params["action"] != "create") &&
                    <TabsScheduledStop
                        shift={object}
                    />
                }
            </div>
        </section>
    );
};

export default ShiftItemComponent;
