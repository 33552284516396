import BaseModel from "../default/base-model";
import {Demand} from "./demand";

export class ProductionOrderDemand extends BaseModel {
    production_order: string;
    demand: string | Demand;

    constructor() {
        super();
        this.production_order = "";
        this.demand = "";
    }
}
