import BaseModel from "../default/base-model";

export class OeeMonthGoal extends BaseModel{
    month: number;
    year: number;
    goal: number;

    constructor() {
        super();
        this.month = 0;
        this.year = 0;
        this.goal = 0;
    }
}
