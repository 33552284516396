import * as React from "react";
import {useEffect} from "react";
import {CSSObject, styled, Theme} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import logo from "../../../assets/images/logo.svg";
import logoFoxconn from "../../../assets/images/logo-foxconn.svg";
import logoFPFTech from "../../../assets/images/logo-fpf.svg";
import {
    Badge,
    Box,
    Button,
    Container,
    Grid,
    ListItem,
    ListSubheader,
    Menu,
    MenuItem, Paper,
    Tooltip,
    Typography
} from "@mui/material";
import {BaseService} from "../../services/base-service";
import * as Icons from "@mui/icons-material";
import {AccountCircle, ExitToApp, InfoRounded, MenuOpen, Notifications, PersonOutline} from "@mui/icons-material";
import {toast} from "react-toastify";
import {Link, NavLink} from "react-router-dom";
import {translate} from "../../translate/translate";
import bgHeader from "../../../assets/images/bg-header.svg";
import {useAuth} from "../../contexts/auth-context";
import {URLS} from "../../services/app-urls";
import {ModuleMenu} from "../../models/default/module-menu";
import {Module} from "../../models/default/module";
import ModuleDialogComponent from "../module-dialog/module-dialog.component";
import CryptoJS from "crypto-js";
import ProductionPlanningComponent from "../../page/basic/production-planning/production-planning.component";
import {WebSocketService} from "../../services/websocket-service";
import {AlertModel} from "../../models/default/alert";
import Helpers from "../../../helpers";
import {useNavigate} from "react-router-dom";

interface LayoutProps {
    showCommonElements?: boolean;
    children?: React.ReactNode;
}

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== "open"})(
    ({theme, open}) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
            ...openedMixin(theme),
            "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            "& .MuiDrawer-paper": closedMixin(theme),
        }),
    }),
);

const LayoutComponent: React.FC<LayoutProps> = ({children, showCommonElements = true}) => {
    const {user, logout} = useAuth();
    const [open, setOpen] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openUserPanel = Boolean(anchorEl);

    const serviceModuleMenu = new BaseService<any>(URLS.MODULE_MENU);
    const serviceModule = new BaseService<Module>(URLS.MODULE);
    const [moduleMenu, setModuleMenu] = React.useState<ModuleMenu[]>([]);
    const [options, setOptions] = React.useState<{ value: string | number; label: string }[]>([]);
    const [openProceed, setOpenProceed] = React.useState(false);
    const encryptionKey = "MQypU-xxNvC7tQm1P6hhe07A95ULiz7pUOy69oNRK4I";
    const isProductionPlanningComponent = React.isValidElement(children) && children.type === ProductionPlanningComponent;

    const webSocketService = new WebSocketService("alerts");
    const serviceAlert = new BaseService<AlertModel>(URLS.ALERT);
    const [alerts, setAlerts] = React.useState<AlertModel[]>([]);
    const [countAlerts, setCountAlerts] = React.useState<number | any>(null);
    const [anchorElAlert, setAnchorElAlert] = React.useState<null | HTMLElement>(null);
    const openAlertMenu = Boolean(anchorElAlert);
    const navigate = useNavigate();

    const encryptValue = (value: any) => {
        const jsonString = JSON.stringify(value);
        return CryptoJS.AES.encrypt(jsonString, encryptionKey).toString();
    };

    const decryptValue = (value: any) => {
        if (!value) {
            return "";
        }
        const decryptedString = CryptoJS.AES.decrypt(value, encryptionKey).toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedString);
    };

    const initialSelectedOption = decryptValue(localStorage.getItem("module"));
    const [selectedOption, setSelectedOption] = React.useState<string>(
        initialSelectedOption?.module?.module?.label ? initialSelectedOption?.module?.module?.label : "registrations"
    );
    const [icon, setIcon] = React.useState<string>(
        initialSelectedOption?.module?.module?.icon ? initialSelectedOption?.module?.module?.icon : "PostAdd"
    );

    const handleSelectedModule = (module: any) => {
        setSelectedOption(module?.module?.label);
        setIcon(module?.module?.icon || "PostAdd");
        localStorage.setItem("module", encryptValue({module}));
    };

    const clearModuleMenu = () => {
        setModuleMenu([]);
    };

    const handleOpenDialog = () => {
        setOpenProceed(true);
    };

    const handleCloseDialog = () => {
        setOpenProceed(false);
    };

    const handleDrawer = () => {
        setOpen(!open);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClickAlert = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElAlert(event.currentTarget);
    };

    const handleCleanAlert = (alert?: AlertModel) => {
        if (alert) {
            serviceAlert.clearParameters();
            serviceAlert.update({"active": false}, alert.id).then(() => {
                getAlerts();
                setAnchorElAlert(null);
                navigate(alert.action);
            });
        } else {
            setAnchorElAlert(null);
        }

    };

    const cleanAlerts = () => {
        setCountAlerts(null);
    };

    const handleCloseAlertsMenu = () => {
        setAnchorElAlert(null);
        cleanAlerts();
    };

    useEffect(() => {
        if (selectedOption) {
            getMenu();
        }
    }, [selectedOption]);

    useEffect(() => {
        getModule();
        getMenu();
        getAlerts();
        createWebSocketConnection();
    }, []);

    const getModule = () => {
        serviceModule.clearParameters();
        serviceModule.addParameter("user", user.id);
        serviceModule.addParameter("granted", "true");
        serviceModule.addParameter("is_active", "true");
        serviceModule.getAllFromListRoute("with_granted")
            .then((response: any) => {
                const moduleOptions = response.data
                    .filter((module: Module) => module)
                    .map((module: Module) => ({value: module.id, label: module.description, icon: module.icon}));
                setOptions(moduleOptions);
            })
            .catch((error: any) => {
                if (error.response) {
                    toast.error(translate.t("session_expired"));
                } else {
                    toast.error(translate.t("connection_error"));
                }
            });
    };

    const getMenu = () => {
        serviceModuleMenu.clearParameters();
        serviceModuleMenu.addParameter("user", user.id);
        serviceModuleMenu.addParameter("module", selectedOption);
        serviceModuleMenu.getAllFromListRoute("find_menu")
            .then((response: any) => {
                setModuleMenu(response.data.results);
            })
            .catch((error: any) => {
                if (error.response.status === 403) {
                    logout();
                } else {
                    if (error.response) {
                        toast.error(translate.t(error.response.data.message));
                    } else {
                        toast.error(translate.t("connection_error"));
                    }
                }
            });
    };

    const createWebSocketConnection = () => {
        webSocketService.createWebSocketConnection();
        webSocketService.onMessage(() => {
            getAlerts();
        });
    };

    const getAlerts = () => {
        serviceAlert.clearParameters();
        serviceAlert.addParameter("active", true);
        serviceAlert.getAll()
            .then((response) => {
                const countAlerts = response.data.length;
                setCountAlerts(countAlerts);
                setAlerts(response.data);
            });

    };

    return (
        <>
            {showCommonElements && (
                <Box sx={{display: "flex"}}>
                    <AppBar sx={{display: "flex", boxShadow: "none"}} open={open}>
                        <Toolbar
                            style={{
                                backgroundImage: `url(${bgHeader})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                backgroundPosition: "center center",
                            }}>
                            <IconButton
                                id="btn_menu"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawer}
                                edge="start"
                                sx={{marginRight: 2}}>
                                <MenuOpen sx={{display: open ? "block" : "none", fontSize: 34}}/>
                                <MenuIcon sx={{display: open ? "none" : "block", fontSize: 34}}/>
                            </IconButton>
                            <Box sx={{display: "flex"}}>
                                <Link to="/home" className="logo">
                                    <Box
                                        component="img"
                                        sx={{
                                            height: 32,
                                        }}
                                        alt="Logo P-Plan"
                                        src={logo}/>
                                </Link>
                            </Box>

                            <Button
                                id="alert-button"
                                color="inherit"
                                sx={{marginRight: 2}}
                                onClick={handleClickAlert}>
                                <Badge color="secondary" badgeContent={countAlerts}><Notifications/></Badge>
                            </Button>
                            <Menu
                                id="alert-menu"
                                aria-labelledby="alert-button"
                                anchorEl={anchorElAlert}
                                open={openAlertMenu}
                                onClose={handleCloseAlertsMenu}
                                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                                transformOrigin={{vertical: "top", horizontal: "right"}}
                                PaperProps={{
                                    style: {
                                        maxHeight: "540px",
                                        width: "320px",
                                    },
                                }}>
                                <List subheader={<li/>}>
                                    <li>
                                        <ul style={{padding: "0", margin: "0"}}>
                                            <ListSubheader className="list-subheader">{translate.t("notifications")}</ListSubheader>
                                            {alerts.length > 0 && alerts.map((alert, index) => {
                                                return (
                                                    <div key={index}>
                                                        <ListItem style={{flexDirection: "column", alignItems: "flex-start"}}>
                                                            <Typography fontWeight="600" variant="body2" gutterBottom>{Helpers.formatDate(alert.created_at)}</Typography>
                                                            <Typography variant="body2">{alert.name}</Typography>
                                                            <Button startIcon={<InfoRounded sx={{fontSize: "12px !important"}}/>} variant="outlined" onClick={() => {
                                                                handleCleanAlert(alert);
                                                            }}>
                                                                <Typography variant="body2">{translate.t("verify_planning")}</Typography>
                                                            </Button>
                                                        </ListItem>
                                                        <Divider component="li"/>
                                                    </div>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                </List>
                            </Menu>

                            <Button
                                id="btn_module"
                                variant="contained"
                                onClick={() => handleOpenDialog()}
                                sx={{display: "flex", alignItems: "center", marginRight: "15px"}}>
                                {React.createElement((Icons as any)[icon], {
                                    sx: {
                                        fontSize: "1.5rem",
                                        marginRight: "0.5rem"
                                    }
                                })}
                                <span style={{fontSize: "1rem"}}>{translate.t(selectedOption)}</span>
                            </Button>
                            <Button
                                id="btn_profile"
                                variant="contained"
                                onClick={handleClick}
                                startIcon={<PersonOutline sx={{fontSize: "24px !important"}}/>}>
                                <span style={{
                                    fontSize: "1rem",
                                    textTransform: "uppercase"
                                }}>{String(user?.username)}</span>
                            </Button>
                            <Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                open={openUserPanel}
                                onClose={handleClose}
                                anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                                transformOrigin={{vertical: "top", horizontal: "right"}}>
                                <Box padding={2} className="box-user">
                                    <Grid spacing={2} container direction={"row"}>
                                        <Grid item>
                                            <AccountCircle sx={{fontSize: 40, marginTop: 1}}/>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant={"subtitle1"}>
                                                <strong style={{fontSize: 20}}>{String(user?.username)}</strong>
                                            </Typography>
                                            <Typography variant={"subtitle2"}>
                                                <span style={{fontSize: 14}}>{String(user?.name)}</span>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Divider></Divider>
                                <MenuItem style={{marginTop: "8px"}} onClick={() => logout()}>
                                    <ListItemIcon id="btn_logout">
                                        <ExitToApp/>
                                    </ListItemIcon>
                                    <ListItemText>{translate.t("logout")}</ListItemText>
                                </MenuItem>
                            </Menu>
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent" open={open} className="sidenav"
                        PaperProps={{
                            style: {
                                width: open ? "140px" : "65px",
                                marginTop: "64px",
                                height: "calc(100vh - 64px)"
                            }
                        }}>
                        <Divider/>
                        <List component={"nav"}>
                            {moduleMenu.map((moduleMenu: any, index: number) => (
                                <Grid key={moduleMenu.label} sx={{textAlign: "center"}}>
                                    {(index > 0 && open) && <Divider sx={{marginTop: "15px", marginBottom: "15px"}}/>}
                                    <ListSubheader
                                        component="div"
                                        sx={{
                                            display: open ? "block" : "none",
                                            position: "initial",
                                            color: "#45485F",
                                            opacity: "65%",
                                            fontSize: "13px",
                                            lineHeight: "38px",
                                        }}>
                                        <strong>{translate.t(moduleMenu.label).toUpperCase()}</strong>
                                    </ListSubheader>
                                    {moduleMenu.items.map((menu: any) => (
                                        <Tooltip key={menu.label} title={translate.t(menu.label)} placement={"right"}>
                                            <ListItem key={menu.label}>
                                                <ListItemButton
                                                    id={`btn_${menu.label}`}
                                                    sx={{
                                                        minHeight: open ? 36 : 0,
                                                        justifyContent: open ? "initial" : "center",
                                                    }}
                                                    component={NavLink}
                                                    to={menu.route}>
                                                    <ListItemIcon
                                                        sx={{
                                                            minWidth: 0,
                                                            mr: open ? 2 : "auto",
                                                            justifyContent: "center"
                                                        }}>
                                                        {React.createElement((Icons as any)[menu.icon])}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        key={menu.label}
                                                        sx={{display: open ? "initial" : "none"}}
                                                        primary={translate.t(menu.label).length > 50 ?
                                                            `${translate.t(menu.label).substring(0, 50)}...` :
                                                            translate.t(menu.label)}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        </Tooltip>
                                    ))}
                                </Grid>
                            ))}
                        </List>
                    </Drawer>
                    <Box component="main" sx={{
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "calc(100vh - 64px)",
                        marginTop: "64px"
                    }}>
                        <Toolbar className="subheader"/>
                        <Container
                            maxWidth={false}
                            disableGutters
                            sx={{
                                flexGrow: 1,
                                p: 3,
                                padding: isProductionPlanningComponent ? 0 : 3,
                            }}
                            className="main-container"
                        >
                            <main style={{height: "100%"}}>{children}</main>
                        </Container>
                        <Paper sx={{borderRadius: "0"}}>
                            <Grid padding="10px 16px" container direction="row" justifyContent="space-between" alignItems="center">
                                <Grid item display="flex" flexDirection="row" alignItems="center">
                                    <Box
                                        marginRight={1}
                                        component="img"
                                        sx={{
                                            width: "auto",
                                            height: 12,
                                        }}
                                        alt="Logo Foxconn"
                                        src={logoFoxconn}/>
                                    <Typography fontSize="13px">
                                        {translate.t("all_rights_reserved")}
                                    </Typography>
                                </Grid>
                                <Grid item display="flex" flexDirection="row" alignItems="center">
                                    <Typography fontSize="13px">
                                        {translate.t("version")}: -
                                    </Typography>
                                </Grid>
                                <Grid item display="flex" flexDirection="row" alignItems="center">
                                    <Typography fontSize="13px">
                                        {translate.t("developed_by")}
                                    </Typography>
                                    <Box
                                        marginLeft={1}
                                        component="img"
                                        sx={{
                                            width: "auto",
                                            height: 24,
                                        }}
                                        alt="Logo FPFTech"
                                        src={logoFPFTech}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Box>
            )}
            <ModuleDialogComponent
                openProceed={openProceed}
                handleCloseDialogProceed={handleCloseDialog}
                modules={options}
                handleSelectedModule={handleSelectedModule}
                handleClearModuleMenu={clearModuleMenu}
                searchMenus={getMenu}/>
        </>
    );

};

export default LayoutComponent;
