import * as React from "react";
import {useEffect} from "react";
import {Grid, Paper} from "@mui/material";
import * as echarts from "echarts/core";
import {DataZoomComponent, GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent} from "echarts/components";
import {BarChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";

import EChartsReact, {useChart,} from "echarts-for-react-fc";
import {translate} from "../../../../translate/translate";

echarts.use([GridComponent, BarChart, CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, DataZoomComponent]);

const RealizedAndPlannedChartComponent = (props: any) => {

    const {
        reportData,
        chartTitle
    } = props;

    // Hooks
    const {chartRef, setChartOption, handleListenChartReady} = useChart();

    const emphasisStyle = {
        itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0,0,0)"
        }
    };

    const labelOption = {show: true, rotate: 45, formatter: "{c}", distance: 10, fontSize: 16, position: "top", align: "center", verticalAlign: "middle"};
    const percentLabelOption = {show: true, rotate: 45, formatter: "{c}", distance: 10, fontSize: 12, position: "top", align: "center", verticalAlign: "middle"};

    // Use effects
    useEffect(() => {
        handleClickSetLineOption();
    }, []);


    const handleClickSetLineOption = () => {

        const max_y_axis_percent_value = Math.max(...reportData.percent);

        const chartOption: any = {
            title: {show: true, text: chartTitle, textStyle: {fontSize: 20}, top: "3%", left: "2%"},
            dataZoom: [{type: "slider", show: true, realtime: true, start: 0, end: 100}],
            legend: {data: ["Planejado", "Realizado"], textStyle: {fontSize: 16}, top: "10%"},
            grid: {left: "3%", right: "4%", top: "25%", bottom: "12%", containLabel: true},
            toolbox: {show: true, itemSize: 16, top: "3%", right: "1%", feature: {saveAsImage: {name: translate.t("realized_vs_planned"), title: translate.t("save")}}},
            emphasis: emphasisStyle,
            barCategoryGap: "60%",
            barGap: "50%",
            tooltip: {
                trigger: "axis", axisPointer: {type: "shadow"},
                formatter: function (params: any) {
                    return `
                            <ul>
                                <li><strong>${params[0].seriesName}</strong>: ${params[0].value}</li>
                                <li><strong>${params[1].seriesName}</strong>: ${params[1].value}</li>
                                <li><strong>Porcentagem</strong>: ${(100 * params[1].value / params[0].value).toFixed(1)}%</li>
                            </ul>
                            `;
                }
            },
            xAxis: [
                {
                    type: "category", data: reportData.daily_planning_date, splitArea: {show: false},
                    axisTick: {show: false, alignWithLabel: true},
                    axisLabel: {interval: 0, show: true, fontSize: 16, rotate: 45},
                }
            ],
            yAxis: [
                {type: "value", name: "Quantidade", axisLine: {onZero: false}, rotate: 45, fontSize: 16,},
                {type: "value", name: "Porcentagem", axisLine: {onZero: false}, rotate: 45, fontSize: 16, max: max_y_axis_percent_value + 20, interval: 20, axisLabel: {formatter: "{value} %"}},
            ],
            series: [
                {id: "planned_results", name: "Planejado", type: "bar", label: labelOption, data: reportData.total_planned},
                {id: "realized_results", name: "Realizado", type: "bar", label: labelOption, data: reportData.total_realized},
                {id: "percent", name: "Porcentagem", type: "line", label: percentLabelOption, data: reportData.percent, yAxisIndex: 1,}
            ]
        };
        setChartOption(chartOption, {notMerge: true}
        );
    };


    // Render return
    return (
        <section>
            <Paper variant="outlined">
                <Grid item>
                    <div>
                        <EChartsReact
                            style={{
                                height: 500,
                            }}
                            ref={chartRef}
                            echarts={echarts}
                            onChartReady={handleListenChartReady}
                        />
                    </div>
                </Grid>
            </Paper>
        </section>
    );
};

export default RealizedAndPlannedChartComponent;
