import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import {Box, Checkbox, Fab, FormControl, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, TextField, Typography} from "@mui/material";
import {Search} from "@mui/icons-material";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import * as echarts from "echarts/core";
import {DataZoomComponent, GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent} from "echarts/components";
import {BarChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";
import EChartsReact, {EChartsReactProps, useChart,} from "echarts-for-react-fc";
import {toast} from "react-toastify";
import {BaseService} from "../../../services/base-service";
import {Downtime} from "../../../models/basic/downtime";
import {URLS} from "../../../services/app-urls";
import {translate} from "../../../translate/translate";
import {ErrorComponent} from "../../../components/error/error.component";
import {DowntimeReason} from "../../../models/basic/downtime-reason";
import Select from "@mui/material/Select";
import {PaginatedResult} from "../../../models/default/paginated-result";
import TablePaginationComponent from "../../../components/table/table-pagination.component";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

echarts.use([GridComponent, BarChart, CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, DataZoomComponent]);

const DowntimeRankingReportComponent = () => {
    // Services
    const service = new BaseService<Downtime>(URLS.DOWNTIME);
    const downtime_reason_service = new BaseService<DowntimeReason>(URLS.DOWNTIME_REASON);

    // Hooks
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [pagination, setPagination] = React.useState<PaginatedResult<Downtime>>();
    const [dataSource, setDataSource] = React.useState<Downtime[]>([]);
    const [start_date, setStartDate] = useState(dayjs());
    const [end_date, setEndDate] = useState(dayjs());
    const [report_type, setReportType] = useState<string>("Q");
    const [showChart, setShowChart] = useState<boolean>(false);
    const {chartRef, setChartOption, handleListenChartReady} = useChart();
    const [showTable, setShowTable] = useState<boolean>(false);
    const [reasonDescriptionTable, setReasonDescriptionTable] = useState<string | null>(null);
    const [reasons, setReasons] = useState<DowntimeReason[]>([]);
    const [selectedDowntimeReasons, setSelectedDowntimeReasons] = useState<any[]>([]);

    const emphasisStyle = {
        itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0,0,0)"
        }
    };

    // Form
    const schema = Yup.object().shape({
        downtime_reason: Yup.number(),
        start_date: Yup.string().required(),
        final_date: Yup.string().required(),
        report_type: Yup.string().required(),
    });

    // Use effects
    useEffect(() => {
        setChartOption({});
    });

    // Handle and get functions

    const handleDownTimeReasonChange = (reasonId: any) => {
        setShowChart(false);
        setShowTable(false);
        setSelectedDowntimeReasons(reasonId);
    };

    const handleInitialDateChange = (date: any) => {
        setShowChart(false);
        setShowTable(false);
        setStartDate(date);
    };

    const handleFinalDateChange = (date: any) => {
        setShowChart(false);
        setShowTable(false);
        setEndDate(date);
    };

    const handleReportTypeChange = (value: any) => {
        setShowChart(false);
        setShowTable(false);
        setReportType(value);
    };

    const handleClickSetLineOption = (data: any) => {

        if (data) {
            const max_y_axis_value = Math.max(...data.quantities);
            const option: any = {
                title: {show: true, text: translate.t("downtime_ranking"), textStyle: {fontSize: 20}, top: "3%", left: "2%"},
                dataZoom: [{
                    type: "slider",
                    show: true,
                    realtime: true,
                    start: 0,
                    end: 100,
                }],
                grid: {
                    right: "5%",
                    left: "5%",
                    top: "20%",
                    containLabel: true
                },
                toolbox: {
                    show: true,
                    top: "3%",
                    right: "1%",
                    itemSize: 22,
                    feature: {
                        saveAsImage: {
                            name: translate.t("downtime_ranking"),
                            title: "save"
                        }
                    }
                },
                emphasis: emphasisStyle,
                barCategoryGap: "60%",
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                    valueFormatter: (value: any) => {
                        return report_type === "Q" ? value : `${value} min`;
                    },
                },
                xAxis: {
                    data: data.reasons,
                    type: "category",
                    splitArea: {show: false},
                    axisTick: {
                        show: false,
                        alignWithLabel: true
                    },
                    axisLabel: {
                        interval: 0,
                        show: true,
                        rotate: 45,
                        fontSize: 16
                    },
                },
                yAxis: {
                    type: "value",
                    show: true,
                    max: max_y_axis_value,
                    axisLine: { onZero: false },
                    axisLabel: {
                        formatter: function (d: any) {
                            return d;
                        }
                    }
                },
                series: [
                    {
                        id: "results",
                        data: data.quantities,
                        type: "bar",
                        label: {
                            show: true,
                            position: "top",
                            align: "center",
                            verticalAlign: "middle",
                            distance: 10,
                            fontSize: 16,
                            formatter: report_type === "Q" ? "{c}" : "{c} min"
                        },
                    }
                ]
            };
            setShowChart(true);
            setChartOption(option, {notMerge: true}
            );
        } else {
            toast.warning(translate.t("empty_listing"));
        }
    };

    useEffect(() => {
        setDataSource(pagination?.results ?? []);
    }, [pagination]);

    useEffect(() => {
        getDowntimes(reasonDescriptionTable);
    }, [page, rowsPerPage]);

    // Use effects
    useEffect(() => {
        getReasons();
    }, []);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getDowntimes = (reason_description: any) => {
        service.clearParameters();
        service.addParameter("start_date", start_date.format("YYYY-MM-DD"));
        service.addParameter("end_date", end_date.format("YYYY-MM-DD"));
        service.addParameter("reason_description_eq", reason_description);
        service.addParameter("expand", "production_line,reason");
        service.addParameter("limit", rowsPerPage);
        service.addParameter("offset", (page * rowsPerPage));
        service.getAllPaginate()
            .then(response => {
                setPagination(response.data);
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    const getReasons = () => {
        downtime_reason_service.clearParameters();
        downtime_reason_service.addParameter("ordering", "description");
        downtime_reason_service.addParameter("active", true);
        downtime_reason_service.getAll()
            .then((response: any) => {
                setReasons(response.data);
            });
    };

    const chartCallback = useCallback(function (event?: any) {
        setRowsPerPage(5);
        setPage(0);
        setShowTable(true);
        if (event) {
            getDowntimes(event.name);
        } else if (reasonDescriptionTable) {
            getDowntimes(reasonDescriptionTable);
        }
    }, [reasonDescriptionTable, setRowsPerPage, setPage]);

    const chartEvent = useMemo<EChartsReactProps["onEvents"]>(() => {
        return {
            click: [
                {
                    query: {seriesId: "results"},
                    handler: (event: any) => {
                        setReasonDescriptionTable(event.name);
                        chartCallback(event);
                    }
                },
            ]
        };
    }, [chartCallback]);

    const getReportData = () => {
        service.clearParameters();
        if (selectedDowntimeReasons.length > 0) {
            service.addParameter("reasons_id", selectedDowntimeReasons);
        }
        service.addParameter("start_date", dayjs(start_date).format("YYYY-MM-DD"));
        service.addParameter("end_date", dayjs(end_date).format("YYYY-MM-DD"));
        service.addParameter("report_type", report_type);
        service.getFromListRoute("report_chart_downtime_ranking")
            .then(response => {
                handleClickSetLineOption(response.data);
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    const renderReasons = (selected: any) => {
        const selects: any[] = [];
        selected.map((reasonId: any) => {
            const description: any = reasons.find(reason => reasonId === reason.id)?.description;
            selects.push(description);
        });

        return selects.join(", ");
    };

    // Render return
    return (
        <section>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("downtime_ranking")}
                </Typography>
            </div>

            <Grid item xs>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            initialValues={{downtimeReason: "", start_date: "", end_date: "", report_type: ""}}
                            validationSchema={schema}
                            validateOnChange={true}
                            validateOnMount={true}
                            enableReinitialize
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    getReportData();
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                ({values, touched}) => {
                                    return (
                                        <Form autoComplete="off">
                                            <Grid alignItems="center" justifyContent={"flex-start"} container spacing={2} direction={"row"}>
                                                <Grid item xs>
                                                    <DatePicker
                                                        format="DD/MM/YYYY"
                                                        maxDate={end_date}
                                                        onChange={handleInitialDateChange}
                                                        slotProps={{
                                                            textField: {
                                                                name: "start_date",
                                                                label: translate.t("start_date"),
                                                                fullWidth: true,
                                                                value: start_date,
                                                                required: true,
                                                                error: !start_date,
                                                                InputLabelProps: {
                                                                    shrink: true
                                                                },
                                                                InputProps: {
                                                                    startAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {!start_date ? (
                                                                                <div className={"required-field"}>
                                                                                    {translate.t("required_field")}
                                                                                </div>
                                                                            ) : null}
                                                                            {start_date > end_date ? (
                                                                                <div className={"error-field"}>
                                                                                    {translate.t("must_be_less_equal_than_final_date")}
                                                                                </div>
                                                                            ) : null}
                                                                        </InputAdornment>
                                                                    ),
                                                                }
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <DatePicker
                                                        format="DD/MM/YYYY"
                                                        onChange={handleFinalDateChange}
                                                        minDate={start_date}
                                                        slotProps={{
                                                            textField: {
                                                                required: true,
                                                                name: "end_date",
                                                                label: translate.t("end_date"),
                                                                fullWidth: true,
                                                                value: end_date,
                                                                error: !end_date || (end_date < start_date),
                                                                InputLabelProps: {
                                                                    shrink: true
                                                                },
                                                                InputProps: {
                                                                    startAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {!end_date ? (
                                                                                <div className={"required-field"}>
                                                                                    {translate.t("required_field")}
                                                                                </div>
                                                                            ) : null}
                                                                            {end_date < start_date ? (
                                                                                <div className={"error-field"}>
                                                                                    {translate.t("must_be_bigger_equal_than_start_date")}
                                                                                </div>
                                                                            ) : null}
                                                                        </InputAdornment>
                                                                    ),
                                                                }
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs>
                                                    <FormControl fullWidth>
                                                        <InputLabel className={"formControl"} htmlFor="report_type">
                                                            {translate.t("report_type")} *
                                                        </InputLabel>
                                                        <Field
                                                            variant={"outlined"}
                                                            defaultValue="Q"
                                                            label={translate.t("report_type")}
                                                            name="report_type"
                                                            component={Select}
                                                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => handleReportTypeChange(event.target.value as string)}
                                                        >
                                                            <MenuItem value="T">{translate.t("time")}</MenuItem>
                                                            <MenuItem value="Q">{translate.t("quantity")}</MenuItem>
                                                        </Field>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <FormControl fullWidth>
                                                        <InputLabel>{translate.t("downtime_reason")}</InputLabel>
                                                        <Select
                                                            name="downtimeReasons"
                                                            variant={"outlined"}
                                                            fullWidth
                                                            multiple
                                                            value={selectedDowntimeReasons}
                                                            onChange={(event: any) => handleDownTimeReasonChange(event.target.value)}
                                                            input={<OutlinedInput label={translate.t("downtime_reason")}/>}
                                                            renderValue={(selected) => renderReasons(selected)}
                                                            autoFocus={false}
                                                            required={false}>
                                                            {reasons.map((reason: any) => (
                                                                <MenuItem key={reason.id} value={reason.id}>
                                                                    <Checkbox checked={selectedDowntimeReasons.includes(reason.id)}/>
                                                                    <ListItemText primary={reason.description}/>
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item>
                                                    <Fab
                                                        size="small"
                                                        color="secondary"
                                                        disabled={!start_date || !end_date || start_date > end_date || !report_type}
                                                        onClick={getReportData}>
                                                        <Search/>
                                                    </Fab>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    );
                                }}
                        </Formik>
                    </Box>
                </Paper>

                {showChart && (
                    <Paper sx={{marginTop: "16px"}}>
                        <Grid item>
                            <div id={"chart"}>
                                <EChartsReact
                                    autoResize={true}
                                    style={{width: "100%", height: 500,}}
                                    ref={chartRef}
                                    echarts={echarts}
                                    onEvents={chartEvent}
                                    onChartReady={handleListenChartReady}
                                />
                            </div>
                        </Grid>
                    </Paper>
                )}

                {showTable && (
                    <Paper sx={{marginTop: "16px"}}>
                        <TablePaginationComponent
                            state={dataSource}
                            field={"type"}
                            service={service}
                            displayedColumnsShow={["#", "production_line", "reason", "type", "start_date", "end_date"]}
                            displayedColumns={["id", "production_line.name", "reason.description", "type", "start_date", "end_date"]}
                            dateColumns={["start_date", "end_date"]}
                            count={pagination?.count}
                            next={pagination?.next}
                            previous={pagination?.previous}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            onPageChange={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            columnStyles={{1: "10%", 2: "15%", 3: "15%", 4: "15%", 5: "10%", 6: "10%"}}
                        />
                    </Paper>
                )}
            </Grid>
        </section>
    );
};

export default DowntimeRankingReportComponent;
