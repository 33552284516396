import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import {translate} from "../../../../translate/translate";
import {Grid, Typography} from "@mui/material";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {Close} from "@mui/icons-material";
import AutocompletePaginated from "../../../../components/autocomplete/autocompletePaginated";
import {ReasonReplanning} from "../../../../models/basic/reason-replanning";

const DialogSetFinalVersion = (props: any) => {
    // Services
    const serviceReasonReplanning = new BaseService<ReasonReplanning>(URLS.REASON_REPLANNING);
    const {
        open,
        onClose
    } = props;
    const [reasonReplanning, setReasonReplanning] = useState<string>("");

    const handleClose = (result: boolean) => {
        if (result) {
            onClose(reasonReplanning);
            setReasonReplanning("");
        } else {
            setReasonReplanning("");
            onClose();
        }
    };

    // Handle and get functions

    const handleReasonReplanningChange = (event: any) => {
        if (event) {
            setReasonReplanning(event.id);
        } else {
            setReasonReplanning("");
        }
    };

    return (
        <Dialog open={open} onClose={() => handleClose(false)} fullWidth>
            <Grid padding={2} container justifyContent="space-between" sx={{backgroundColor: "primary.main"}}>
                <Grid item>
                    <Typography sx={{color: "#ffffff", fontWeight: "bold", fontSize: "16px"}}>
                        {translate.t("change_final_version")}
                    </Typography>
                </Grid>
                <Grid item sx={{cursor: "pointer", color: "#ffffff"}}>
                    <Close onClick={() => handleClose(false)}/>
                </Grid>
            </Grid>
            <DialogContent>

                <Grid display="flex" flexDirection="row" alignItems="center" container spacing={1} sx={{height: "150px", marginTop: "20px"}}>
                    <Grid item xs>
                        <AutocompletePaginated
                            display="name"
                            searchField="name"
                            label="reason"
                            service={serviceReasonReplanning}
                            filters={[{"active": true}]}
                            onSelectElement={handleReasonReplanningChange}
                            required={false}
                        />
                    </Grid>
                    <Grid item container direction={"row"}>
                        <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                            <Grid item>
                                <Button
                                    className="button-options"
                                    color="secondary"
                                    onClick={() => handleClose(false)}
                                    variant="outlined">
                                    {translate.t("cancel")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    className="button-options"
                                    color="success"
                                    onClick={() => handleClose(true)}
                                    disabled={!reasonReplanning}
                                    variant="contained">
                                    {translate.t("save")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default DialogSetFinalVersion;
