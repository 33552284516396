import "./dashboard-planning.component.css";
import React, {useEffect, useState} from "react";
import {Box, Grid, Typography} from "@mui/material";
import {translate} from "../../translate/translate";
import {AccountCircle, DisplaySettings, Person} from "@mui/icons-material";
import {Tooltip} from "react-tooltip";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import {PlanningCalendar, PlanningMonthKey} from "../../dto/planning-calendar";
import {Month} from "../../dto/months";
import {WebSocketService} from "../../services/websocket-service";
import {BaseService} from "../../services/base-service";
import {Version} from "../../models/basic/version";
import {URLS} from "../../services/app-urls";
import {ErrorComponent} from "../../components/error/error.component";

dayjs.locale("pt-br");

const DashboardPlanningComponent = () => {
    const serviceVersion = new BaseService<Version>(URLS.VERSION);
    const [planning, setPlanning] = useState<PlanningCalendar>();
    const [monthKeys, setMonthKeys] = useState<PlanningMonthKey[]>([]);
    const [start_date] = useState(dayjs());
    const month_calendar = Month.loadObject();

    const getStringMonth = (index: string) => {
        return Object.values(month_calendar)[Number(index) - 1] as string;
    };

    useEffect(() => {
        updatePlanning();
    }, []);

    useEffect(() => {
        getPlanning();
    }, []);

    const processResponse = (data: any) => {
        const keys = [];
        keys.push({
            "year": "0",
            "month": "0",
            "yearMonth": "Lines"
        });

        Object.keys(data.header).forEach((yearMonth: string) => {
            const yearMonthSplit = yearMonth.split("-");
            keys.push({
                "year": yearMonthSplit[0],
                "month": yearMonthSplit[1],
                "yearMonth": yearMonth
            });
        });

        setMonthKeys(keys);
        setPlanning(data);
    };

    const getPlanning = () => {
        serviceVersion.clearParameters();
        serviceVersion.getFromListRoute("get_final_version")
            .then((response: any) => {
                processResponse(response.data);
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    const updatePlanning = () => {
        const webSocketService = new WebSocketService("dashboard_planning");
        webSocketService.onMessage((response: any) => {
            let result = response.data;
            result = JSON.parse(result);

            if (typeof result === "string") {
                result = JSON.parse(result);
            }
            processResponse(result);
        });

        return () => {
            webSocketService.close();
        };
    };

    const handleScroll = (event: any) => {
        const newScrollPosition = event.target.scrollLeft;
        const boxHeader = document.getElementById("boxHeader");
        if (boxHeader) {
            boxHeader.scrollLeft = newScrollPosition;
        }
    };

    return (
        <div className="background-screen">
            <Grid container spacing={2} direction={"column"} p={10} sx={{ height: "100vh", width: "100%", alignItems: "center", justifyContent: "center"}}>
                <Box className="box">
                    <Box className="box-months" id="boxHeader" onScroll={handleScroll}>
                        {monthKeys.map((month, index) => {
                            return (
                                <Box width="auto" key={index}>
                                    <Box display="flex" justifyContent="start" alignItems="center" className="month">
                                        {month.month != "0" && (<Typography variant="body2">{translate.t(getStringMonth(month.month))}</Typography>)}
                                    </Box>
                                    <Box width="auto" display="flex" flexDirection="row" className="header-planning">
                                        {month.month === "0" ?
                                            (<>
                                                <Box className="phantom-box"></Box>
                                                <Box className="phantom-box"></Box>
                                            </>)
                                            :
                                            (<Box display="flex" flexDirection="row">
                                                {Object.keys(planning?.header).map((monthKey) => {
                                                    const monthData = planning?.header[monthKey];
                                                    return (
                                                        Object.keys(monthData).map((itemKey, index) => {
                                                            const item = monthData[itemKey];
                                                            return (
                                                                parseInt(month.month) == item.month &&
                                                                (<Box key={index} display="flex" flexDirection="column" className="header-day">
                                                                    <Box display="flex" flex="1" justifyContent="center" alignItems="center" overflow="hidden" className="week-day">
                                                                        <Typography variant="body2">
                                                                            {dayjs(start_date).add(index, "day").format("dddd")}
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>)
                                                            );
                                                        })
                                                    );
                                                })}
                                            </Box>)
                                        }
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                    <Box>
                        {planning?.body?.lines && (
                            <Box id="lines" onScroll={handleScroll} className="box">
                                {planning.body.lines.map((line: any, index: number) => {
                                    return (
                                        <Box key={index} width="fit-content" display="flex" position="relative" flexDirection="row">
                                            {line.type === "S" ?
                                                (<Box className="sticky-bg">
                                                    <Box
                                                        className="smt-line-name"
                                                        display="flex"
                                                        flexDirection="column"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <DisplaySettings></DisplaySettings>
                                                        <Typography variant="body2">{line.name}</Typography>
                                                    </Box>
                                                </Box>)
                                                :
                                                (<Box className="sticky-bg">
                                                    <Box
                                                        className="manual-line-name"
                                                        display="flex"
                                                        flexDirection="column"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <AccountCircle></AccountCircle>
                                                        <Typography variant="body2">{line.name}</Typography>
                                                    </Box>
                                                </Box>)
                                            }
                                            <Box position="relative" display="flex" flexDirection="column" className="shifts">
                                                {line.shifts.map((shift: any, index: number) => {
                                                    return (
                                                        <Box key={index} position="relative" display="flex" flexDirection="row">
                                                            {line.type === "S" ?
                                                                (<Box className="sticky-bg" left="100px">
                                                                    <Box className="smt-shift-name" display="flex" justifyContent="center" alignItems="center">
                                                                        <Typography variant="body2">{shift.name}</Typography>
                                                                    </Box>
                                                                </Box>)
                                                                :
                                                                (<Box className="sticky-bg" left="100px">
                                                                    <Box className="manual-shift-name" display="flex" justifyContent="center" alignItems="center">
                                                                        <Typography variant="body2">{shift.name}</Typography>
                                                                    </Box>
                                                                </Box>)
                                                            }
                                                            {shift.days.map((day: any, index: number) => {
                                                                return (
                                                                    <Box
                                                                        id={`${line.id}${shift.id}${day}`}
                                                                        key={index} width="auto"
                                                                        position="relative" display="flex"
                                                                        flexDirection="row"
                                                                    >
                                                                        <Box display="flex" flex="1" flexDirection="column">
                                                                            <Box display="flex" height="100%" position="relative" flexDirection="column" justifyContent="center">
                                                                                {!day?.["is_work_day"] && !day?.["event_description"] &&
                                                                                    <Box className="weekend" sx={{height: "100%"}} display="flex" alignItems="center" justifyContent="center">
                                                                                    </Box>
                                                                                }
                                                                                {!day?.["is_work_day"] && day?.["event_description"] &&
                                                                                    <Box
                                                                                        sx={{height: (day.availability && day?.["is_work_day"]) ? "50%" : "100%"}}
                                                                                        className="holiday"
                                                                                        display="flex"
                                                                                        alignItems="center"
                                                                                        justifyContent="center"
                                                                                        data-tooltip-content={day?.["event_description"]}
                                                                                        data-tooltip-id="tooltip"
                                                                                    >
                                                                                        <Tooltip id="tooltip"/>
                                                                                        <Box
                                                                                            className="ic-holiday"></Box>
                                                                                    </Box>
                                                                                }
                                                                                {day?.["partial_event"] &&
                                                                                    <Box
                                                                                        sx={{height: (day.availability && day?.["is_work_day"]) ? "50%" : "100%"}}
                                                                                        className="holiday"
                                                                                        display="flex"
                                                                                        alignItems="center"
                                                                                        justifyContent="center"
                                                                                        data-tooltip-content={day?.["partial_event"]}
                                                                                        data-tooltip-id="tooltip"
                                                                                    >
                                                                                        <Tooltip id="tooltip"/>
                                                                                        <Box
                                                                                            className="ic-holiday">
                                                                                        </Box>
                                                                                    </Box>
                                                                                }
                                                                                {(day?.["daily_plannings"].length == 0 && day.availability && day?.["is_work_day"]) &&
                                                                                    <Box
                                                                                        sx={{height: (day?.["partial_event"]) ? "50%" : "100%"}}
                                                                                        className="day"
                                                                                        display="flex"
                                                                                        alignItems="center"
                                                                                        justifyContent="center">
                                                                                        <Person className="person-add"></Person>
                                                                                    </Box>
                                                                                }
                                                                                {day?.["daily_plannings"].map((daily_planning: any, index: number) => {
                                                                                    return (
                                                                                        <div className="used-day" key={index}>
                                                                                            <Box
                                                                                                key={index}
                                                                                                className="used-day"
                                                                                                display="flex"
                                                                                                flexDirection="row"
                                                                                                alignItems="center"
                                                                                                justifyContent="center"
                                                                                                sx={{
                                                                                                    backgroundColor: daily_planning?.color,
                                                                                                    height: (day.availability &&
                                                                                                        day?.["daily_plannings"].length == 1 &&
                                                                                                        day?.["is_work_day"]) ?
                                                                                                        "50%" : "100%"
                                                                                                }}
                                                                                            >
                                                                                                {daily_planning?.markers && daily_planning?.markers.length > 0 &&
                                                                                                    <Box
                                                                                                        className="marker"
                                                                                                        data-tooltip-content=""
                                                                                                        data-tooltip-id="empty_tooltip"
                                                                                                    >!
                                                                                                        <Tooltip
                                                                                                            id="empty_tooltip"/>
                                                                                                    </Box>
                                                                                                }
                                                                                                <Typography
                                                                                                    sx={{color: daily_planning.font_color}}>
                                                                                                    {
                                                                                                        daily_planning.is_npi == true ? "NPI" + " / " +
                                                                                                            daily_planning?.["total_realized"] : daily_planning?.["total_goal"] + " / " +
                                                                                                            daily_planning?.["total_realized"]
                                                                                                    }
                                                                                                </Typography>
                                                                                            </Box>
                                                                                            {day.availability && day?.["daily_plannings"].length == 1 && day?.["is_work_day"] &&
                                                                                                <Box
                                                                                                    sx={{height: "50%"}}
                                                                                                    className="day"
                                                                                                    display="flex"
                                                                                                    alignItems="center"
                                                                                                    justifyContent="center"
                                                                                                >
                                                                                                    <Person className="person-add"></Person>
                                                                                                </Box>
                                                                                            }
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                );
                                                            })}
                                                        </Box>
                                                    );
                                                })}
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Grid>
        </div>
    );

};

export default DashboardPlanningComponent;
