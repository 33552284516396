import {Box, FormControl, InputAdornment, InputLabel, MenuItem, TextField} from "@mui/material";
import {Field} from "formik";
import {translate} from "../../../../translate/translate";
import React from "react";
import Select from "@mui/material/Select";

interface ContainerProps {
    index: any;
    values: any
    touched: any;
    errors: any;
    setFieldValue: any;
    field: any;
    resetForm: any;
    isSelect?: boolean;
    isType?: boolean;
    isRamp?: boolean;
    isManual?: boolean;
    isScheduled?: boolean;
    selectList?: any[];
    disabled?: boolean;
    required?: boolean;
}

const SimulationFieldComponent = ({
    index, values, touched, errors, setFieldValue, field, isSelect = false, isType = false, isRamp = false,
    isManual = false, isScheduled = false, selectList = [], disabled = false, required = true
}: ContainerProps) => {

    const validateError = (touched: any, errors: any, index: any, returnBoolean: boolean) => {
        const touchedRow = touched?.selectedRows?.[index]?.[field];
        const errorRow = errors?.selectedRows?.[index]?.[field];
        return returnBoolean ? !!touchedRow && !!errorRow : errorRow || null;
    };

    return (
        <Box>
            <Box justifyContent="space-between" display="flex">
                {!isSelect ? (
                    <Field
                        as={TextField}
                        name={`selectedRows[${index}][${field}]`}
                        placeholder={translate.t("input_a_value")}
                        variant="outlined"
                        required={false}
                        type="number"
                        values={values}
                        error={validateError(touched, errors, index, true)}
                        InputProps={{
                            pattern: "[1-7]",
                            endAdornment: (
                                <InputAdornment position="end">
                                    {validateError(touched, errors, index, true) && (
                                        <div className={"required-field"}>
                                            {validateError(touched, errors, index, false)}
                                        </div>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e: any) => {
                            const updatedSelectedRows = [...values.selectedRows];
                            updatedSelectedRows[index][field] = e.target.value;
                            setFieldValue(updatedSelectedRows);
                        }}
                    />
                ) : (
                    <FormControl fullWidth>
                        {isRamp && (<InputLabel required={true}>{translate.t("production_ramp")}</InputLabel>)}
                        {isType && (<InputLabel required={true}>{translate.t("line_setting")}</InputLabel>)}
                        {isManual && (<InputLabel required={required}>{translate.t("capacity")}</InputLabel>)}
                        {isScheduled && (<InputLabel required={false}>{translate.t("line_setup")}</InputLabel>)}
                        {isRamp && (
                            <Field
                                as={Select}
                                name={`selectedRows[${index}].production_ramp`}
                                variant={"outlined"}
                                fullWidth
                                label={translate.t("production_ramp")}
                                error={validateError(touched, errors, index, true)}
                                autoFocus={false}
                                required={required}
                                values={values}
                                onChange={(e: any) => {
                                    const updatedSelectedRows = [...values.selectedRows];
                                    updatedSelectedRows[index][field] = e.target.value;
                                    setFieldValue(updatedSelectedRows);
                                }}>
                                {selectList.length > 0 ? (
                                    selectList.map((pr: any) => (
                                        <MenuItem key={pr.id} value={pr.id}>{pr.name}</MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>{translate.t("empty_listing")}</MenuItem>
                                )}
                            </Field>
                        )}
                        {isType && (
                            <Field
                                as={Select}
                                name={`selectedRows[${index}].line_setting`}
                                variant={"outlined"}
                                fullWidth
                                label={translate.t("line_setting")}
                                error={validateError(touched, errors, index, true)}
                                autoFocus={false}
                                required={required}
                                values={values}
                                onChange={(e: any) => {
                                    const updatedSelectedRows = [...values.selectedRows];
                                    updatedSelectedRows[index][field] = e.target.value;
                                    setFieldValue(updatedSelectedRows);
                                }}>
                                {selectList.length > 0 ? (
                                    selectList.map((type: any) => (
                                        <MenuItem key={type.key} value={type.key}>{translate.t(type.name)}</MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>{translate.t("empty_listing")}</MenuItem>
                                )}
                            </Field>
                        )}
                        {isManual && (
                            <Field
                                as={Select}
                                name={`selectedRows[${index}].manual_default_time`}
                                variant={"outlined"}
                                fullWidth
                                disabled={disabled}
                                label={translate.t("capacity")}
                                error={validateError(touched, errors, index, true)}
                                autoFocus={false}
                                required={false}
                                values={values}
                                onChange={(e: any) => {
                                    const updatedSelectedRows = [...values.selectedRows];
                                    updatedSelectedRows[index][field] = e.target.value;
                                    setFieldValue(updatedSelectedRows);
                                }}>
                                {selectList.length > 0 ? (
                                    selectList.map((type: any) => (
                                        <MenuItem key={type.id} value={type.id}>{type.capacity}</MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>{translate.t("empty_listing")}</MenuItem>
                                )}
                            </Field>
                        )}
                        {isScheduled && (
                            <Field
                                as={Select}
                                name={`selectedRows[${index}].scheduled_stop`}
                                variant={"outlined"}
                                fullWidth
                                disabled={disabled}
                                label={translate.t("line_setup")}
                                error={validateError(touched, errors, index, true)}
                                autoFocus={false}
                                values={values}
                                onChange={(e: any) => {
                                    const updatedSelectedRows = [...values.selectedRows];
                                    updatedSelectedRows[index][field] = e.target.value;
                                    setFieldValue(updatedSelectedRows);
                                }}>
                                {selectList.length > 0 ? (
                                    selectList.map((type: any) => (
                                        <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>{translate.t("empty_listing")}</MenuItem>
                                )}
                            </Field>
                        )}
                        {validateError(touched, errors, index, true) && (<div className={"required-field"}>{translate.t("required_field")}</div>)}
                    </FormControl>
                )}
            </Box>
        </Box>
    );


};

export {SimulationFieldComponent};
