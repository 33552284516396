import * as React from "react";
import {useEffect} from "react";
import {translate} from "../../../translate/translate";
import {Box, Button, Fab, Grid, Paper, Typography} from "@mui/material";
import TablePaginationComponent from "../../../components/table/table-pagination.component";
import {BaseService} from "../../../services/base-service";
import {Add, Search} from "@mui/icons-material";
import {URLS} from "../../../services/app-urls";
import {NavLink, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {PaginatedResult} from "../../../models/default/paginated-result";
import TableActionComponent from "../../../components/table/table-action.component";
import {Form, Formik} from "formik";
import {ErrorComponent} from "../../../components/error/error.component";
import {Setting} from "../../../models/settings/setting";
import HistoryComponent from "../../../components/history/history.component";
import AutocompletePaginated from "../../../components/autocomplete/autocompletePaginated";


const PreferencesComponent = () => {
    const service = new BaseService<Setting>(URLS.SETTING);
    const url = "/settings/preferences/";
    const [dataSource, setDataSource] = React.useState<Setting[]>([]);
    const navigate = useNavigate();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [openHistory, setOpenHistory] = React.useState(false);
    const [idHistory, setIdHistory] = React.useState(0);
    const [searchSetting, setSearchSetting] = React.useState<Setting>();
    const [pagination, setPagination] =
        React.useState<PaginatedResult<Setting>>();

    const handleOpenDialogHistory = (id: number) => {
        setIdHistory(id);
        setOpenHistory(true);
    };

    const handleCloseDialogHistory = () => {
        setOpenHistory(false);
    };

    const search = (restartIndex = false) => {
        service.clearParameters();

        if (searchSetting) {
            service.addParameter("name", searchSetting.name);
        }
        service.addParameter("limit", rowsPerPage);
        service.addParameter("offset", (page * rowsPerPage));
        service.getAllPaginate().then(response => {
            setPagination(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
        restartIndex && setPage(0);
    };

    useEffect(() => {
        setDataSource(pagination?.results ?? []);
    }, [pagination]);

    useEffect(() => {
        search();
    }, [page, rowsPerPage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDoubleClick = (row: Setting) => {
        navigate(`${url}${row.id}`);
    };

    const handleDelete = (row: Setting) => {
        service.delete(Number(row.id)).then(() => {
            toast.success(translate.t("successfully_deleted"));
            service.getAll().then(() => {
                search();
            });
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    return (
        <section>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("preferences")}
                </Typography>
            </div>
            <div className="floating-button">
                <Button
                    component={NavLink}
                    to={url + "create"}
                    startIcon={<Add/>}
                    variant="contained"
                    color="secondary"
                    className={"button-new"}>
                    {translate.t("new")}
                </Button>
            </div>

            <Grid item xs={12}>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{name: ""}}
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    search(true);
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                () => (
                                    <Form autoComplete="off">
                                        <Grid alignItems="center" container spacing={2} direction={"row"}>
                                            <Grid item xs>
                                                <AutocompletePaginated
                                                    label="name"
                                                    searchField="name"
                                                    service={service}
                                                    display="name"
                                                    onSelectElement={(setting: Setting) => {
                                                        setSearchSetting(setting);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Fab
                                                    size="small"
                                                    type="submit"
                                                    color="secondary">
                                                    <Search/>
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>

            <Paper variant="outlined" sx={{marginTop: "16px"}}>
                <TablePaginationComponent
                    field={"name"}
                    service={service}
                    state={dataSource}
                    displayedColumnsShow={["#", "name", "value", "action"]}
                    displayedColumns={["id", "name", "value", "action"]}
                    handleDelete={handleDelete}
                    url={url}
                    handleDoubleClick={handleDoubleClick}
                    count={pagination?.count}
                    next={pagination?.next}
                    previous={pagination?.previous}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    actionsComponent={TableActionComponent}
                    handleOpenDialogHistory={handleOpenDialogHistory}
                    columnStyles={{1: "20%", 2: "50%", 3: "30%"}}
                />
            </Paper>
            <HistoryComponent
                openHistory={openHistory}
                handleCloseDialogHistory={handleCloseDialogHistory}
                idHistory={idHistory}
                columns_history={["name", "value"]}
                service={service}
            />
        </section>
    );
};

export default PreferencesComponent;
