import moment from "moment";

export const DateTimeFormat = (datetime: string) => {
    const parsedDate = moment(datetime);
    return parsedDate.format("DD/MM/YYYY HH:mm");
};

export const DateFormat = (datetime: string, format = "DD/MM/YYYY") => {
    const parsedDate = moment(datetime);
    return parsedDate.format(format);
};

export const TimeFormat = (datetime: string, format = "HH:mm") => {
    const parsedDate = moment(datetime);
    return parsedDate.format(format);
};

export const DateNowEnUS = (date?: Date) => {
    const actualDate = date ? date : new Date();
    const year = actualDate.getFullYear();
    const month = String(actualDate.getMonth() + 1).padStart(2, "0");
    const day = String(actualDate.getDate()).padStart(2, "0");
    return year + "-" + month + "-" + day;
};

export const ConvertDateToString = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return year + "-" + month + "-" + day;
};
