import BaseModel from "../default/base-model";

export const NAME = {
    MAIL_HOST: "MAIL_HOST",
    MAIL_PORT: "MAIL_PORT",
    MAIL_SENDER: "MAIL_SENDER",
    MAIL_PASSWORD: "MAIL_PASSWORD",
    MAIL_USE_TLS: "MAIL_USE_TLS",
    MAIL_USE_SSL: "MAIL_USE_SSL",
    DEMAND_DAYS_BEFORE_VALIDATE: "DEMAND_DAYS_BEFORE_VALIDATE",
    EMAILS_TO_ALERT: "EMAILS_TO_ALERT",
    PRODUCTION_PLAN_XLS_PATH: "PRODUCTION_PLAN_XLS_PATH",
    WIP_VOLUME_MODELS: "WIP_VOLUME_MODELS",
    WIP_VOLUME_STATIONS: "WIP_VOLUME_STATIONS",
    BUFFER_CHART_MODELS: "BUFFER_CHART_MODELS",
    BUFFER_CHART_STATIONS: "BUFFER_CHART_STATIONS",
    OFFLINE_PROJECTS: "OFFLINE_PROJECTS",
    BUFFER_CHAR_TARGET: "BUFFER_CHAR_TARGET",
    UPPH_TARGET: "UPPH_TARGET",
    UPH_TARGET: "UPH_TARGET",
    UPH_PRODUCTS: "UPH_PRODUCTS",
    PATH_IMPORT_STOCK: "PATH_IMPORT_STOCK",
    HOUR_IMPORT_STOCK: "HOUR_IMPORT_STOCK",
    EMAILS_TO_PLANNING: "EMAILS_TO_PLANNING",
    EMAIL_KEY: "EMAIL_KEY",
};

export class Setting extends BaseModel {
    name: string;
    value: string;

    constructor() {
        super();
        this.name = "";
        this.value = "";
    }
}
