import BaseModel from "../default/base-model";

export class ActivePeopleCount extends BaseModel {
    year: number;

    constructor() {
        super();
        this.year = 0;
    }
}
