import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Paper, Tooltip,
    Typography
} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {translate} from "../../../../../translate/translate";
import {Assignment, Build, Edit, Info, MoreVertOutlined} from "@mui/icons-material";
import DialogInfoDemandComponent from "../../dialog-info-demand/dialog-info-demand.component";
import {Demand} from "../../../../../models/basic/demand";
import DialogEditDemandComponent from "../../dialog-edit-demand/dialog-edit-demand.component";
import DialogPlanningDemandComponent from "../../dialog-planning-demand/dialog-planning-demand.component";
import {BaseService} from "../../../../../services/base-service";
import {DailyPlanning} from "../../../../../models/basic/daily-planning";
import {URLS} from "../../../../../services/app-urls";
import {Version} from "../../../../../models/basic/version";
import {ErrorComponent} from "../../../../../components/error/error.component";

const DelayedMenuComponent = (props: any) => {
    const {
        handleSearch,
        version,
        delayed,
    } = props;
    const [expanded, setExpanded] = useState<boolean>(false);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [demandSelected, setDemandSelected] = useState<Demand>(new Demand());
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [openEditDemandDialog, setOpenEditDemandDialog] = useState(false);
    const [openEditPlanningDialog, setOpenEditPlanningDialog] = useState(false);
    const serviceDailyPlanning = new BaseService<DailyPlanning>(URLS.DAILY_PLANNING);
    const serviceVersion = new BaseService<Version>(URLS.VERSION);
    const [dailyPlanningValue, setDailyPlanningValue] = useState<any>();
    const [versionValue, setVersionValue] = useState<any>();

    const handleDemandMenuClick = (event: any, item: any) => {
        setDemandSelected(item);
        setAnchorEl(event.currentTarget);
        getDailyPlanning(item);
    };

    const handleOpenEditPlanning = () => {
        setAnchorEl(null);
        setOpenEditPlanningDialog(true);
    };

    const handleDemandMenuClose = () => {
        setAnchorEl(null);
    };

    const handleChange = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    const handleOpeninfoDemand = () => {
        setAnchorEl(null);
        setDemandSelected(demandSelected);
        setOpenInfoDialog(true);
    };

    const handleOpenEditDemand = () => {
        setAnchorEl(null);
        setDemandSelected(demandSelected);
        setOpenEditDemandDialog(true);
    };

    const handleCloseDialog = (search: boolean) => {
        setOpenInfoDialog(false);
        setOpenEditDemandDialog(false);
        setOpenEditPlanningDialog(false);
        setDailyPlanningValue(null);
        search ? handleSearch() : null;
    };

    const getTotalItems = () => {
        let totalCount = 0;
        if (Array.isArray(delayed.delayed)) {
            totalCount = delayed.delayed.length;
        }
        setTotalItems(totalCount);
    };

    const getDailyPlanning = (value: any) => {
        serviceDailyPlanning.clearParameters();
        serviceDailyPlanning.addParameter("demand", value.id);
        serviceDailyPlanning.getAllFromListRoute("get_complete_daily_planning").then(response => {
            setDailyPlanningValue(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const getVersion = () => {
        serviceVersion.clearParameters();
        serviceVersion.getById(version).then(response => {
            setVersionValue(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    useEffect(() => {
        getVersion();
    }, [version]);

    useEffect(() => {
        getTotalItems();
    }, [delayed]);

    return (
        <Box>
            <Accordion expanded={expanded} onChange={handleChange} sx={{borderRadius: "0", boxShadow: "none"}}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className="accordion-summary"
                >
                    <Box justifyContent="space-between" display="flex">
                        <Typography fontWeight="bold" justifyContent="flex-start" display="flex">
                            {translate.t("delayed")}
                        </Typography>
                        <Paper className="badge-quantity" elevation={0}>
                            <Typography variant="body2">
                                {totalItems}
                            </Typography>
                        </Paper>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{padding: "5px 0px 5px"}}>
                    <Box>
                        {delayed.delayed && Array.isArray(delayed.delayed) && delayed.delayed.length > 0 ? (
                            delayed.delayed.map((item: any, index: number) => (
                                <Card key={index}
                                    className="demand-card"
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        backgroundColor: item.color,
                                        color: item.font_color,
                                        padding: "6px"
                                    }}>

                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}>
                                        <Typography sx={{color: item.font_color, fontWeight: "bold",}}>
                                            {item.description}
                                        </Typography>
                                        {item.bone_pile ? (
                                            <Tooltip title={translate.t("bone_pile")}>
                                                <Build sx={{color: item.font_color}}/>
                                            </Tooltip>
                                        ) : null}
                                        <Button
                                            sx={{backgroundColor: "transparent"}}
                                            onClick={(event) => handleDemandMenuClick(event, item)}
                                        >
                                            <MoreVertOutlined
                                                sx={{color: item.font_color}}/>
                                        </Button>
                                    </Box>
                                </Card>
                            ))

                        ) : (
                            <Typography sx={{padding: "8px 16px"}}>{translate.t("empty_listing")}</Typography>
                        )}
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleDemandMenuClose}>
                <MenuItem onClick={() => handleOpeninfoDemand()}>
                    <ListItemIcon>
                        <Info/>
                    </ListItemIcon>
                    <ListItemText>
                        {translate.t("info_demand")}
                    </ListItemText>
                </MenuItem>
                {dailyPlanningValue && new Date(dailyPlanningValue?.daily_planning_date) > new Date() && !versionValue?.final_version && (
                    <MenuItem onClick={() => handleOpenEditPlanning()}>
                        <ListItemIcon>
                            <Edit/>
                        </ListItemIcon>
                        <ListItemText>
                            {translate.t("edit_planning")}
                        </ListItemText>
                    </MenuItem>
                )}
                {!versionValue?.final_version && (
                    <MenuItem onClick={() => handleOpenEditDemand()}>
                        <ListItemIcon>
                            <Assignment/>
                        </ListItemIcon>
                        <ListItemText>
                            {translate.t("edit_demand")}
                        </ListItemText>
                    </MenuItem>
                )}
            </Menu>
            {openInfoDialog && (<DialogInfoDemandComponent style="width: 1200px!important; max-width: 1200px!important;"
                open={openInfoDialog} onClose={handleCloseDialog}
                demand={demandSelected}></DialogInfoDemandComponent>)}
            {openEditDemandDialog && (
                <DialogEditDemandComponent style="width: 1200px!important; max-width: 1200px!important;"
                    open={openEditDemandDialog} onClose={handleCloseDialog}
                    demand={demandSelected}></DialogEditDemandComponent>)}
            {openEditPlanningDialog && dailyPlanningValue && (
                <DialogPlanningDemandComponent style="width: 1200px!important; max-width: 1200px!important;"
                    dailyPlanning={dailyPlanningValue}
                    open={openEditPlanningDialog}
                    onClose={handleCloseDialog}></DialogPlanningDemandComponent>)}
        </Box>
    );
};

export default DelayedMenuComponent;
