import {BaseService} from "../../../../services/base-service";
import {Box, Button, Grid, InputAdornment, Paper, TextField, Typography,} from "@mui/material";
import React, {useEffect, useState} from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {translate} from "../../../../translate/translate";
import {URLS} from "../../../../services/app-urls";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {DowntimeReason} from "../../../../models/basic/downtime-reason";
import {toast} from "react-toastify";
import {ErrorComponent} from "../../../../components/error/error.component";


const DowntimeReasonItemComponent = () => {
    const [object, setObject] = React.useState(new DowntimeReason());
    const url = "/registration/downtime_reason/";
    const navigate = useNavigate();
    const params = useParams();
    const downtimeReasonService = new BaseService<DowntimeReason>(URLS.DOWNTIME_REASON);
    const [buttonValue, setButtonValue] = useState("");

    const schema = Yup.object().shape({
        description: Yup.string().max(256, `${translate.t("exceeds_character_limit")}`).required(translate.t("required_field")),
    });

    useEffect(() => {
        if (params["action"] !== "create") {
            downtimeReasonService.clearParameters();
            downtimeReasonService.getById(parseInt(params["action"] as string)).then((response) => {
                setObject(response.data);
            });
        }
    }, []);

    const onSubmit = (values: DowntimeReason, {resetForm}: FormikHelpers<DowntimeReason>) => {
        setTimeout(() => {
            const downtimeReason = new DowntimeReason();
            Object.assign(downtimeReason, values);
            if (downtimeReason.id) {
                downtimeReasonService.patch(downtimeReason, downtimeReason.id)
                    .then(() => {
                        toast.success(translate.t("successfully_updated"));
                        if (buttonValue === "save") {
                            setObject(new DowntimeReason());
                            resetForm();
                        } else {
                            navigate(url);
                        }
                    })
                    .catch((error: any) => {
                        ErrorComponent(error);
                    });
            } else {
                downtimeReasonService.save(downtimeReason)
                    .then(() => {
                        toast.success(translate.t("successfully_registered"));
                        if (buttonValue === "save") {
                            setObject(new DowntimeReason());
                            resetForm();
                        } else {
                            navigate(url);
                        }
                    })
                    .catch((error: any) => {
                        ErrorComponent(error);
                    });
            }
        }, 500);
    };

    return (
        <section>
            <div className="floating-title floating-title-item" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={url}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("downtime_reason")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={onSubmit}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {({isValid, values, isValidating, errors, touched}) => (
                                <Form autoComplete="off">
                                    <Grid container spacing={2} direction={"column"}>
                                        <Grid container spacing={2} item xs direction={"row"}>
                                            <Grid item xs>
                                                <Field
                                                    as={TextField}
                                                    name="description"
                                                    label={translate.t("description")}
                                                    variant={"outlined"}
                                                    fullWidth
                                                    autoFocus={true}
                                                    required={true}
                                                    values={values}
                                                    error={touched.description && Boolean(errors.description)}
                                                    InputProps={{
                                                        maxLength: 64,
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {touched.description && Boolean(errors.description) && (
                                                                    <div className={"required-field"}>
                                                                        {translate.t("required_field")}
                                                                    </div>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}/>
                                            </Grid>
                                        </Grid>
                                        <Grid item container justifyContent={"space-between"}>
                                            <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        component={NavLink}
                                                        to={url}
                                                        variant="outlined">
                                                        {translate.t("cancel")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="success"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save_form"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save_form"
                                                        disabled={!isValid || isValidating}>
                                                        {translate.t("save_form")}
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        className="button-options"
                                                        color="secondary"
                                                        variant="contained"
                                                        type="submit"
                                                        value="save"
                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                            setButtonValue(event.currentTarget.value);
                                                        }}
                                                        name="save"
                                                        disabled={!isValid || isValidating}>
                                                        {translate.t("save_plus")}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )
                            }
                        </Formik>
                    </Box>
                </Paper>
            </div>
        </section>
    );
};

export default DowntimeReasonItemComponent;
