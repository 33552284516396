import {BaseService} from "../../../../services/base-service";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    ListItemText,
    MenuItem,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ErrorMessage, Field, Form, Formik, FormikHelpers} from "formik";
import * as Yup from "yup";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {translate} from "../../../../translate/translate";
import {URLS} from "../../../../services/app-urls";
import {toast} from "react-toastify";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {ManualDefaultTime} from "../../../../models/basic/manual-default-time";
import {Product} from "../../../../models/basic/product";
import Select from "@mui/material/Select";
import {ProductionLine} from "../../../../models/basic/production-line";
import AutocompletePaginated from "../../../../components/autocomplete/autocompletePaginated";
import {ErrorComponent} from "../../../../components/error/error.component";

const ManualDefaultTimeItemComponent = () => {
    const url = "/production_capacity/manual_default_time/";
    const params = useParams();
    const navigate = useNavigate();
    const service = new BaseService<ManualDefaultTime>(URLS.MANUAL_DEFAULT_TIME);
    const serviceProductionLine = new BaseService<ProductionLine>(URLS.PRODUCTION_LINE);
    const serviceProduct = new BaseService<Product>(URLS.PRODUCT);
    const [object, setObject] = React.useState(new ManualDefaultTime());
    const [buttonValue, setButtonValue] = useState("");
    const [allLines, setAllLines] = useState<ProductionLine[]>([]);
    const [selectedLines, setSelectedLines] = useState<ProductionLine[]>([]);
    const [productValue, setProductValue] = React.useState<Product | null>(null);
    const [productionLineFocused, setProductionLineFocused] = useState(false);
    const [clearAutocomplete, setClearAutocomplete] = useState<boolean>(false);
    const [resetAutocomplete, setResetAutocomplete] = useState<boolean>(false);

    const handleProductionLineAfterFocus = () => {
        setProductionLineFocused(true);
    };

    const schema = Yup.object().shape({
        capacity: Yup.number().required().positive().integer(),
        default_time_seconds: Yup.string().required(),
    });

    useEffect(() => {
        if (params["action"] != "create") {
            service.clearParameters();
            service.addParameter("expand", "lines,product");
            service.getById(parseInt(params["action"] as string)).then((response) => {
                setProductValue(response.data.product);
                setSelectedLines(response.data.lines);
                setObject(response.data);
                setProductionLineFocused(false);
            });
        }
    }, [params["action"]]);

    useEffect(() => {
        getLines();
        setProductionLineFocused(false);
    }, []);

    const getLines = () => {
        serviceProductionLine.clearParameters();
        serviceProductionLine.addParameter("ordering", "id");
        serviceProductionLine.addParameter("type", "M");
        serviceProductionLine.getAll()
            .then((response: any) => {
                setAllLines(response.data);
            });
    };

    const validateNumberInput = (event: any, fieldName: string) => {
        const regex = /^[0-9]+(.[0-9]*)?$/;
        if (fieldName === "default_time_seconds" && !regex.test(event.target.value)) {
            event.target.value = event.target.value.replace(/[^0-9.]/g, "");
        } else if (!regex.test(event.target.value)) {
            event.target.value = event.target.value.replace(/[^0-9]/g, "");
        }
    };

    const handleChangeProductionLine = (event: any, productionLine: ProductionLine) => {
        let newStateSide = [];
        const isChecked = event.target.checked;

        if (isChecked) {
            newStateSide.push(...selectedLines);
            newStateSide.push(productionLine);
        } else {
            newStateSide = selectedLines.filter(s => s.id !== productionLine.id);
        }
        setSelectedLines(newStateSide);
    };

    const handleChangeProduct = (event: any) => {
        setProductValue(event);
    };

    const resetFields = (resetForm: any) => {
        setObject(new ManualDefaultTime());
        setProductValue(null);
        setSelectedLines([]);
        setClearAutocomplete(true);
        setResetAutocomplete(true);
        resetForm();
    };

    return (
        <section>
            <div className="floating-title floating-title-item" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    component={NavLink}
                    to={url}>
                    <ArrowBackIcon color="secondary" fontSize="large" fontWeight="fontWeightBold"/>
                </IconButton>
                <Typography variant={"h5"}>
                    {translate.t("manual_default_time")}
                </Typography>
            </div>
            <div>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize
                            initialValues={object}
                            onSubmit={(values: ManualDefaultTime, {resetForm}: FormikHelpers<ManualDefaultTime>) => {
                                setTimeout(() => {
                                    setClearAutocomplete(false);
                                    setResetAutocomplete(false);
                                    const manual_default_time = new ManualDefaultTime();
                                    const selected_lines_payload = selectedLines.map((s) => s.url);
                                    values.people_quantity = values.people_quantity || null;
                                    values.product = productValue?.url;
                                    values.production_line_list = selected_lines_payload;
                                    values.lines = selected_lines_payload;
                                    Object.assign(manual_default_time, values);
                                    if (manual_default_time.id) {
                                        try {
                                            service.patch(manual_default_time, manual_default_time.id).then(() => {
                                                toast.success(translate.t("successfully_updated"));
                                                if (buttonValue === "save") {
                                                    resetFields(resetForm);
                                                    setProductionLineFocused(false);
                                                } else {
                                                    navigate(url);
                                                }
                                            }).catch((error: any) => {
                                                ErrorComponent(error);
                                            });
                                        } catch (error) {
                                            console.log(error);
                                        }
                                    } else {
                                        try {
                                            service.save(manual_default_time)
                                                .then(() => {
                                                    toast.success(translate.t("successfully_registered"));
                                                    if (buttonValue === "save") {
                                                        resetFields(resetForm);
                                                        setProductionLineFocused(false);
                                                    } else {
                                                        navigate(url);
                                                    }
                                                })
                                                .catch((error) => {
                                                    const {response} = error;
                                                    if (response?.status === 400) {
                                                        const {data} = response;
                                                        const message_error = data.non_field_errors || data.default_time_seconds || "error_required_field";
                                                        toast.error(translate.t(message_error));
                                                        return;
                                                    }
                                                    console.log(error);
                                                });
                                        } catch (error) {
                                            console.log(error);
                                        }
                                    }
                                }, 500);
                            }}
                            validationSchema={schema}
                            validateOnChange={true}>
                            {({isValid, values, isValidating, errors, touched}) => <Form autoComplete="off">
                                <Grid container spacing={2} direction={"row"}>
                                    <Grid container item xs={12} spacing={2} justifyContent={"flex-end"}>
                                        <Grid item xs>
                                            <AutocompletePaginated
                                                display={["model","name"]}
                                                searchField="full_description"
                                                label="product"
                                                service={serviceProduct}
                                                onSelectElement={handleChangeProduct}
                                                element={productValue}
                                                required={true}
                                                clear={clearAutocomplete}
                                                reset={resetAutocomplete}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <FormControl fullWidth>
                                                <InputLabel required={true} className={productionLineFocused && !selectedLines.length ? "error-required" : ""}>
                                                    {translate.t("production_line")}
                                                </InputLabel>
                                                <Field
                                                    as={Select}
                                                    name="production_line"
                                                    variant={"outlined"}
                                                    fullWidth
                                                    multiple
                                                    onBlur={handleProductionLineAfterFocus}
                                                    label={translate.t("production_line")}
                                                    error={productionLineFocused && !selectedLines.length}
                                                    autoFocus={false}
                                                    required={true}
                                                    renderValue={(selected: any) => selected.map((obj: any) => obj.name).join(", ")}
                                                    value={selectedLines}>
                                                    {allLines.map((productionLine: ProductionLine) => (
                                                        <MenuItem key={productionLine.id}>
                                                            <Checkbox
                                                                checked={selectedLines.find(obj => (obj.id === productionLine.id)) !== undefined}
                                                                onChange={(p: any) => handleChangeProductionLine(p, productionLine)}/>
                                                            <ListItemText primary={productionLine.name}/>
                                                        </MenuItem>
                                                    ))}
                                                </Field>
                                                {productionLineFocused && !selectedLines.length && (
                                                    <div className={"required-field"}>
                                                        {translate.t("required_field")}
                                                    </div>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={2} justifyContent={"flex-end"}>
                                        <Grid item xs>
                                            <Typography variant={"h6"}>
                                                {translate.t("default_expression") + " Capacidade_Headcount"}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={2} justifyContent={"flex-end"}>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="default_time_seconds"
                                                label={translate.t("default_time_seconds")}
                                                variant={"outlined"}
                                                fullWidth
                                                autoFocus={false}
                                                required={true}
                                                values={values}
                                                error={touched.default_time_seconds && Boolean(errors.default_time_seconds)}
                                                InputProps={{
                                                    maxLength: 16,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {touched.default_time_seconds && Boolean(errors.default_time_seconds) && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}/>
                                        </Grid>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="capacity"
                                                label={translate.t("capacity")}
                                                variant={"outlined"}
                                                fullWidth
                                                autoFocus={false}
                                                required={true}
                                                values={values}
                                                error={touched.capacity && Boolean(errors.capacity)}
                                                InputProps={{
                                                    maxLength: 16,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {touched.capacity && Boolean(errors.capacity) && (
                                                                <div className={"required-field"}>
                                                                    {translate.t("required_field")}
                                                                </div>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}/>
                                        </Grid>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="people_quantity"
                                                variant={"outlined"}
                                                fullWidth
                                                label={translate.t("people_quantity")}
                                                autoFocus={false}
                                                value={values.people_quantity === null ? "" : values.people_quantity}
                                                required={false}
                                                type="text"
                                                inputProps={{
                                                    maxLength: 16,
                                                    onInput: (event: any) => validateNumberInput(event, "people_quantity")
                                                }}
                                                disabled={!values.people_quantity}
                                                helperText={<ErrorMessage name="people_quantity"/>}/>
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction={"row"}>
                                        <Grid container item xs={12} spacing={1} justifyContent={"flex-end"}>
                                            <Grid item>
                                                <Button
                                                    color="secondary"
                                                    component={NavLink}
                                                    to={url}
                                                    variant="outlined">
                                                    {translate.t("cancel")}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className="button-options"
                                                    color="success"
                                                    variant="contained"
                                                    type="submit"
                                                    value="save_form"
                                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                        setButtonValue(event.currentTarget.value);
                                                    }}
                                                    name="save_form"
                                                    disabled={!isValid || isValidating || !values.capacity || !values.default_time_seconds || !productValue || selectedLines.length === 0}>
                                                    {translate.t("save_form")}
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    className="button-options"
                                                    color="secondary"
                                                    variant="contained"
                                                    type="submit"
                                                    value="save"
                                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                        setButtonValue(event.currentTarget.value);
                                                    }}
                                                    name="save"
                                                    disabled={!isValid || isValidating || !values.capacity || !values.default_time_seconds || !productValue || selectedLines.length === 0}>
                                                    {translate.t("save_plus")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Form>}
                        </Formik>
                    </Box>
                </Paper>
            </div>
        </section>
    );
};

export default ManualDefaultTimeItemComponent;
