import * as React from "react";
import {useEffect, useState} from "react";
import {translate} from "../../../translate/translate";
import {Box, Grid, Paper, Typography} from "@mui/material";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import EChartsReact, {useChart} from "echarts-for-react-fc";
import * as echarts from "echarts/core";
import {DataZoomComponent, GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent} from "echarts/components";
import {BarChart, LineChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";
import {OverallEquipmentEffectiveness} from "../../../models/basic/overall-equipment-effectiveness";
import {ErrorComponent} from "../../../components/error/error.component";
import {WebSocketService} from "../../../services/websocket-service";

echarts.use([GridComponent, BarChart, LineChart, CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, DataZoomComponent]);

const initOption: any = {
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "cross",
            crossStyle: {
                color: "#999"
            }
        }
    },
    toolbox: {
        feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar"] },
            restore: { show: false },
            saveAsImage: { show: true }
        }
    },
    legend: {
        data: ["OEE", "Meta"]
    },
    xAxis: [
        {
            type: "category",
            data: [],
            axisPointer: {
                type: "shadow"
            }
        }
    ],
    yAxis: [
        {
            type: "value",
            name: "OEE",
            min: 0,
            max: 100,
            interval: 10,
            axisLabel: {
                formatter: "{value} %"
            }
        }
    ],
    series: [
        {
            name: "OEE",
            type: "bar",
            tooltip: {
                valueFormatter: function (value: any) {
                    return value as number + " %";
                }
            },
            data: []
        },
        {
            name: "Meta",
            type: "line",
            tooltip: {
                valueFormatter: function (value: any) {
                    return value as number + " %";
                }
            },
            data: []
        }
    ]
};


const DashboardOeeComponent = () => {
    // Services
    const service = new BaseService<OverallEquipmentEffectiveness>(URLS.OEE);

    // Hooks
    const [report_type] = useState<string>("D");
    const [year] = useState<number>(new Date().getFullYear());
    const [showChart, setShowChart] = useState<boolean>(false);
    const {chartRef, setChartOption, handleListenChartReady} = useChart();
    const [oeeWeek, setOeeWeek] = useState<string>("");
    const [oeeMonth, setOeeMonth] = useState<string>("");
    const [oeeYear, setOeeYear] = useState<string>("");
    const [oeeYearTarget, setOeeYearTarget] = useState<string>("");
    const [oeeMonthTarget, setOeeMonthTarget] = useState<string>("");

    useEffect(() => {
        getReportData();
        const webSocketService = new WebSocketService("dashboard_oee");
        webSocketService.onMessage((response: any) => {
            const result: any = JSON.parse(response.data);
            setChartValues(result);
        });
    }, []);

    const setChartValues = (data: any) => {
        const option = JSON.parse(JSON.stringify(initOption));
        option["xAxis"][0]["data"] = data["days"];
        option["series"][0]["data"] = data["oee"];
        option["series"][1]["data"] = data["goals"];
        setOeeWeek(data["title"]["actual_week"]);
        setOeeMonth(data["title"]["actual_month"]);
        setOeeYear(data["title"]["actual_year"]);
        setOeeMonthTarget(data["title"]["month_target"]);
        setOeeYearTarget(data["title"]["year_target"]);
        setChartOption(option);
        setShowChart(true);
    };

    const getReportData = () => {
        service.clearParameters();
        service.addParameter("chart_type", report_type);
        service.addParameter("year", year);
        service.getFromListRoute("get_oee_chart_data")
            .then((response: any) => {
                setChartValues(response.data);
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    // Render return
    return (
        <Grid container>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("OEE")}
                </Typography>
            </div>

            {showChart && (
                <Grid container direction={"column"} gap={0}>
                    <Grid item xs>
                        <Box display="flex" gap={2}>
                            <Paper className={"card-paper"} variant="outlined" square>
                                <Typography variant={"h5"} fontSize={"1.1rem"}>{translate.t("oee_one") + ":"}</Typography>
                                <Typography variant={"h4"}>{oeeWeek + " %"}</Typography>
                            </Paper>
                            <Paper className={"card-paper"} variant="outlined" square>
                                <Typography variant={"h5"} fontSize={"1.1rem"}>{translate.t("monthly_oee") + ":"}</Typography>
                                <Typography variant={"h4"}>{oeeMonth + " %"}</Typography>
                            </Paper>
                            <Paper className={"card-paper"} variant="outlined" square>
                                <Typography variant={"h5"} fontSize={"1.1rem"}>{translate.t("year_oee") + ":"}</Typography>
                                <Typography variant={"h4"}>{oeeYear + " %"}</Typography>
                            </Paper>
                            <Paper className={"card-paper"} variant="outlined" square>
                                <Typography variant={"h5"} fontSize={"1.1rem"}>{translate.t("month_target") + ":"}</Typography>
                                <Typography variant={"h4"}>{oeeMonthTarget + " %"}</Typography>
                            </Paper>
                            <Paper className={"card-paper"} variant="outlined" square>
                                <Typography variant={"h5"} fontSize={"1.1rem"}>{translate.t("year_target") + ":"}</Typography>
                                <Typography variant={"h4"}>{oeeYearTarget + " %"}</Typography>
                            </Paper>
                        </Box>
                    </Grid>

                    <Grid item xs>
                        <Paper id={"chart"} sx={{padding: "16px"}}>
                            <EChartsReact
                                autoResize={true}
                                style={{width: "100%", height: 500,}}
                                ref={chartRef}
                                echarts={echarts}
                                onChartReady={handleListenChartReady}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default DashboardOeeComponent;
