import * as React from "react";
import {useEffect} from "react";
import {Grid, Paper} from "@mui/material";
import * as echarts from "echarts/core";
import {DataZoomComponent, GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent} from "echarts/components";
import {BarChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";

import EChartsReact, {useChart,} from "echarts-for-react-fc";
import {translate} from "../../../../translate/translate";

echarts.use([GridComponent, BarChart, CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, DataZoomComponent]);

const CategoricalReportComponent = (props: any) => {

    const {
        reportData,
        chartTitle,
        reportType
    } = props;

    // Hooks
    const {chartRef, setChartOption, handleListenChartReady} = useChart();

    const emphasisStyle = {
        itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0,0,0)"
        }
    };

    // Use effects
    useEffect(() => {
        handleClickSetLineOption();
    }, []);


    const handleClickSetLineOption = () => {

        const yearlyOption: any = {
            title: {show: true, text: chartTitle, textStyle: {fontSize: 20}, top: "3%", left: "2%"},
            dataZoom: [{type: "slider", show: true, realtime: true, start: 0, end: 100}],
            legend: {data: reportData.legends, textStyle: {fontSize: 16}, top: "10%"},
            grid: {right: "5%", left: "5%", containLabel: true, top: "25%"},
            toolbox: {show: true, itemSize: 22, top: "3%", right: "1%", feature: {saveAsImage: {name: translate.t("downtime_summary_by_quantity_report"), title: translate.t("save")}}},
            emphasis: emphasisStyle,
            barCategoryGap: "20%",
            tooltip: {
                trigger: "axis", axisPointer: {type: "shadow"},
                valueFormatter: (value: any) => {
                    return reportType === "Q" ? value : (value > 0 ? `${value} min` : "");
                }
            },
            xAxis: {
                data: reportData.xAxis,
                type: "category",
                splitArea: {show: true},
                axisTick: {show: false, alignWithLabel: true},
                axisLabel: {interval: 0, show: true, fontSize: 16},
            },
            yAxis: {
                type: "value", show: true,
                axisLine: {onZero: false}
            },
            series: reportData.series
        };
        setChartOption(yearlyOption, {notMerge: true}
        );

    };


    // Render return
    return (
        <section>
            <Paper sx={{marginTop: "16px"}}>
                <Grid item>
                    <div id={"yearlyChart"}>
                        <EChartsReact
                            autoResize={true}
                            style={{width: "100%", height: 500,}}
                            ref={chartRef}
                            echarts={echarts}
                            onChartReady={handleListenChartReady}
                        />
                    </div>
                </Grid>
            </Paper>
        </section>
    );
};

export default CategoricalReportComponent;
