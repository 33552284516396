import BaseModel from "../default/base-model";
import {CalendarEventType} from "./calendar-event-type";
import {ProductionLine} from "./production-line";

export class CalendarEvent extends BaseModel {
    description: string;
    id_calendar_event_type: number;
    calendar_event_type: CalendarEventType;
    event_date: string;
    start_date: string;
    end_date: string;
    start_time: string;
    end_time: string;
    start_time_event: string;
    end_time_event: string;
    production_lines: ProductionLine[];

    constructor() {
        super();
        this.id = 0;
        this.description = "";
        this.id_calendar_event_type = 0;
        this.calendar_event_type = new CalendarEventType();
        this.event_date = "";
        this.start_date = "";
        this.end_date = "";
        this.start_time = "";
        this.end_time = "";
        this.start_time_event = "";
        this.end_time_event = "";
        this.active = true;
        this.url = "";
        this.production_lines = [];
    }
}

export class CalendarEventSave extends BaseModel {
    description: string;
    calendar_event_type: string;
    start_date: string;
    end_date: string;
    start_time: string | undefined | null;
    end_time: string | undefined | null;
    is_replanning: boolean;
    production_lines: string[];

    constructor() {
        super();
        this.id = 0;
        this.description = "";
        this.calendar_event_type = "";
        this.start_date = "";
        this.end_date = "";
        this.is_replanning = false;
        this.production_lines = [];
    }
}
