import * as React from "react";
import {useEffect, useState} from "react";
import {translate} from "../../../translate/translate";
import {Box, Button, Fab, FormControl, Grid, InputLabel, ListItemText, MenuItem, Paper, Typography} from "@mui/material";
import TablePaginationComponent from "../../../components/table/table-pagination.component";
import {BaseService} from "../../../services/base-service";
import {Add, Search} from "@mui/icons-material";
import {URLS} from "../../../services/app-urls";
import {NavLink, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {PaginatedResult} from "../../../models/default/paginated-result";
import TableActionComponent from "../../../components/table/table-action.component";
import {Field, Form, Formik} from "formik";
import HistoryComponent from "../../../components/history/history.component";
import {Demand, DemandStatus} from "../../../models/basic/demand";
import Select from "@mui/material/Select";
import {ErrorComponent} from "../../../components/error/error.component";
import AutocompletePaginated from "../../../components/autocomplete/autocompletePaginated";
import {Product} from "../../../models/basic/product";
import {Version} from "../../../models/basic/version";


const DemandComponent = () => {
    // URL Request
    const url = "/planning/demand/";

    // Services
    const service = new BaseService<Demand>(URLS.DEMAND);
    const productService = new BaseService<Product>(URLS.PRODUCT);
    const versionService = new BaseService<Version>(URLS.VERSION);

    // Hooks Filters or Search
    const [searchProduct, setSearchProduct] = useState<Product>();
    const [searchVersion, setSearchVersion] = useState<Version>();
    const [searchStatus, setSearchStatus] = React.useState<string>("");

    // Hooks  Data and Paginate
    const [dataSource, setDataSource] = React.useState<Demand[]>([]);
    const [pagination, setPagination] = React.useState<PaginatedResult<Demand>>();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [dataDemandStatus, setDemandStatus] = React.useState<DemandStatus[]>([]);

    // Hooks History
    const [openHistory, setOpenHistory] = React.useState(false);
    const [idHistory, setIdHistory] = React.useState(0);

    // Other
    const navigate = useNavigate();

    // Use effects
    useEffect(() => {
        setDataSource(pagination?.results ?? []);
    }, [pagination]);

    useEffect(() => {
        search();
        searchDemandStatus();
    }, [page, rowsPerPage]);

    // Search
    const search = (restartIndex = false) => {
        service.clearParameters();

        if (searchProduct) service.addParameter("product", searchProduct.id);
        if (searchVersion) service.addParameter("version", searchVersion.id);
        if (searchStatus) service.addParameter("status", searchStatus);

        service.addParameter("expand", "product,version");
        service.addParameter("limit", rowsPerPage);
        service.addParameter("offset", (page * rowsPerPage));
        service.getAllPaginate().then(response => {
            setPagination(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
        restartIndex && setPage(0);
    };

    const searchDemandStatus = () => {
        service.getChoices("status").then((response: any) => {
            setDemandStatus(response?.map((item: any) => item));
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    // Handle
    const handleOpenDialogHistory = (id: number) => {
        setIdHistory(id);
        setOpenHistory(true);
    };

    const handleCloseDialogHistory = () => {
        setOpenHistory(false);
    };


    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDoubleClick = (row: Demand) => {
        navigate(`${url}${row.id}`);
    };

    const handleDelete = (row: Demand) => {
        service.delete(Number(row.id)).then(() => {
            toast.success(translate.t("successfully_deleted"));
            service.getAll().then(() => {
                search();
            });
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handleSwitch = (row: Demand, column: keyof Demand) => {
        (row[column] as boolean) = !row[column];
        const payload = {"active": row.active};
        service.patch(payload, Number(row.id)).then(() => {
            toast.success(translate.t("successfully_updated"));
            service.clearParameters();
            search();
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    // Render return
    return (
        <Box>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("demand")}
                </Typography>
            </div>
            <div className="floating-button">
                <Button
                    component={NavLink}
                    to={url + "create"}
                    startIcon={<Add/>}
                    variant="contained"
                    color="secondary">
                    {translate.t("new")}
                </Button>
            </div>

            <Grid>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{name: ""}}
                            onSubmit={(values: any, {setSubmitting}) => {
                                setTimeout(() => {
                                    search(true);
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {
                                () => (
                                    <Form autoComplete="off">
                                        <Grid alignItems="center" container spacing={2} direction={"row"}>
                                            <Grid item xs={12} sm={12} md={4} lg={3}>
                                                <AutocompletePaginated
                                                    display={["model","name"]}
                                                    searchField="full_description"
                                                    label="product"
                                                    service={productService}
                                                    onSelectElement={(product: Product) => {
                                                        setSearchProduct(product);
                                                    }}
                                                    autoFocus={true}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={2}>
                                                <AutocompletePaginated
                                                    display="name"
                                                    searchField="name"
                                                    label="version"
                                                    service={versionService}
                                                    onSelectElement={(version: Version) => {
                                                        setSearchVersion(version);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={2.4}>
                                                <FormControl fullWidth>
                                                    <InputLabel className={"formControl"} htmlFor="status">
                                                        Status
                                                    </InputLabel>
                                                    <Field
                                                        variant={"outlined"}
                                                        defaultValue=""
                                                        name="status"
                                                        component={Select}
                                                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => setSearchStatus(event.target.value as string)}>
                                                        <MenuItem value="">-</MenuItem>
                                                        {dataDemandStatus.map((choice: any) => (
                                                            <MenuItem key={choice.value} value={choice.value}>
                                                                <ListItemText primary={translate.t(choice.display_name)}/>
                                                            </MenuItem>
                                                        ))}
                                                    </Field>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={0.6}>
                                                <Fab
                                                    size="small"
                                                    type="submit"
                                                    color="secondary">
                                                    <Search/>
                                                </Fab>
                                            </Grid>
                                        </Grid>
                                    </Form>
                                )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>

            <Paper variant="outlined" sx={{marginTop: "16px"}}>
                <TablePaginationComponent
                    state={dataSource}
                    displayedColumnsShow={["#", "product", "version", "quantity", "color", "reference_date", "Status", "active", "action"]}
                    displayedColumns={["id", "product.name", "version.name", "quantity", "color", "reference_date", "status_name", "active", "action"]}
                    dateColumns={["reference_date"]}
                    handleDelete={handleDelete}
                    handleSwitch={handleSwitch}
                    url={url}
                    handleDoubleClick={handleDoubleClick}
                    count={pagination?.count}
                    next={pagination?.next}
                    previous={pagination?.previous}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    actionsComponent={TableActionComponent}
                    handleOpenDialogHistory={handleOpenDialogHistory}
                    columnStyles={{1: "5%", 2: "20%", 3: "10%", 4: "5%", 5: "5%", 6: "10%", 7: "10%", 8: "10%", 9: "5%"}}
                />
            </Paper>
            <HistoryComponent
                openHistory={openHistory}
                handleCloseDialogHistory={handleCloseDialogHistory}
                idHistory={idHistory}
                columns_history={["product", "version", "quantity", "reference_date", "color", "font_color", "status", "active"]}
                dateColumns={["reference_date"]}
                service={service}
            />
        </Box>
    );
};

export default DemandComponent;
