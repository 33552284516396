import React, {useEffect, useState} from "react";
import {Box, Button, Card, CardContent, Typography} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Edit} from "@mui/icons-material";
import {BaseService} from "../../../../../services/base-service";
import {URLS} from "../../../../../services/app-urls";
import {translate} from "../../../../../translate/translate";
import DialogActivePeopleLineComponent from "./dialog-active-people-line/dialog-active-people-line.component";
import {ActivePeopleLine} from "../../../../../models/basic/active-people-line";

const ActivePeopleLineComponent = (props: any) => {
    const { activePeopleCount } = props;
    const service = new BaseService<ActivePeopleLine>(URLS.ACTIVE_PEOPLE_LINE);
    const [datesObject, setDatesObject] = useState<any>();
    const [openPeopleLineDialog, setOpenPeopleLineDialog] = useState(false);
    const [peopleLineDay, setPeopleLineDay] = useState<any[]>([]);
    const [peopleLine, setPeopleLine] = useState<any[]>([]);
    const [weekTitle, setWeekTitle] = useState("");

    useEffect(() => {
        if (activePeopleCount.id) {
            getData();
        }
    }, [activePeopleCount]);

    const getData = () => {
        service.clearParameters();
        service.addParameter("active_line_count", activePeopleCount.id);
        service.getFromListRoute("generate_dates_for_year").then(response => setDatesObject(response.data));
    };

    const handleOpenPeopleLineDialog = (event: any, day: any, line: any, weekTitle = "", week = false) => {
        const days = [];
        const lines = [];
        if (week) {
            Object.keys(day).forEach((d: any) => days.push(d));
            Object.values(day).forEach((d: any) => lines.push(d));
        } else {
            days.push(day);
            lines.push(line);
        }
        setWeekTitle(weekTitle);
        setPeopleLineDay(days);
        setPeopleLine(lines);
        setOpenPeopleLineDialog(true);
    };

    const handlePeopleLineDialogClose = (result: boolean) => {
        setPeopleLineDay([]);
        setOpenPeopleLineDialog(false);
        if (result) {
            getData();
        }
    };



    return (
        <Box sx={{width: "100%"}}>
            {/* Meses */}
            {datesObject && Object.keys(datesObject).map((month) => {
                return (
                    <Accordion key={month}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Box display="flex" gap="10px" alignItems="center">
                                <Typography width={"130px"} variant={"h6"} borderRadius={"4px"} sx={{padding: "4px 8px", backgroundColor: "#E3F2FD"}}>
                                    {translate.t(month)}
                                </Typography>
                                <Box display="flex" flexDirection="row">
                                    {Object.keys(datesObject[month]["lines"]).map((line) => {
                                        return (
                                            <Box className="shift-data" key={line} display="flex" flexDirection="column" gap={1} alignItems="start">
                                                <Typography variant="h6">
                                                    {line}:
                                                </Typography>
                                                <Typography fontSize={"1.1em"} lineHeight={"1.6"}>
                                                    {datesObject[month]["lines"][line]}
                                                </Typography>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            {/* Semanas */}
                            {Object.keys(datesObject[month]["weeks"]).map((week) => {
                                return (
                                    <Accordion key={week}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                            <Box display="flex" gap="10px" alignItems="center">
                                                <Typography width={"130px"} variant={"h6"} borderRadius={"4px"} sx={{padding: "4px 8px", backgroundColor: "#E0F2F1"}}>
                                                    {week}
                                                </Typography>
                                                <Box display="flex" flexDirection="row">
                                                    {Object.keys(datesObject[month]["weeks"][week]["lines"]).map((line) => {
                                                        return (
                                                            <Box className="shift-data" gap={1} key={line} display="flex" flexDirection="column" alignItems="start">
                                                                <Typography variant={"h6"}>
                                                                    {line}:
                                                                </Typography>
                                                                <Typography fontSize={"1.1em"} lineHeight={"1.6"}>
                                                                    {datesObject[month]["weeks"][week]["lines"][line]}
                                                                </Typography>
                                                            </Box>
                                                        );
                                                    })}
                                                </Box>
                                                <Button
                                                    color={"secondary"}
                                                    size="small"
                                                    onClick={(event) => handleOpenPeopleLineDialog(event, datesObject[month]["weeks"][week]["days"], datesObject[month]["weeks"][week], week, true)}>
                                                    <Edit/>
                                                </Button>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {/* Dias */}
                                            <Box display="flex" flexDirection={"row"} flexWrap={"wrap"} sx={{gap: 2}}>
                                                {Object.keys(datesObject[month]["weeks"][week]["days"]).map((day) => {
                                                    return (
                                                        <Card sx={{width: "180px", border: "1px solid rgba(0, 0, 0, .2)", boxShadow: "none", backgroundColor: "#F0F8FF" }} key={day}>
                                                            <CardContent>
                                                                <Box marginBottom={"8px"} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
                                                                    <Typography variant={"h6"} gutterBottom sx={{ color: "text.secondary", fontSize: 14 , margin: 0}}>
                                                                        {day}
                                                                    </Typography>

                                                                    <Button
                                                                        color={"secondary"}
                                                                        size="small"
                                                                        onClick={(event) => handleOpenPeopleLineDialog(event, day, datesObject[month]["weeks"][week]["days"][day])}>
                                                                        <Edit/>
                                                                    </Button>
                                                                </Box>
                                                                {Object.keys(datesObject[month]["weeks"][week]["days"][day]).map((line) => {
                                                                    return (
                                                                        <Box className="active-line" marginBottom={"4px"} key={line} display="flex" flexDirection="row" justifyContent="space-between">
                                                                            <Typography>
                                                                                {line}:
                                                                            </Typography>
                                                                            <Typography variant="h6">
                                                                                {datesObject[month]["weeks"][week]["days"][day][line]["quantity"]}
                                                                            </Typography>
                                                                        </Box>
                                                                    );
                                                                })}
                                                            </CardContent>
                                                        </Card>
                                                    );
                                                })}
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </AccordionDetails>
                    </Accordion>
                );
            })}
            {openPeopleLineDialog && peopleLineDay && (
                <DialogActivePeopleLineComponent
                    activePeopleCount={activePeopleCount}
                    activePeopleLineDate={peopleLineDay}
                    activePeopleLine={peopleLine}
                    weekTitle={weekTitle}
                    open={openPeopleLineDialog}
                    onClose={(result: boolean) => handlePeopleLineDialogClose(result)}>
                </DialogActivePeopleLineComponent>)}
        </Box>
    );
};

export default ActivePeopleLineComponent;
