import {AxiosResponse} from "axios";
import {trackPromise} from "react-promise-tracker";
import {PaginatedResult} from "../models/default/paginated-result";
import Api from "./api-config";
import {HistoryResult} from "../models/default/history-result";

export interface Dictionary {
    [index: string]: string | number | any;
}


export class BaseService<T> {
    path: string;
    parameters = new URLSearchParams();
    private token: string | undefined;


    constructor(path: string) {
        this.path = path;
    }


    getOptions(responseType?: Dictionary | any, isFormData = false): Dictionary {
        const httpOptions: Dictionary = {};
        const token = this.token ? this.token : localStorage.getItem("token");
        httpOptions["headers"] = {};
        if (token) {
            httpOptions["headers"]["Authorization"] = `Bearer ${token}`;
        }
        if (this.parameters) {
            httpOptions["params"] = this.parameters;
        }
        if (responseType) {
            httpOptions["responseType"] = responseType;
        }
        if (isFormData) {
            httpOptions["headers"]["Content-Type"] = "multipart/form-data";
        }
        const language = localStorage.getItem("lang");
        if (language) {
            const languageMappings: { [key: string]: string } = {
                pt: "pt-br",
                en: "en-us",
            };
            httpOptions["headers"]["Accept-Language"] = languageMappings[language] || language;
        }
        return httpOptions;
    }

    mountFormData(payload: any, files?: File[]): FormData {
        const newObject: Dictionary = {};
        Object.assign(newObject, payload);
        const formData = new FormData();
        // add files
        if (files != null) {
            Array.from(files).forEach(file => formData.append(file.name, file));
            // add attr object
            if (payload != null) {
                Object.keys(newObject).forEach((key) => {
                    if (newObject[key] != null) {
                        formData.append(key, newObject[key]);
                    }
                });
            }
        }
        return formData;
    }

    addParameter(key: string, value: any) {
        if (this.parameters.has(key)) {
            this.parameters.set(key, value);
        } else {
            this.parameters.append(key, value);
        }
    }

    clearParameters(): void {
        this.parameters = new URLSearchParams();
        this.token = "";
    }

    async save(aObject: T | null, isFormData = false, files?: File[]): Promise<AxiosResponse<T>> {
        if (isFormData) {
            const formData = this.mountFormData(aObject, files);
            return await trackPromise(Api.post<T>(`${this.path}`, formData,
                this.getOptions(null, true)));
        } else {
            return await trackPromise(Api.post<T>(this.path, aObject, this.getOptions()));
        }
    }

    async postFromListRoute(payload: Dictionary, route: string, isFormData = false, files?: File[]):
        Promise<AxiosResponse<T>> {
        if (isFormData) {
            const formData = this.mountFormData(files);
            return await trackPromise(Api.post<T>(`${this.path}${route}/`, formData,
                this.getOptions(null, true)));
        } else {
            return await trackPromise(Api.post<T>(`${this.path}${route}/`, payload, this.getOptions()));
        }
    }

    async saveFromDetailRoute(
        payload: Dictionary,
        id: number,
        route: string,
        isFormData = false,
        files?: File[]): Promise<AxiosResponse<T>> {
        if (isFormData) {
            const formData = this.mountFormData(payload, files);
            return await trackPromise(Api.post<T>(`${this.path}${id}/${route}/`, formData,
                this.getOptions(null, true)));
        } else {
            return await trackPromise(Api.post<T>(`${this.path}${id}/${route}/`, payload, this.getOptions()));
        }
    }


    async saveFromRoute(
        payload: Dictionary,
        route: string,
        isFormData = false, files?: File[]): Promise<AxiosResponse<T>> {
        if (isFormData) {
            const formData = this.mountFormData(payload, files);
            return await trackPromise(Api.post<T>(`${this.path}${route}/`, formData,
                this.getOptions(null, true)));
        } else {
            return await trackPromise(Api.post<T>(`${this.path}${route}/`, payload, this.getOptions()));
        }
    }

    async update(payload: Dictionary, id: number): Promise<AxiosResponse<T>> {
        return await trackPromise(Api.patch<T>(`${this.path}${id}/`, payload, this.getOptions()));
    }

    async patch(payload: Dictionary, id: number): Promise<AxiosResponse<T>> {
        return await trackPromise(Api.patch<T>(`${this.path}${id}/`, payload, this.getOptions()));
    }

    async getAllPaginate(): Promise<AxiosResponse<PaginatedResult<T>>> {
        return await trackPromise(Api.get(this.path, this.getOptions()));
    }

    async getHistory(idHistory: number): Promise<AxiosResponse<HistoryResult[]>> {
        return await trackPromise(Api.get(`${this.path}${idHistory}/history/`, this.getOptions()));
    }

    async changePassword(payload: Dictionary, id: number, newPassword: string, route: string):
        Promise<AxiosResponse<T>> {
        this.clearParameters();
        payload.new_password = newPassword;
        return await trackPromise(Api.patch<T>(`${this.path}${route}/`, payload, this.getOptions()));
    }

    async getAll(): Promise<AxiosResponse<T[]>> {
        return await trackPromise(Api.get(this.path, this.getOptions()));
    }

    async getById(id: string | number): Promise<AxiosResponse<T>> {
        return await trackPromise(Api.get<T>(`${this.path}${id}/`, this.getOptions()).catch(ex => ex.response));
    }

    async loadUrl(url: string): Promise<AxiosResponse<T>> {
        return await trackPromise(Api.get<T>(url, this.getOptions()).catch(ex => ex.response));
    }

    async getAllFromListRoute(route: string): Promise<AxiosResponse<T[]>> {
        return await trackPromise(Api.get(`${this.path}${route}/`, this.getOptions()));
    }

    async getFromListRoute(route: string): Promise<AxiosResponse<T>> {
        return await trackPromise(Api.get(`${this.path}${route}/`, this.getOptions()));
    }

    async getFromRoutePatch(route: string, payload: Dictionary): Promise<AxiosResponse<T>> {
        return await trackPromise(Api.patch(`${this.path}${route}/`, payload, this.getOptions()));
    }

    async getFromRoutePost(route: string, payload: Dictionary): Promise<AxiosResponse<T>> {
        return await trackPromise(Api.post(`${this.path}${route}/`, payload, this.getOptions()));
    }

    async delete(id: string | number): Promise<AxiosResponse<void>> {
        return await trackPromise(Api.delete<void>(`${this.path}${id}/`, this.getOptions()));
    }

    async getChoices(field: string, options?: Dictionary, isFormData = false): Promise<AxiosResponse<T>> {
        const mergedOptions = Object.assign({},
            this.getOptions(undefined, isFormData), options);
        const response = await trackPromise(Api.options<any>(this.path, mergedOptions));
        const actions = response.data?.actions?.POST;

        if (actions && actions[field] && actions[field].choices) {
            return actions[field].choices;
        } else {
            throw new Error(`Invalid response structure for field: ${field}`);
        }
    }

    // async saveFromListRoute(aObject: T | null, route: string, isFormData =
    // false, files?: File[]): Promise<AxiosResponse<T>> {
    //     if (isFormData) {
    //         const formData = this.mountFormData(aObject, files);
    //         return await trackPromise(Api.post<T>(`${this.path}/${route}`, formData, this.getOptions(null, true)));
    //     } else {
    //         return await trackPromise(Api.post<T>(`${this.path}/${route}`, aObject, this.getOptions()));
    //     }
    // }

    // async updateFromDetailRoute(payload: Dictionary, id: number, route: string, isFormData =
    // false, files?: File[]): Promise<AxiosResponse<T>> {
    //     if (isFormData) {
    //         const formData = this.mountFormData(payload, files);
    //         return await trackPromise(Api.put<T>(`${this.path}/${id}/${route}`, formData,
    //         this.getOptions(null, true)));
    //     } else {
    //         return await trackPromise(Api.put<T>(`${this.path}/${id}/${route}`, payload, this.getOptions()));
    //     }
    // }

    async getFromDetailRoute(id: string | number, route: string): Promise<AxiosResponse<T>> {
        return await trackPromise(Api.get<T>(`${this.path}${id}/${route}/`, this.getOptions()));
    }

    // async uploadFromDetailRoute(id: number | string, route: string, files: File[]): Promise<AxiosResponse<T>> {
    //     const formData = new FormData();
    //     // add files
    //     Array.from(files).forEach(file => formData.append(file.name, file));
    //     return await trackPromise(Api.put<T>(`${this.path}/${id}/${route}`, formData, this.getOptions(null, true)));
    // }

    async uploadFile(route: string, files: File[]): Promise<AxiosResponse<T>> {
        const formData = new FormData();
        Array.from(files).forEach(file => {
            formData.append("file_name", file.name);
            formData.append("file", file);
        });
        return await trackPromise(Api.post<T>(`${this.path}${route}/`, formData, this.getOptions(null, true)));
    }

    // async loadFileFromDetailRoute(id: string | number, route: string): Promise<AxiosResponse<Blob>> {
    //     return await trackPromise(Api.get<Blob>(`${this.path}/${id}/${route}`, this.getOptions("blob")));
    // }

    // async loadFile(route: string): Promise<AxiosResponse<Blob>> {
    //     return await trackPromise(Api.get<Blob>(`${this.path}/${route}`, this.getOptions("blob")));
    // }

    // async getFromListRoute(route: string): Promise<AxiosResponse<T>> {
    //     return await trackPromise(Api.get(`${this.path}/${route}`, this.getOptions()));
    // }

    // async getPaginatedFromListRoute(route: string): Promise<AxiosResponse<PaginatedResult<T>>> {
    //     return await trackPromise(Api.get(`${this.path}/${route}`, this.getOptions()));
    // }

}
