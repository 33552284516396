export class PaginatedResult<T> {
    count: number | undefined;
    next: string | undefined;
    previous: string | undefined;
    results: T[];

    constructor() {
        this.results = [];
    }
}
