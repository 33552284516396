import React, {useEffect} from "react";
import {Field, Form, FormikProvider, useFormik} from "formik";
import Dialog from "@mui/material/Dialog";
import {translate} from "../../../../translate/translate";
import {Checkbox, DialogActions, DialogTitle, Grid, InputAdornment, TextField, Typography} from "@mui/material";
import {Close, GridOff} from "@mui/icons-material";
import {URLS} from "../../../../services/app-urls";
import {BaseService} from "../../../../services/base-service";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import AutocompletePaginated from "../../../../components/autocomplete/autocompletePaginated";
import {WorkerPlanning} from "../../../../models/basic/worker-planning";
import {ReasonReplanning} from "../../../../models/basic/reason-replanning";
import {toast} from "react-toastify";
import {DailyPlanning} from "../../../../models/basic/daily-planning";
import {ErrorComponent} from "../../../../components/error/error.component";
import {Shift} from "../../../../models/basic/shift";


const DialogInterruptDemandComponent = (props: any) => {
    const {
        version,
        open,
        onClose,
        dailyPlanning
    } = props;
    const service = new BaseService<WorkerPlanning>(URLS.PLANNING);
    const serviceDailyPlanning = new BaseService<DailyPlanning>(URLS.DAILY_PLANNING);
    const serviceReasonReplanning = new BaseService<ReasonReplanning>(URLS.REASON_REPLANNING);
    const [reasonReplanning, setReasonReplanning] = React.useState<ReasonReplanning | null>(null);
    const [shift, setShift] = React.useState<Shift | null>(null);
    const [typeInterruption, setTypeInterruption] = React.useState(false);

    useEffect(() => {
        getDailyPlanning();
    }, [dailyPlanning, version]);

    const getDailyPlanning = () => {
        serviceDailyPlanning.clearParameters();
        serviceDailyPlanning.addParameter("worker_planning", dailyPlanning?.worker_planning_id);
        serviceDailyPlanning.addParameter("expand", "shift");
        serviceDailyPlanning.getAll().then(response => {
            setShift(response?.data[0].shift.id);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const save = (values: any) => {
        const payload = {
            "quantity": values.quantity,
            "worker_planning_id": dailyPlanning?.worker_planning_id,
            "shift_id": shift,
            "diary_planning_date": dailyPlanning?.daily_planning_date,
            "notes": values.notes,
            "reason": reasonReplanning?.url,
            "version": version?.url,
            "type_interruption": typeInterruption,
        };
        service.clearParameters();
        service.postFromListRoute(payload, "split_worker_order").then(() => {
            toast.success(translate.t("successfully_updated"));
            handleClose(true);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handleClose = (result: boolean) => {
        onClose(result);
    };

    const handleChangeReasonReplanning = (event: any) => {
        setReasonReplanning(event);
    };

    const formik = useFormik({
        initialValues: WorkerPlanning,
        validationSchema: Yup.object().shape({
            quantity: Yup.number().required().positive().integer()
        }),
        validateOnChange: true,
        enableReinitialize: true,
        onSubmit: (values: any, {setSubmitting}) => {
            setTimeout(() => {
                setSubmitting(false);
            }, 500);
        }
    });

    return (
        <div>
            <Dialog open={open} onClose={() => handleClose(false)} fullWidth={true} maxWidth={"sm"}>
                <DialogTitle sx={{backgroundColor: "primary.main", padding: "16px"}}>
                    <Grid container direction="row">
                        <Grid item xs>
                            <Typography color="#FFFFFF" fontWeight="bold" fontSize="16px" display="flex"
                                alignItems="center">
                                <GridOff fontSize="large" sx={{paddingRight: "5px"}}/>
                                {translate.t("interrupt_demand")}
                            </Typography>
                        </Grid>
                        <Grid sx={{cursor: "pointer", color: "#ffffff"}} alignItems="center" display="flex">
                            <Close onClick={() => handleClose(false)}/>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogTitle sx={{backgroundColor: "#F5F5F5"}}>
                    <Grid container direction="column" spacing={1}>
                        <Grid container item direction="row">
                            <Grid container item direction="row" xs spacing={1}>
                                <Grid item>
                                    <Typography sx={{color: "#45485F", fontWeight: "bold", fontSize: "14px"}}>
                                        {translate.t("model")}:
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography sx={{color: "#45485F", fontSize: "14px"}}>
                                        {dailyPlanning?.model}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" xs spacing={1}>
                                <Grid item>
                                    <Typography sx={{color: "#45485F", fontWeight: "bold", fontSize: "14px"}}>
                                        {translate.t("product")}:
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography sx={{color: "#45485F", fontSize: "14px"}}>
                                        {dailyPlanning?.product_description}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" xs spacing={1}>
                                <Grid item>
                                    <Typography sx={{color: "#45485F", fontWeight: "bold", fontSize: "14px"}}>
                                        {translate.t("balance")}:
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <Typography sx={{color: "#45485F", fontSize: "14px"}}>
                                        {dailyPlanning?.worker_planning_quantity}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent dividers tabIndex={0}>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off">
                            <Grid container paddingTop={2} direction="column" spacing={2}>
                                <Grid container item direction="row" spacing={2}>
                                    <Grid item xs>
                                        <Field
                                            as={TextField}
                                            name="quantity"
                                            label={translate.t("quantity")}
                                            variant={"outlined"}
                                            fullWidth
                                            required={true}
                                            autoFocus={true}
                                            error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                                            values={formik.values.quantity}
                                            onChange={formik.handleChange}
                                            onInput={(e: any) => {
                                                e.target.value = e.target.value.replace(/[^0-9]/g, "");

                                                const enteredQuantity = parseInt(e.target.value, 10);
                                                const maxQuantity = dailyPlanning?.worker_planning_quantity;

                                                if (enteredQuantity > maxQuantity) {
                                                    e.target.value = maxQuantity.toString();
                                                }
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {formik.touched.quantity && Boolean(formik.errors.quantity) && (
                                                            <div className={"required-field"}>
                                                                {translate.t("required_field")}
                                                            </div>
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <AutocompletePaginated
                                            display="name"
                                            searchField="name"
                                            label="reason"
                                            service={serviceReasonReplanning}
                                            filters={[{"active": true}]}
                                            onSelectElement={handleChangeReasonReplanning}
                                            required={true}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid item xs>
                                    <Field
                                        as={TextField}
                                        name="notes"
                                        label={translate.t("notes")}
                                        variant={"outlined"}
                                        fullWidth
                                        autoFocus={false}
                                        required={false}
                                        value={formik.values.notes}
                                        multiline
                                        rows={4}
                                        InputProps={{
                                            maxLength: 150,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Field
                                        as={Checkbox}
                                        name="type"
                                        checked={typeInterruption}
                                        onChange={() => setTypeInterruption(!typeInterruption)}
                                    />
                                    <label>{translate.t("anticipate_planning")}</label>
                                </Grid>
                            </Grid>
                        </Form>
                    </FormikProvider>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="button-options"
                        color="secondary"
                        onClick={() => handleClose(false)}
                        variant="outlined">
                        {translate.t("cancel")}
                    </Button>
                    <Button
                        className="button-options"
                        color="success"
                        onClick={() => save(formik.values)}
                        disabled={!formik.isValid || formik.isValidating || !reasonReplanning}
                        variant="contained">
                        {translate.t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DialogInterruptDemandComponent;
