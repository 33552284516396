import BaseModel from "../default/base-model";

export class ScheduledStop extends BaseModel {
    id: number;
    name: string;
    duration: any;
    type: string;
    display_name: string;
    active: boolean;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;

    constructor() {
        super();
        this.id = 0;
        this.name = "";
        this.duration = "";
        this.type = "";
        this.display_name = "";
        this.active = true;
        this.monday = false;
        this.tuesday = false;
        this.wednesday = false;
        this.thursday = false;
        this.friday = false;
        this.saturday = false;
        this.sunday = false;
    }
}
