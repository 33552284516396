import * as React from "react";
import {useEffect} from "react";
import {translate} from "../../../translate/translate";
import {Box, Button, Fab, FormControl, Grid, InputLabel, MenuItem, Paper, TextField, Typography} from "@mui/material";
import TablePaginationComponent from "../../../components/table/table-pagination.component";
import {BaseService} from "../../../services/base-service";
import {Add, Search} from "@mui/icons-material";
import {URLS} from "../../../services/app-urls";
import {NavLink, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {PaginatedResult} from "../../../models/default/paginated-result";
import TableActionComponent from "../../../components/table/table-action.component";
import {Field, Form, Formik} from "formik";
import HistoryComponent from "../../../components/history/history.component";
import Select from "@mui/material/Select";
import {ReasonReplanning} from "../../../models/basic/reason-replanning";
import {ErrorComponent} from "../../../components/error/error.component";


const ReasonReplanningComponent = () => {
    const service = new BaseService<ReasonReplanning>(URLS.REASON_REPLANNING);
    const url = "/registration/reason_replanning/";
    const navigate = useNavigate();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);
    const [openHistory, setOpenHistory] = React.useState(false);
    const [idHistory, setIdHistory] = React.useState(0);
    const [searchName, setSearchName] = React.useState<string>("");
    const [searchStatus, setSearchStatus] = React.useState<string>("");
    const [pagination, setPagination] =
        React.useState<PaginatedResult<ReasonReplanning>>();
    const [dataSource, setDataSource] =
        React.useState<ReasonReplanning[]>([]);

    const handleOpenDialogHistory = (id: number) => {
        setIdHistory(id);
        setOpenHistory(true);
    };

    const handleCloseDialogHistory = () => {
        setOpenHistory(false);
    };

    const search = (restartIndex = false) => {
        if (searchName || searchStatus) {
            service.clearParameters();
            service.addParameter("name", searchName);
            service.addParameter("active", searchStatus);
            service.addParameter("limit", rowsPerPage);
            service.addParameter("offset", (page * rowsPerPage));
            service.getAllPaginate().then(response => {
                setPagination(response.data);
            }).catch((error: any) => {
                ErrorComponent(error);
            });
        } else {
            service.clearParameters();
            service.addParameter("limit", rowsPerPage);
            service.addParameter("offset", (page * rowsPerPage));
            service.getAllPaginate().then(response => {
                setPagination(response.data);
            }).catch((error: any) => {
                ErrorComponent(error);
            });
        }
        restartIndex && setPage(0);
    };

    useEffect(() => {
        setDataSource(pagination?.results ?? []);
    }, [pagination]);

    useEffect(() => {
        search();
    }, [page, rowsPerPage]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDoubleClick = (row: ReasonReplanning) => {
        navigate(`${url}${row.id}`);
    };

    const handleDelete = (row: ReasonReplanning) => {
        service.delete(Number(row.id)).then(() => {
            toast.success(translate.t("successfully_deleted"));
            search();
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handleSwitch = (row: ReasonReplanning, column: keyof ReasonReplanning) => {
        const updatedRow = {...row};
        (updatedRow[column] as boolean) = !(updatedRow[column] as boolean);
        const payload = {"active": updatedRow.active, "default": updatedRow.default};
        service.patch(payload, Number(row.id)).then(() => {
            toast.success(translate.t("successfully_updated"));
            search();
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    return (
        <section>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("reason_replanning")}
                </Typography>
            </div>
            <div className="floating-button">
                <Button
                    component={NavLink}
                    to={url + "create"}
                    startIcon={<Add/>}
                    variant="contained"
                    color="secondary"
                    className={"button-new"}>
                    {translate.t("new")}
                </Button>
            </div>

            <Grid item xs={12}>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{name: ""}}
                            onSubmit={(values, {setSubmitting}) => {
                                setTimeout(() => {
                                    search(true);
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {() => (
                                <Form autoComplete="off">
                                    <Grid alignItems="center" container spacing={2} direction={"row"}>
                                        <Grid item xs>
                                            <Field
                                                as={TextField}
                                                name="name"
                                                label={translate.t("name")}
                                                fullWidth
                                                variant={"outlined"}
                                                autoFocus={true}
                                                value={searchName}
                                                onChange={(event: React.ChangeEvent<{
                                                    value: unknown
                                                }>) => setSearchName(event.target.value as string)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={2}>
                                            <FormControl fullWidth>
                                                <InputLabel className={"formControl"} htmlFor="status">
                                                    {translate.t("status")}
                                                </InputLabel>
                                                <Field
                                                    variant={"outlined"}
                                                    label={translate.t("status")}
                                                    defaultValue=""
                                                    name="status"
                                                    component={Select}
                                                    onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                                                        setSearchStatus(event.target.value as string)}>
                                                    <MenuItem value="">-</MenuItem>
                                                    <MenuItem value="true">
                                                        {translate.t("is_active")}</MenuItem>
                                                    <MenuItem value="false">
                                                        {translate.t("inactive")}</MenuItem>
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <Fab
                                                size="small"
                                                type="submit"
                                                color="secondary">
                                                <Search/>
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>

            <Paper variant="outlined" sx={{marginTop: "16px"}}>
                <TablePaginationComponent
                    state={dataSource}
                    displayedColumnsShow={["#", "name", "default", "Status", "action"]}
                    displayedColumns={["id", "name", "default", "active", "action"]}
                    handleDelete={handleDelete}
                    handleSwitch={handleSwitch}
                    url={url}
                    handleDoubleClick={handleDoubleClick}
                    count={pagination?.count}
                    next={pagination?.next}
                    previous={pagination?.previous}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    actionsComponent={TableActionComponent}
                    handleOpenDialogHistory={handleOpenDialogHistory}
                    columnStyles={{1: "20%", 2: "20%", 3: "20%", 4: "20%"}}/>
            </Paper>
            <HistoryComponent
                openHistory={openHistory}
                handleCloseDialogHistory={handleCloseDialogHistory}
                idHistory={idHistory}
                columns_history={["name", "default", "active"]}
                service={service}
            />
        </section>
    );
};

export default ReasonReplanningComponent;
