import * as React from "react";
import {useEffect, useState} from "react";
import {translate} from "../../../translate/translate";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {Demand} from "../../../models/basic/demand";
import {Box, Fab, Grid, Paper, TextField, Typography} from "@mui/material";
import {Search} from "@mui/icons-material";
import {Field, Form, Formik} from "formik";
import {DateNowEnUS} from "../../../components/date/date-timeFormat";
import {ErrorComponent} from "../../../components/error/error.component";
import {toast} from "react-toastify";
import * as echarts from "echarts/core";
import {GridComponent, TitleComponent, TooltipComponent} from "echarts/components";
import {BarChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";

import EChartsReact, {useChart} from "echarts-for-react-fc";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

echarts.use([GridComponent, BarChart, CanvasRenderer, TooltipComponent, TitleComponent]);


const DemandUsedHoursReportComponent = () => {
    const service = new BaseService<Demand>(URLS.DEMAND);

    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [showChart, setShowChart] = useState(false);
    const [chartData, setChartData] = useState<any>(undefined);
    const {chartRef, setChartOption, handleListenChartReady} = useChart();

    const getChartOptions = () => {

        return {
            title: {
                text: translate.t("demand")
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow"
                },
            },
            grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true
            },
            xAxis: {
                type: "value",
                axisLabel: {
                    formatter: "{value}h"
                }
            },
            yAxis: {
                type: "category",
                data: chartData.categories,
                axisLabel: {
                    formatter: function (value: any) {
                        return "{titleStyle|" + translate.t("model") + ":} " + value;
                    },
                    rich: {
                        titleStyle: {
                            color: "#000",
                            fontWeight: "bold"
                        },
                    }
                }
            },
            series: [
                {
                    type: "bar",
                    label: {
                        show: true,
                        position: "inside",
                        formatter: "{c}h"
                    },
                    itemStyle: {
                        color: function (param: any) {
                            return chartData.colors[param.dataIndex];
                        },
                    },
                    data: chartData.data
                },
            ]
        };
    };

    const handleStartDateChange = (event: any) => {
        setStartDate(event);
        setShowChart(false);
    };

    const handleEndDateChange = (event: any) => {
        setEndDate(event);
        setShowChart(false);
    };

    useEffect(() => {
        setStartDate(dayjs(DateNowEnUS()));
        const end = new Date();
        end.setDate(end.getDate() + 14);
        setEndDate(dayjs(DateNowEnUS(end)));
    }, []);

    useEffect(() => {
        if (chartData) {
            const options: any = getChartOptions();
            setChartOption(options);
            setShowChart(true);
        }
    }, [chartData]);

    const search = () => {
        service.clearParameters();
        service.addParameter("start_date", dayjs(startDate).format("YYYY-MM-DD"));
        service.addParameter("end_date", dayjs(endDate).format("YYYY-MM-DD"));
        service.getFromListRoute("get_used_hours_report")
            .then((response) => {
                if (response.data) {
                    setChartData(response.data);
                } else {
                    toast.warning(translate.t("no_records_found"));
                }
            })
            .catch((error: any) => {
                ErrorComponent(error);
            });
    };

    return (
        <Box sx={{padding: "10px"}}>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("demand_used_hours")}
                </Typography>
            </div>
            <Grid item xs={12}>
                <Paper variant="outlined">
                    <Box p={2}>
                        <Formik
                            enableReinitialize={true}
                            validateOnMount={true}
                            initialValues={{name: ""}}
                            onSubmit={(values: any, {setSubmitting}) => {
                                setTimeout(() => {
                                    search();
                                    setSubmitting(false);
                                }, 500);
                            }}
                        >
                            {({values, errors, touched}) => (
                                <Form autoComplete="off">
                                    <Grid alignItems="center" container spacing={2} direction={"row"}>
                                        <Grid item xs>
                                            <DatePicker
                                                format="DD/MM/YYYY"
                                                maxDate={endDate}
                                                onChange={handleStartDateChange}
                                                slotProps={{
                                                    textField: {
                                                        name: "start_date",
                                                        fullWidth: true,
                                                        label: translate.t("start_date"),
                                                        value: startDate,
                                                        error: Boolean(errors.start_date),
                                                        InputLabelProps: {
                                                            shrink: true
                                                        },
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <DatePicker
                                                format="DD/MM/YYYY"
                                                minDate={startDate}
                                                onChange={handleEndDateChange}
                                                slotProps={{
                                                    textField: {
                                                        name: "end_date",
                                                        fullWidth: true,
                                                        label: translate.t("end_date"),
                                                        value: endDate,
                                                        error: Boolean(errors.end_date),
                                                        InputLabelProps: {
                                                            shrink: true
                                                        },
                                                    },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={0.6}>
                                            <Fab
                                                size="small"
                                                type="submit"
                                                color="secondary">
                                                <Search/>
                                            </Fab>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                </Paper>
            </Grid>
            {showChart && (<Paper variant="outlined" sx={{marginTop: "10px"}}>
                <EChartsReact
                    style={{
                        height: 300,
                    }}
                    ref={chartRef}
                    echarts={echarts}
                    onChartReady={handleListenChartReady}
                />
            </Paper>)}
        </Box>
    );
};

export default DemandUsedHoursReportComponent;
