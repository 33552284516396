import * as React from "react";
import {useEffect, useState} from "react";
import {Grid, Paper, Typography} from "@mui/material";
import EChartsReact, {useChart} from "echarts-for-react-fc";
import * as echarts from "echarts/core";
import {DataZoomComponent, GridComponent, LegendComponent, TitleComponent, ToolboxComponent, TooltipComponent} from "echarts/components";
import {BarChart} from "echarts/charts";
import {CanvasRenderer} from "echarts/renderers";
import {BaseService} from "../../../services/base-service";
import {URLS} from "../../../services/app-urls";
import {ErrorComponent} from "../../../components/error/error.component";
import {translate} from "../../../translate/translate";
import Loading from "../../../components/loading/loading";
import {ActiveLineShift} from "../../../models/basic/active-line-shift";

echarts.use([GridComponent, BarChart, CanvasRenderer, TitleComponent, TooltipComponent, LegendComponent, ToolboxComponent, DataZoomComponent]);

const initUpphOption: any = {
    tooltip: {
        trigger: "axis",
        axisPointer: {
            type: "shadow"
        }
    },
    toolbox: {
        feature: {
            saveAsImage: {show: true}
        }
    },
    legend: {
        data: []
    },
    xAxis: {
        type: "category",
        data: [],
        axisLabel: {
            rotate: 45,
            interval: 0
        }
    },
    yAxis: {
        type: "value",
        name: "",
        axisLabel: {
            formatter: "{value}"
        }
    },
    series: []
};

const DashboardUpphComponent = () => {
    const service = new BaseService<ActiveLineShift>(URLS.ACTIVE_LINE_SHIFT);
    const [showBufferChart, setShowBufferChart] = useState<boolean>(false);
    const {chartRef: bufferChartRef, setChartOption: setBufferChartOption, handleListenChartReady: handleBufferChartReady} = useChart();
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        getData();
    }, []);

    const setBufferChartValues = (data: any[]) => {
        const option = JSON.parse(JSON.stringify(initUpphOption));
        const months = data.map(item => item.month);
        const controls = data.map(item => item.value);
        const targets = data.map(item => item.target);

        option.xAxis.data = months;

        option.series = [
            {
                name: "CONTROL",
                type: "bar",
                stack: "total",
                data: controls,
                itemStyle: {
                    color: "#8faadc",
                }
            },
            {
                name: "TARGET",
                type: "line",
                data: targets,
                symbol: "none",
                lineStyle: {
                    color: "#002060"
                },
            }
        ];

        option.tooltip = {
            trigger: "axis",
            axisPointer: {
                type: "shadow"
            },
            formatter: (params: any) => {
                const tooltipContent = params
                    .map((item: any) => `
                    <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${item.color};"></span>
                    <span style="display:inline-block;text-align:left; padding-right: 16px;">${item.seriesName}</span>
                    <strong style="float:right;">${item.value}</strong>`
                    ).join("<br/>");
                return `<div>${tooltipContent}</div>`;
            }
        };

        option.legend = {
            show: true,
        };

        setBufferChartOption(option, true);
        setShowBufferChart(true);
    };

    const getData = () => {
        setLoading(true);
        service.getFromListRoute("get_upph_data")
            .then((response: any) => {
                setLoading(false);
                setBufferChartValues(response.data);
            })
            .catch((error: any) => {
                setLoading(false);
                ErrorComponent(error);
            });
    };

    return (
        <Grid>
            <div className="floating-title" style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Typography variant={"h5"}>
                    {translate.t("upph")}
                </Typography>
            </div>
            <Loading open={loading}/>
            {showBufferChart && (
                <Grid container direction={"column"} gap={0} marginTop={1}>
                    <Grid item xs>
                        <Paper id={"chart-buffer"} sx={{padding: "16px"}}>
                            <EChartsReact
                                autoResize={true}
                                style={{width: "100%", height: 500}}
                                ref={bufferChartRef}
                                echarts={echarts}
                                onChartReady={handleBufferChartReady}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default DashboardUpphComponent;
