import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogContent, FormControl,
    Grid, InputLabel,
    ListItemText, MenuItem, Select,
    TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {translate} from "../../../../translate/translate";
import {Check, Close, PriorityHigh} from "@mui/icons-material";
import {Field, Form, Formik, FormikHelpers} from "formik";
import * as React from "react";
import {useEffect, useState} from "react";
import {CalendarEventType} from "../../../../models/basic/calendar-event-type";
import {ProductionLine} from "../../../../models/basic/production-line";
import {toast} from "react-toastify";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {enUS, ptBR} from "date-fns/locale";
import {CalendarEvent, CalendarEventSave} from "../../../../models/basic/calendar-event";
import {ErrorComponent} from "../../../../components/error/error.component";
import {ConvertDateToString} from "../../../../components/date/date-timeFormat";
import moment from "moment";
import * as Yup from "yup";

const DialogCalendarComponent = (props: any) => {
    const {
        openDialog,
        setOpenDialog,
        typeOperation,
        setTypeOperation,
        calendarEvent,
        setCalendarEvent,
        eventStart,
        searchCalendarEvent,
    } = props;
    const locale = translate.language == "pt" ? ptBR : enUS;
    const serviceCalendarEventType = new BaseService<CalendarEventType>(URLS.CALENDAR_EVENT_TYPE);
    const serviceCalendarEventSave = new BaseService<CalendarEventSave>(URLS.CALENDAR_EVENT);
    const serviceProductionLine = new BaseService<ProductionLine>(URLS.PRODUCTION_LINE);
    const [buttonValue, setButtonValue] = useState("");
    const [eventType, setEventType] = useState(false);
    const [eventTypeValue, setEventTypeValue] = useState<CalendarEventType | null>(null);
    const [listEventType, setListEventType] = useState<CalendarEventType[]>([]);
    const [selectedProductionLines, setSelectedProductionLines] = useState<ProductionLine[]>([]);
    const [listProductionLines, setListProductionLines] = useState<ProductionLine[]>([]);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [startTime, setStartTime] = useState<string>("");
    const [endTime, setEndTime] = useState<string>("");
    const [checked, setChecked] = useState<boolean>(false);
    const [validEndDate, setValidEndDate] = useState<boolean>(true);
    const [validStartTime, setValidStartTime] = useState<boolean>(false);
    const [validEndTime, setValidEndTime] = useState<boolean>(false);
    const [validTimes, setValidTimes] = useState<boolean>(true);
    const [existsPlanning, setExistsPlanning] = useState<boolean>(false);

    const schema = Yup.object().shape({
        description: Yup.string().required(translate.t("required_field")),
    });

    useEffect(() => {
        searchEventType();
        searchProductionLine();
        setEventType(false);
    }, []);

    useEffect(() => {
        if (calendarEvent.id) {
            setEventTypeValue(listEventType
                .find((eventType) =>
                    eventType.id == calendarEvent.calendar_event_type.id
                ) ?? null
            );
            setSelectedProductionLines(() => listProductionLines
                .filter(line => calendarEvent.production_lines
                    .some((currentline: ProductionLine) =>
                        currentline.id == line.id
                    )));
            setStartDate(moment(calendarEvent.start_date).toDate());
            setEndDate(moment(calendarEvent.end_date).toDate());
            setStartTime(calendarEvent.start_time);
            setEndTime(calendarEvent.end_time);
            if (calendarEvent.start_time && calendarEvent.end_time) {
                setChecked(true);
                setStartTime(calendarEvent.start_time);
                setEndTime(calendarEvent.end_time);
            } else {
                setChecked(false);
                setStartTime("");
                setEndTime("");
            }
        }
    }, [calendarEvent]);

    useEffect(() => {
        if (!checked) {
            setStartTime("");
            setEndTime("");
        }
    }, [checked]);

    useEffect(() => {
        if (buttonValue == "cancel") {
            setOpenDialog(false);
        }
    }, [buttonValue]);

    useEffect(() => {
        if (!openDialog) {
            closeDialog();
        } else {
            if (typeOperation == "add") {
                setStartDate(eventStart);
                setEndDate(eventStart);
            }
        }
    }, [openDialog]);

    const closeDialog = () => {
        setButtonValue("");
        setTypeOperation("");
        setStartDate(null);
        setEndDate(null);
        setStartTime("");
        setEndTime("");
        setEventTypeValue(null);
        setSelectedProductionLines(listProductionLines);
        setCalendarEvent(new CalendarEvent());
    };

    const searchEventType = () => {
        serviceCalendarEventType.clearParameters();
        serviceCalendarEventType.getAll().then(response => {
            setListEventType(response.data);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const searchProductionLine = () => {
        serviceProductionLine.clearParameters();
        serviceProductionLine.getAll().then(response => {
            const { data: productionLines } = response;
            setListProductionLines(productionLines);
            setSelectedProductionLines(productionLines);
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handleChangeProductionLine = (event: any, productionLine: ProductionLine) => {
        let newStateLines = [];
        const { checked: isChecked } = event.target;

        if (isChecked) {
            newStateLines.push(...selectedProductionLines);
            newStateLines.push(productionLine);
        } else {
            newStateLines = selectedProductionLines
                .filter(line =>
                    line.id !== productionLine.id
                );
        }
        setSelectedProductionLines(newStateLines);
    };

    const defaultPropsProduct = {
        options: listEventType,
        getOptionLabel: (option: CalendarEventType) => option.description,
    };

    const handleProductAfterFocus = () => {
        setEventType(true);
    };

    const handleStartDateChange = (date: any) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date: any) => {
        if (date == "Invalid Date") {
            setValidEndDate(false);
        } else if (!date) {
            setValidEndDate(false);
        } else {
            setValidEndDate(true);
        }
        setEndDate(date);
    };

    useEffect(() => {
        if (endDate != null && startDate != null) {
            if (endDate < startDate) {
                setValidEndDate(false);
            } else {
                setValidEndDate(true);
            }
        }
    }, [endDate]);

    useEffect(() => {
        if (checked && (!startTime || startTime.length != 8)) {
            setValidStartTime(false);
        } else {
            setValidStartTime(true);
        }

        if (checked && (!endTime || endTime.length != 8)) {
            setValidEndTime(false);
        } else {
            setValidEndTime(true);
        }
    }, [startTime, endTime, checked]);

    useEffect(() => {
        if (checked && validEndDate && validStartTime && validEndTime && startDate != null && endDate != null) {
            const [hrStart, minStart, secStart] = startTime.split(":").map(Number);
            const [hrEnd, minEnd, secEnd] = endTime.split(":").map(Number);

            const dateStart = new Date(startDate);
            const dateEnd = new Date(endDate);

            dateStart.setHours(hrStart);
            dateEnd.setHours(hrEnd);
            dateStart.setMinutes(minStart);
            dateEnd.setMinutes(minEnd);
            dateStart.setSeconds(secStart);
            dateEnd.setSeconds(secEnd);

            if (dateStart >= dateEnd) {
                setValidTimes(false);
            } else {
                setValidTimes(true);
            }
        }
    }, [validEndDate, validStartTime, validEndTime, startTime, endTime, endDate, checked]);

    const handleChange = (event: any) => {
        const isChecked = event.target.checked;
        setChecked(isChecked);
    };

    const handleDelete = () => {
        serviceCalendarEventSave.delete(Number(calendarEvent.id)).then(() => {
            toast.success(translate.t("successfully_deleted"));
            setOpenDialog(false);
            searchCalendarEvent();
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    useEffect(() => {
        if (!(eventTypeValue && !eventTypeValue?.is_working_day)) {
            setChecked(false);
        }
    }, [eventTypeValue]);

    const onSubmit = (values: CalendarEvent, {resetForm}: FormikHelpers<CalendarEvent>) => {
        setTimeout(() => {
            const calendarEventSave = new CalendarEventSave();
            if (buttonValue == "save_form") {
                calendarEventSave.description = values.description;
                calendarEventSave.calendar_event_type = eventTypeValue?.url ?? "";
                calendarEventSave.production_lines = selectedProductionLines.map(line => line.url);
                calendarEventSave.start_date = ConvertDateToString(startDate ?? new Date());
                calendarEventSave.end_date = ConvertDateToString(endDate ?? new Date());
                calendarEventSave.start_time = startTime == "" || !checked ? null : startTime;
                calendarEventSave.end_time = endTime == "" || !checked ? null : endTime;
                calendarEventSave.is_replanning = existsPlanning;
                serviceCalendarEventSave.clearParameters();
                if (typeOperation == "add") {
                    serviceCalendarEventSave.save(calendarEventSave)
                        .then(() => {
                            toast.success(translate.t("successfully_registered"));
                            searchCalendarEvent();
                            resetForm();
                            setOpenDialog(false);
                            setExistsPlanning(false);
                        })
                        .catch((error: any) => {
                            error.response && error.response.data.detail === "Exists planning" ?
                                setExistsPlanning(true) : ErrorComponent(error);
                        });
                } else if (typeOperation == "edit") {
                    calendarEventSave.id = calendarEvent.id;
                    serviceCalendarEventSave.patch(calendarEventSave, calendarEventSave.id)
                        .then(() => {
                            toast.success(translate.t("successfully_updated"));
                            searchCalendarEvent();
                            resetForm();
                            setOpenDialog(false);
                            setExistsPlanning(false);
                        }).catch((error: any) => {
                            error.response && error.response.data.detail === "Exists planning" ?
                                setExistsPlanning(true) : ErrorComponent(error);
                        });
                }
                setButtonValue("");
            }
        }, 400);
    };

    return (
        <Dialog
            open={openDialog}
            onClose={() => {
                setOpenDialog(false);
            }}
        >
            <Grid
                padding={2}
                container
                justifyContent="space-between"
                sx={{backgroundColor: "primary.main", minWidth: 480}}
            >
                <Grid item>
                    <Typography sx={{color: "#ffffff", fontWeight: "bold", fontSize: "16px"}}>
                        {translate.t(typeOperation)} {translate.t("title_event")}
                    </Typography>
                </Grid>
                <Grid item sx={{cursor: "pointer", color: "#ffffff"}}>
                    <Close onClick={() => {
                        setOpenDialog(false);
                    }}/>
                </Grid>
            </Grid>
            <DialogContent>
                <Formik
                    enableReinitialize
                    initialValues={calendarEvent}
                    validationSchema={schema}
                    validateOnChange={true}
                    onSubmit={onSubmit}
                >
                    {({isValid, values, isValidating, errors, touched}) => (
                        <Form autoComplete="off">
                            <Grid container spacing={2} direction={"column"}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Field
                                        as={TextField}
                                        name="description"
                                        label={translate.t("description")}
                                        fullWidth
                                        variant={"outlined"}
                                        autoFocus={true}
                                        error={touched.description && Boolean(errors.description)}
                                        required={true}
                                        values={values}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Autocomplete
                                        className={`${!eventTypeValue && eventType ? "not-filled" : ""}`}
                                        disablePortal
                                        id="cb-calendar_event_type"
                                        {...defaultPropsProduct}
                                        fullWidth
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                required={true}
                                                color={!eventTypeValue && eventType ? "error" : "primary"}
                                                label={translate.t("calendar_event_type")}
                                                onFocus={handleProductAfterFocus}
                                                onBlur={handleProductAfterFocus}
                                            />
                                        }
                                        value={eventTypeValue}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={(event: any, newValue: CalendarEventType | null) => {
                                            setEventTypeValue(newValue);
                                        }}
                                        noOptionsText={translate.t("empty_listing")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <FormControl fullWidth>
                                        <InputLabel
                                            id="production-lines-select-label"
                                            className={!selectedProductionLines.length ? "error-required" : ""}
                                            required>
                                            {translate.t("production_line")}
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            multiple
                                            required
                                            label={translate.t("production_line")}
                                            name="production_lines"
                                            variant={"outlined"}
                                            labelId="production-lines-select-label"
                                            autoFocus={false}
                                            error={!selectedProductionLines.length}
                                            renderValue={
                                                (selected: any) => selected.map((obj: any) => obj.name).join(", ")
                                            }
                                            value={selectedProductionLines}>
                                            {listProductionLines.map((productionLine: ProductionLine) => (
                                                <MenuItem key={productionLine.id}>
                                                    <Checkbox
                                                        checked={selectedProductionLines.find(obj => (obj.id === productionLine.id)) !== undefined}
                                                        onChange={(p: any) => handleChangeProductionLine(p, productionLine)}/>
                                                    <ListItemText primary={productionLine.name}/>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} container spacing={2} direction="row">
                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                                            <DatePicker
                                                label={translate.t("start_date")}
                                                value={startDate}
                                                disabled={true}
                                                onChange={handleStartDateChange}
                                                inputRef={(props: any) => (
                                                    <TextField
                                                        {...props}
                                                        required={true}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                                            <DatePicker
                                                label={translate.t("end_date")}
                                                value={endDate}
                                                disabled={typeOperation == "edit"}
                                                onChange={handleEndDateChange}
                                                inputRef={(props: any) => (
                                                    <TextField
                                                        {...props}
                                                        required={typeOperation == "add"}
                                                    />
                                                )}
                                                minDate={startDate}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Checkbox
                                        checked={checked}
                                        onChange={(event) => {
                                            handleChange(event);
                                        }}
                                        disabled={!(eventTypeValue && !eventTypeValue?.is_working_day)}
                                        name="partial"
                                    />
                                    Parcial
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    container
                                    spacing={2}
                                    direction="row"
                                    style={{paddingBottom: 20}}
                                >
                                    {
                                        checked && (
                                            <>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <Field
                                                        as={TextField}
                                                        name="start_time"
                                                        label={translate.t("start_time")}
                                                        variant={"outlined"}
                                                        fullWidth
                                                        autoFocus={false}
                                                        value={startTime}
                                                        onChange={(event: any) => setStartTime(event.target.value as string)}
                                                        type="time"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 1,
                                                            min: "00:00:00",
                                                            max: "23:59:59",
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                                    <Field
                                                        as={TextField}
                                                        name="end_time"
                                                        label={translate.t("end_time")}
                                                        variant={"outlined"}
                                                        fullWidth
                                                        autoFocus={false}
                                                        value={endTime}
                                                        onChange={(event: any) => setEndTime(event.target.value as string)}
                                                        type="time"
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        inputProps={{
                                                            step: 1,
                                                            min: "00:00:00",
                                                            max: "23:59:59",
                                                        }}
                                                        helperText={
                                                            !validTimes ?
                                                                translate.t("validate_end_time") :
                                                                ""
                                                        }
                                                        FormHelperTextProps={{
                                                            style: {
                                                                color: "red",
                                                            },
                                                        }}
                                                    />
                                                </Grid>
                                            </>
                                        )
                                    }
                                </Grid>
                                {existsPlanning && (
                                    <Grid item xs style={{paddingTop: 0}}>
                                        <Typography color="red" fontSize="16px" display="flex"
                                            alignItems="center">
                                            <PriorityHigh fontSize="large" sx={{paddingRight: "5px"}}/>
                                            {translate.t("exists_planning")}
                                        </Typography>
                                    </Grid>
                                )}
                                <Grid spacing={1} item justifyContent={"flex-end"} container direction={"row"}>
                                    <Grid item>
                                        <Button
                                            color="secondary"
                                            variant="outlined"
                                            value="cancel"
                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                setButtonValue(event.currentTarget.value);
                                            }}
                                        >
                                            {translate.t("cancel")}
                                        </Button>
                                    </Grid>
                                    {typeOperation == "edit" && (
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                onClick={() => {
                                                    handleDelete();
                                                }}
                                            >
                                                {translate.t("delete")}
                                            </Button>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Button
                                            startIcon={<Check/>}
                                            color="success"
                                            variant="contained"
                                            value="save_form"
                                            name="save_form"
                                            type="submit"
                                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                setButtonValue(event.currentTarget.value);
                                            }}
                                            disabled={
                                                !isValid ||
                                                isValidating ||
                                                !validEndDate ||
                                                eventTypeValue == null ||
                                                !selectedProductionLines.length ||
                                                !validStartTime ||
                                                !validEndTime ||
                                                !validTimes
                                            }
                                        >
                                            {translate.t("save_form")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default DialogCalendarComponent;
