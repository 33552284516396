import * as React from "react";
import {useEffect} from "react";
import {Box, Fab, FormControl, Grid, InputLabel, MenuItem, Paper, TableCell} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import {translate} from "../../../../translate/translate";
import {BaseService} from "../../../../services/base-service";
import {URLS} from "../../../../services/app-urls";
import {User} from "../../../../models/account/user";
import AddIcon from "@mui/icons-material/Add";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import {toast} from "react-toastify";
import TableMenuComponent from "./table-menu/table-menu.component";
import Select from "@mui/material/Select";
import TablePagination from "@mui/material/TablePagination";
import TableActionComponent from "../../../../components/table/table-action.component";
import Divider from "@mui/material/Divider";
import {ErrorComponent} from "../../../../components/error/error.component";

interface ContainerProps {
    groupId: any;
}

const GroupUserComponent = ({groupId}: ContainerProps) => {
    const displayedColumns = ["name", "username", "email", "actions"];
    const service = new BaseService<User>(URLS.USER);
    const [dataSource, setDataSource] = React.useState<User[]>([]);
    const [options, setOptions] = React.useState<{ value: string | number; label: string }[]>([]);
    const [selectedUsers, setSelectedUsers] = React.useState<User[]>([]);
    const [selectedRow, setSelectedRow] = React.useState<User | null>(null);
    const [value, setValue] = React.useState("");
    const [pagination, setPagination] = React.useState<any>();
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0);

    const search = () => {
        service.clearParameters();
        service.addParameter("target", groupId);
        service.addParameter("limit", rowsPerPage);
        service.addParameter("offset", (page * rowsPerPage));
        service.addParameter("associated", true);
        service.getAllFromListRoute("find_group_associated")
            .then((response: any) => {
                setPagination(response.data);
            }).catch((error: any) => {
                ErrorComponent(error);
            });
    };

    const searchAssociated = () => {
        service.clearParameters();
        service.addParameter("target", groupId);
        service.addParameter("associated", false);
        service.getAllFromListRoute("find_group_associated")
            .then((response: any) => {
                const userOptions = response.data
                    .filter((user: User) => user)
                    .map((user: User) => ({value: user.id, label: user.name}));
                setOptions(userOptions);
            }).catch((error: any) => {
                ErrorComponent(error);
            });
    };

    const handleAddUser = () => {
        service.clearParameters();
        const data = {
            "source": selectedUsers,
            "target": groupId,
            "associated": true
        };
        service.saveFromRoute(data, "associate_group").then(() => {
            setSelectedUsers([]);
            search();
            searchAssociated();
            toast.success(translate.t("successfully_updated"));
        }).catch(error => {
            if (error.response.status === 400) {
                toast.error(translate.t("error_user_selected"));
            }
        });
    };

    const handleRowClick = (row: User) => {
        setSelectedRow(row);
    };

    const handleDelete = () => {
        service.clearParameters();
        const data = {
            "source": selectedRow?.id,
            "target": groupId,
            "associated": false
        };
        service.saveFromRoute(data, "associate_group").then(() => {
            toast.success(translate.t("successfully_deleted"));
            search();
        }).catch((error: any) => {
            ErrorComponent(error);
        });
    };

    const handleChange = (event: any) => {
        const selectedValue = event.target.value;
        setValue(selectedValue);
        setSelectedUsers(selectedValue);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        setDataSource(pagination?.results?.filter((item: User) => item.associated) ?? []);
    }, [pagination]);

    useEffect(() => {
        setSelectedUsers([]);
        if (groupId) {
            search();
            searchAssociated();
        }
    }, [groupId, page, rowsPerPage]);

    return (
        <Paper variant="outlined">
            <Box p={2}>
                <Grid alignItems="center" container spacing={2} marginBottom={2} direction={"row"}>
                    <Grid item xs>
                        <FormControl sx={{width: "100%"}}>
                            <InputLabel>{translate.t("user")}</InputLabel>
                            <Select
                                label={translate.t("user")}
                                variant="outlined"
                                value={value}
                                onChange={handleChange}
                                sx={{"& .MuiSelect-icon": {fontSize: "2rem"}, overflow: "hidden"}}
                                disabled={false}>
                                {options.length > 0 ? (
                                    options.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>{translate.t("empty_listing")}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Fab
                            onClick={handleAddUser}
                            size="small"
                            type="submit"
                            color="secondary">
                            <AddIcon/>
                        </Fab>
                    </Grid>
                </Grid>
                <Paper variant="outlined" sx={{overflow: "hidden"}}>
                    <Table size={"small"}>
                        <TableHead>
                            <TableRow sx={{flexDirection: "row-reverse", alignContent: "end"}}>
                                {displayedColumns.map((column: string) => (
                                    <TableCell
                                        sx={{paddingTop: "12px", paddingBottom: "12px"}}
                                        key={column}>{translate.t(column)}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataSource && dataSource.length > 0 ? (
                                dataSource.map((row: any) => (
                                    <TableRow
                                        onClick={() => handleRowClick(row)}
                                        key={row.id}
                                        sx={{"&:last-child td, &:last-child th": {border: 0}}}>
                                        {displayedColumns.map((column: string) => (
                                            <TableCell key={column}>
                                                {column === "actions" ? (
                                                    <TableMenuComponent
                                                        row={row}
                                                        handleDelete={handleDelete}
                                                        url={"/security/group/"}/>
                                                ) : (
                                                    row[column] ? row[column] : "-"
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={displayedColumns.length} sx={{
                                        textAlign: "center",
                                        fontSize: "13px",
                                        padding: "8px 0"}}>
                                        {translate.t("empty_listing")}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <Divider/>
                    <TablePagination
                        sx={{textAlign: "center"}}
                        rowsPerPageOptions={[5, 10, 20, 50]}
                        component="div"
                        count={Number(pagination?.count ?? 0)}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TableActionComponent}
                        labelRowsPerPage={translate.t("rows_per_page")}
                        labelDisplayedRows={({from, to, count}) => `${from} - ${to} ${translate.t("of")} ${count}`}/>
                </Paper>
            </Box>
        </Paper>
    );
};

export default GroupUserComponent;
