import BaseModel from "../default/base-model";

export class DowntimeReason extends BaseModel {
    description: string;

    constructor() {
        super();
        this.description = "";
    }
}

